<template>
    <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog"
      aria-labelledby="`booking-detail-modal-label`" aria-hidden="true" style="">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="sms-modal-label">
              <span>{{ title }}</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
  
          <div class="modal-body" :key="bookingid">
            <div id="booking-detail-modal-body">
              <div v-if="booking">
                <RevisitDetail
                  :booking="booking" :leadbooking="booking.leadbooking" />
              </div>
            </div>
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import $ from 'jquery';
  import _get from 'lodash/get';
  import { ref, computed, onMounted, inject, defineProps, defineExpose } from 'vue';
  import { useStore } from 'vuex';
  import RevisitDetail from "./RevisitDetail.vue";
  
  // Import your models or other external methods
  import {  Booking, CognitoUser, Dictionary, Landlord, Tenant } from "@/models";
  
  const props = defineProps({
    id: String
  });
  
  const store = useStore();
  
  const cognitoUser = computed((): CognitoUser => store.getters['auth/cognitoUser']);
  const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
  
  const saveBooking = (booking: Booking) => {
    return store.dispatch("diary/saveBookingFromScheduler", booking);
  };
  
  const realtime: any = inject('realtime');
  const actProperty: any = inject('actProperty');
  
  const channel = realtime.channels.get('diary');
  const deviceid = actProperty.getDeviceId();
  
  const booking = ref(new Booking());
  const showfull = ref(true);
  
  
  const title = ref("");
  const titlenoteschanged = ref(false);
  const bookingid = computed(() => booking.value.id);
  
  // Methods
  const init = (bookingData: Booking, showfullFlag: boolean) => {
    booking.value = bookingData;
    title.value = actProperty.getBookingTitle(booking.value, dictionary.value);
    showfull.value = showfullFlag;
  };
  
  const pischeduler = computed(() => {
    const assignedGroups = _get(
      cognitoUser.value,
      'signInUserSession.accessToken.payload["cognito:groups"]',
      []
    );
    const isDiaryUser = assignedGroups.some(
      (assignedGroup: string) => ["DiaryUser"].includes(assignedGroup)
    );
    return (screen.width <= 760 && isDiaryUser);
  });
  
  const onAddressclick = () => {
    if (pischeduler.value) {
      let address = actProperty.formatAddress(booking.value.address, ", ");
      window.open(`http://maps.google.com/?q=${encodeURI(address)}`, '_blank', 'noreferrer');
    }
  }
  
  
  const show = () => {
    if ($(`#${props.id}` + 'Backdrop').length == 0) {
      const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
      $('body').append(backdropDiv);
      let el = $(`#${props.id}`);
      el.show();
    }
  }
  
  const hide = () => {
    if ($(`#${props.id}`).length > 0) {
      $(`#${props.id}` + 'Backdrop').remove();
    }
    if (titlenoteschanged.value) {
      saveBooking(booking.value).then((b: Booking) => {
        channel.publish('appointmentChanged', { deviceid: deviceid, bookingid: b.id, source: 'Scheduler' });
        $(`#${props.id}`).hide();
      });
    }
    else {
      $(`#${props.id}`).hide();
    }
  };
  
  defineExpose({ init, show, hide });
  </script>
  
  
  <style scoped lang="scss">
  .modal-body {
    max-height: 50vh;
    overflow-y: scroll;
  }
  </style>