<template>
  <div :id="name" class="modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="`auditlogs-label`"
    aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="auditlogs-label">
            <i class="fas fa-clipboard-list"></i> Audit logs
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div v-for="(auditlog, $index) in auditlogs" :key="$index" class="row mb-4 ml-4">
            <div class="col-md-1 text-right" style="padding: 0px">
              <avatar :name="auditlog.user" :size="25" :inline="true"></avatar>
            </div>
            <div class="col-md-11 text-left">
              <div>
                <b>{{ auditlog.user }}</b>
              </div>
              <div>{{ formatDatetime(auditlog.datetime) }}</div>
              <div v-if="auditlog.subtype" class="font-italic">
                {{ auditlog.subtype }} : Sub-booking audit log
              </div>
              <div v-if="auditlog.notes">{{ auditlog.notes }}</div>
              <div v-for="(valuechange, $vcindex) in auditlog.valuechanges" :key="$vcindex">
                <div v-if="valuechange.fieldname && valuechange.newvalue">
                  Changed {{ getFieldDisplayName(valuechange.fieldname) }} to
                  {{
                    getFormattedFieldValue(
                      valuechange.fieldname,
                      valuechange.newvalue
                    )
                  }}
                </div>
                <div v-if="valuechange.fieldname && !valuechange.newvalue">
                  Deleted {{ getFieldDisplayName(valuechange.fieldname) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, computed, defineExpose, defineProps } from 'vue';
import { useStore } from 'vuex';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { Auditlog, Auditlogvaluechange, Booking, Inspector } from '@/models';

const props = defineProps({
  name: String,
  field: String,
});

const store = useStore();

const booking = ref(new Booking());
const inspectors = computed((): Inspector[] => store.getters['diary/inspectorlist']);

const init = (bookingData: Booking) => {
  booking.value = bookingData;
};

const formatDatetime = (val: string) => {
  return moment(val).format("DD/MM/YY hh:mm a");
};

const getFieldDisplayName = (val: string) => {
  return Booking.getFieldDisplayName(val);
};

const getFormattedFieldValue = (field: string, val: string) => {
  let formattedValue: string = val;
  if (field === "inspector.id" && inspectors.value) {
    const inspector = inspectors.value.find(i => i.id === val);
    if (inspector) formattedValue = inspector.name;
  } else {
    formattedValue = val;
  }
  return formattedValue;
};

const auditlogs = computed(() => {
  let logs: Auditlog[] = booking.value ? [...booking.value.auditlogs] : [];
  if (booking.value && booking.value.subbookings) {
    booking.value.subbookings.forEach((sb: Booking) => {
      if (sb.auditlogs) {
        sb.auditlogs.forEach((l: Auditlog) => {
          let sublog: Auditlog = new Auditlog(l);
          sublog.subtype = sb.subtype;
          logs.push(sublog);
        });
      }
    });
  }

  if (props.field) {
    logs = logs.filter((l: Auditlog) =>
      l.valuechanges.find((v: Auditlogvaluechange) => v.fieldname === props.field)
    );
  }

  logs.sort((l1: Auditlog, l2: Auditlog) => {
    if (moment(l1.datetime).isAfter(moment(l2.datetime))) {
      return 1;
    } else if (moment(l1.datetime).isBefore(moment(l2.datetime))) {
      return -1;
    } else {
      return 0;
    }
  });

  return logs;
});

const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = () => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
  }
};

defineExpose({ show, hide, init });
</script>


<style scoped lang="scss">
.modal-body {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>