import { MutationTree } from "vuex";
import { PvBookingsState } from "./types";
import { Booking, Customer, Inspector, PmTenancy } from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<PvBookingsState> = {
  /**
   * Set the tenancies list
   *
   * @param {PvBookingsState} state
   * @param {PmTenancy[]} tenancies
   * @returns void
   */
  setTenancies(
    state: PvBookingsState,
    tenancies: PmTenancy[]
  ): void {
    state.tenancies = tenancies;
  },

  /**
   * Set the current Tenancy ID
   *
   * @param {PvBookingsState} state
   * @param {string} id
   * @returns void
   */
  setCurrentOfficeId(state: PvBookingsState, id: string): void {
    state.currentTenancyId = id;
  },
  setBookings(state: PvBookingsState, list: Booking[]): void {
    state.list = list;
  },
  setOriginalBookings(
    state: PvBookingsState,
    list: Booking[]
  ): void {
    // state.originallist = list.map((b: Booking) => new Booking(b));
    state.originallist = list;
  },
  /**
   * Set the month for the Reports query.
   *
   * @param state PvBookingsState
   * @param month Month in YYYY-MM format
   * @returns void
   */
  setCurrentPage(
    state: PvBookingsState,
    currentPage: number
  ): void {
    state.currentPage = currentPage;
  },
  /**
   * Set the monthly Report count
   * - This is the total number Reports, in the current month
   *
   * @param state ReportsState
   * @param count number
   * @returns void
   */
  setPvCount(state: PvBookingsState, count: number | null): void {
    state.totalcount = count;
  },
  setPageLimit(state: PvBookingsState, data: any): void {},

  sethasUnsavedChanges(
    state: PvBookingsState,
    hasUnsavedChanges: boolean
  ): void {
    state.hasUnsavedChanges = hasUnsavedChanges;
  },
  setCustomers(
    state: PvBookingsState,
    customers: Customer[]
  ): void {
    state.customerlist = customers;
  },
  setInspectorBookings(
    state: PvBookingsState,
    payload: { inspectorid: string; date: string, bookings: Booking[] }
  ): void {
    state.inspectorlist.forEach((i) => {
      if (i.id === payload.inspectorid) {
        i.bookingsmap.set(payload.date, payload.bookings);
      }
    });
  },
  /**
   * Set the inspectors list
   *
   * @param state InspectorsState
   * @param inspector Inspector
   * @returns void
   */
  setInspectors(state: PvBookingsState, inspectors: Inspector[]): void {
    state.inspectorlist = inspectors;
  },
};
