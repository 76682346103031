<template>
  <div class="booking-section">
    <div class="card-header booking-section-header">
      <div class="row align-items-center">
        <div class="col-md-1" v-if="showserachresulttable">
          <i
            class="col-auto fas fa-arrow-left fa-2x"
            @click="hideserachresult"
          ></i>
        </div>
        <div class="col-md-3">
          <h3 class="mb-0">Search For Existing Booking</h3>
        </div>
        <div class="col-md-5">
          <search-by-string
            placeholder="Search"
            @searchsubmit="search"
            :showserachresulttable="showserachresulttable"
          />
        </div>
        <div class="col-4 text-right">
          <span v-if="parkedbookings.length" class="pr-1">Parked </span>
          <div
            class="chip"
            style="cursor: pointer;"
            v-for="(parkedbooking, $index) in parkedbookings"
            :key="parkedbooking.id"
            @click="loadParkedBooking($index,parkedbooking)"
          >
            <div class="chip-head">{{ $index + 1 }}</div>
            <i
              class="fas fa-lock icon-botton-left"
              style="color: yellow"
              v-if="parkedbooking.locked"
              :title="parkedbooking.lockedby"
            ></i>
            <div class="chip-content">
              {{ parkedbooking.address.postcode }}
            </div>
          </div>
          <button
            :disabled="
              !booking.address ||
              !booking.address.postcode ||
              parkedbookings.length === 3
            "
            type="button"
            class="btn btn-outline-light ml-2"
            aria-label="Park booking"
            @click="parkBooking()"
          >
            <i class="fas fa-parking"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="showserachresulttable">
      <div class="col-md-12">
        <DxDataGrid
          ref="searchresultgrid"
          :height="height"
          :data-source="results"
          key-expr="id"
          :allow-column-reordering="false"
          :allow-column-resizing="false"
          :column-auto-width="true"
          :show-borders="false"
          :show-column-lines="false"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="true"
          :focused-row-key="focusedRowKey"
          no-data-text="No bookings found for given search"
          @row-click="onAppointmentClick"
          @row-dbl-click="onAppointmentDblClick"
          @row-prepared="onSearchTableRowPrepared"
          @cell-prepared="onSearchTableCellPrepared"
        >
          <DxColumn
            width="15%"
            :fixed="true"
            data-field="bookingdatewithday"
            caption="Date"
          />
          <DxColumn
            width="15%"
            :fixed="true"
            cell-template="timeCellTemplate"
            caption="Time"
          />
          <template #timeCellTemplate="{ data }">
            <span style="white-space: pre-wrap">
              {{ data.data.starttime }} to {{ data.data.endtime }}
            </span>
          </template>
          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="apptimeCellTemplate"
            caption="Appointment Time"
          />
          <template #apptimeCellTemplate="{ data }">
            {{
              data.data.appointmenttimeForDisplay === "Invalid date"
                ? ""
                : data.data.appointmenttimeForDisplay
            }}
          </template>
          <DxColumn
            width="5%"
            :fixed="true"
            cell-template="jobtypeCellTemplate"
            caption="Job Type"
          />
          <template #jobtypeCellTemplate="{ data }">
            <div v-if="!data.data.googleid">
              {{ actProperty.getBookingTitle(data.data, dictionary) }}
            </div>
            <div v-else>
              <i class="fab fa-google"></i>
            </div>
          </template>

          <DxColumn
            width="25%"
            :fixed="true"
            cell-template="addressCellTemplate"
            caption="Address/Summary"
          />
          <template #addressCellTemplate="{ data }">
            <span style="white-space: pre-wrap">
              <span v-if="data.data.googleid">
                <div>{{ data.data.location }}</div>
                <div>{{ data.data.summary }}</div>
              </span>
              <span v-else-if="data.data.jobtype === 'Personal'">
                {{ data.data.summary }}
              </span>
              <span v-else>
                {{ data.data.addressPreview }}
              </span>
            </span>
          </template>

          <DxColumn
            width="10%"
            :fixed="true"
            data-field="inspector.name"
            caption="Inspector"
          />

          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="companyNameCellTemplate"
            caption="Customer"
          />
          <template #companyNameCellTemplate="{ data }">
            <span v-if="data.data.subtype && data.data.leadbooking">
              {{ data.data.leadbooking.customer.companyName }}
            </span>
            <span v-else>
              {{ data.data.customer.companyName }}
            </span>
          </template>

          <DxColumn
            width="10%"
            :fixed="true"
            cell-template="branchNameCellTemplate"
            caption="Branch"
          />
          <template #branchNameCellTemplate="{ data }">
            <span v-if="data.data.subtype && data.data.leadbooking">
              {{ data.data.leadbooking.customer.branchName }}
            </span>
            <span v-else>
              {{ data.data.customer.branchName }}
            </span>
          </template>

          <DxPaging :enabled="false" />
        </DxDataGrid>
      </div>
    </div>

    <BookingDetailModal
      id="search-booking-detail-model"
      ref="bookingDetailModal"
      :editable="false"
    />
    <AlertDialog ref="alertDialog1" name="alertdialog1" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, inject, nextTick, defineEmits, onBeforeUnmount, onUnmounted} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment-timezone";
import { debounce } from "lodash";
import SearchByString from "@/components/diary/SearchByString.vue";
import BookingDetailModal from "@/components/modals/BookingDetailModal.vue";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import { DxDataGrid, DxColumn, DxPaging } from "devextreme-vue/data-grid";
import { Booking } from "@/models";
import Ably from "ably";

const route = useRoute();
const router = useRouter();
const store = useStore();

const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty"); // Injected property
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const searchresultgrid = ref(null);

const dictionary = computed(() => store.getters["dictionary/current"]);
const booking = computed((): Booking => store.getters["diary/booking"]);
const searchBookings = async (params: any) => {
  return await store.dispatch("diary/searchBookings", params);
};
const setBooking = (booking: any) => {
  store.commit('diary/setBooking', booking);
};
const email = computed(() => store.getters["auth/email"]);
const unlockBooking = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch("diary/unlockBooking", booking).then((b: Booking) => {
      channel.publish("bookingunlocked", { bookingid: b.id });
    });
  }
};
const lockBooking = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch("diary/lockBooking", booking).then((b: Booking) => {
      channel.publish("bookinglocked", { bookingid: b.id });
    });
  }
};
const parkedbookings = computed(() => store.getters["diary/parkedbookings"]);
const hasUnsavedChanges = computed(
  () => store.getters["diary/hasUnsavedChanges"]
);
const setCurrentdate = (currentdate: Date) => {  
  store.commit('diary/setCurrentdate', currentdate);
};
const parkBooking = () => {
  if (booking.value.id) {
    unlockBooking(booking.value);
    channel.publish("unselectBooking", { deviceid: deviceid });
  }
  store.commit("diary/parkBooking");

  nextTick(() => {
    store.commit("diary/resetUnsavedChanges");
    router.replace({ name: "newbooking", params: { id: booking.value.id } });
    setCurrentdate(moment("2050-12-31", "YYYY-MM-DD").utc().toDate());
  });
};
const loadParkedBooking = (index:number,booking: Booking) => {
  if (
    !booking.locked ||
    (booking.locked && booking.lockedby === email.value)
  ) {
    if (hasUnsavedChanges.value) {
      const modal = alertDialog1.value as any;
      modal.init(
        "Warning",
        "There are unsaved changes. Please first save all the changes to load the selected booking.",
        "OK"
      );
      modal.show();
    } else {
      store.commit("diary/loadParkedBooking",index);
      nextTick(() => {
        store.commit("diary/addUnsavedChange", "parkedBooking");
        if (!booking.id) {
          router.replace({
            name: "newbooking",
            params: { id: booking.id },
            query: { parkedbookingid: 'new' },
          });
        } else {
          if (booking.id) {
            lockBooking(booking);
            channel.publish("unselectBooking", { deviceid: deviceid });
          }
          router.replace({
            name: "existingbooking",
            params: { id: booking.id },
            query: { parkedbookingid: booking.id },
          });
          setCurrentdate(booking.startdateAsDate);
        }
      });
    }
  }
};

const height = ref(window.innerHeight - window.innerHeight * 0.25);
const results = ref<Booking[]>([]);
const focusedRowKey = ref("");
const showserachresulttable = ref(false);
const todaymoment = ref(moment.utc(new Date()).startOf("day"));
const nowmoment = ref(moment());
const selectedbooking = ref(new Booking());

const emit = defineEmits(["showsearchresult", "hidesearchresult"]);

const bookingDetailModal = ref(null);
const alertDialog1 = ref(null);

onMounted(() => {
  channel.subscribe("appointmentSearchRowSelected", (message: any) => {
    if (showserachresulttable.value && message?.data) {
      if (message.data.deviceid != deviceid) return;
      if (message.data.bookingid && results.value.length) {
        const index = results.value.findIndex(
          (b: Booking) => b.id === message.data.bookingid
        );
        if (index >= 0) {
          selectedbooking.value = results.value[index];
        }
      }
    }
  });
  channel.subscribe("appointmentSelected", (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      if(showserachresulttable.value){
        showserachresulttable.value = false;
        results.value = [];
        hideserachresult();
      }
    }
  });

  channel.subscribe('bookinglocked', (message: any) => {
    if (message?.data?.bookingid) {   
      // Make sure the event is coming from the same pc
      if (message?.data && message.data?.bookingid && parkedbookings.value.length > 0){
        const index = parkedbookings.value.findIndex(
          (n:Booking) => n.id === message.data?.bookingid
        );
        if (index >= 0) {
          let newlist = [...parkedbookings.value];
          newlist[index].locked = true;
          newlist[index].lockedby = message.data.lockedby;
          store.commit("diary/setParkBookings", newlist);
        }     
      }
    }
  });
  channel.subscribe('bookingunlocked', (message: any) => {
    if (message?.data?.bookingid) {
     // Make sure the event is coming from the same pc
     if (message?.data && message.data?.bookingid && parkedbookings.value.length > 0){
        const index = parkedbookings.value.findIndex(
          (n:Booking) => n.id === message.data?.bookingid
        );
        if (index >= 0) {
          let newlist = [...parkedbookings.value];
          newlist[index].locked = false;
          newlist[index].lockedby = message.data.lockedby;
          store.commit("diary/setParkBookings", newlist);
        }     
      }
    }
  });
});

onBeforeUnmount(() => {
  channel.unsubscribe("appointmentSearchRowSelected");
  channel.unsubscribe("appointmentSelected");
  channel.unsubscribe("bookinglocked");
  channel.unsubscribe("bookingunlocked");
});

const search = (val: string) => {
  searchBookings({ anytext: val, cancelled: "all", sort: "created_at", limit: 100 }).then(
    (bookings: Booking[]) => {
      processSearchResult(bookings);
    }
  );
};

const processSearchResult = (bookings: Booking[]) => {
  results.value = [];
  bookings.sort((b1: Booking, b2: Booking) => {
    if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
      return 1;
    } else if (moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))) {
      return -1;
    } else {
      return 0;
    }
  });
  var futurebooking: Booking | undefined = bookings.find((b: Booking) =>
    moment.utc(b.startdate).isAfter(todaymoment.value as moment.Moment)
  );
  if (!futurebooking || !futurebooking?.id) {
    // If there is not future booking, select the last one in the list
    futurebooking =
      bookings.length > 0 ? bookings[bookings.length - 1] : undefined;
  }
  nowmoment.value = moment();
  results.value.push(...bookings);
  showserachresult();
  nextTick(() => {
    if (futurebooking?.id) {
      focusedRowKey.value = futurebooking?.id;
      searchresultgrid.value.instance.navigateToRow(futurebooking?.id);
      setTimeout(() => {
        const scrollOffset = searchresultgrid.value.instance
          .getScrollable()
          .scrollOffset();
        searchresultgrid.value.instance
          .getScrollable()
          .scrollTo(scrollOffset.top);
      }, 1000);
    }
  });
};

const showserachresult = () => {
  showserachresulttable.value = true;
  emit("showsearchresult");
};
const hideserachresult = () => {
  showserachresulttable.value = false;
  emit("hidesearchresult");
};

const onSearchTableRowPrepared = (e: any) => {
  if (e.rowType === "data") {
    if (e.data.cancelled) {
      e.rowElement.classList.add("cancelled-booking");
    }
    if (
      e.data.startdate &&
      moment.utc(e.data.startdate).isBefore(nowmoment.value as moment.Moment)
    ) {
      e.rowElement.classList.add("historical-booking");
    }
  }
};

const onSearchTableCellPrepared = (e: any) => {
  if (e.rowType === "data") {
    if (e.data.cancelled) {
      e.cellElement.classList.add("transparent-background");
    }
  }
};

const dbonAppointmentClick = debounce((e: any) => {
  if (e.data) {
    let booking: Booking = e.data;
    if (booking.subtype != Booking.PERSONAL) {
      const modal = bookingDetailModal.value as any;
      if (modal) {
        modal.init(booking);
        modal.show();
      }
    }
  }
}, 250);

const onAppointmentClick = (e: any) => {
  e.cancel = true;
  dbonAppointmentClick(e);
};

const onAppointmentDblClick = (e: any) => {
  e.cancel = true;
  dbonAppointmentClick.cancel();
  if (e.data) {
    let booking: Booking = e.data;
    if (booking.googleid) {
      selectBooking(booking);
    }
    // eslint-disable-next-line no-empty
    else if (booking.jobtype === Booking.PERSONAL) {
    } else if (!booking.subtype) {
      selectBooking(booking);
      channel.publish("appointmentSearchRowSelected", {
        deviceid: deviceid,
        bookingid: booking.id,
      });
      hideserachresult();
    }
  }
};

const selectBooking = (booking: Booking) => {
  if (booking.googleid || !booking.jobtype) {
    locatebooking(booking);
    selectedbooking.value = booking;
  } else if (booking.subtype) {
    locatebooking(booking);
    selectedbooking.value = booking;
  } else {
    locatebooking(booking);
    channel.publish("appointmentSelectedInSearch", {
      deviceid: deviceid,
      bookingid: booking.id,
      inspectorid: booking.inspector?.id,
    });
    selectedbooking.value = booking;
  }
};

const locatebooking = (booking: Booking) => {
  channel.publish("locateBooking", {
    deviceid: deviceid,
    bookingid: booking.id,
    date: booking.startdate,
  });
};
</script>

<style scoped lang="scss">
.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  color: rgb(127, 127, 127);
  border: none;
  cursor: default;
  height: 20px;
  outline: none;
  padding: 0;
  font-size: 10px;
  font-color: #333333;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 10px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #969696;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 20px;
  color: #fff;
  height: 20px;
  font-size: 10px;
  margin-right: -4px;
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
.icon-botton-left {
  margin-left: 10px;
}
</style>
