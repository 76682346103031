<template>
    <div class="customer">
      <div class="user-header">
        <form>
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Smoke and CO alarm form</label>
              <div class="col-sm-4">
                <DxSwitch :value="customer.policies.smokecoalarms" 
                  @value-changed="setCustomerDeep({path: 'policies.smokecoalarms', data: $event.value })" />
              </div>             
            </div>
  
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Safety Cleats to Blinds</label>
              <div class="col-sm-4">
                <DxSwitch :value="customer.policies.safetycleats" 
                  @value-changed="setCustomerDeep({path: 'policies.safetycleats', data: $event.value })" />
              </div>
            </div>
  
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Summary email following CO</label>
              <div class="col-sm-4">
                <DxSwitch :value="customer.policies.summaryemailafterco" 
                  @value-changed="setCustomerDeep({path: 'policies.summaryemailafterco', data: $event.value })" />
              </div>
            </div>
  
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Confirm access for diary bookings</label>
              <div class="col-sm-4">
                <DxSwitch :value="customer.policies.accessconfirm" 
                  @value-changed="setCustomerDeep({path: 'policies.accessconfirm', data: $event.value })" />
              </div>
            </div>
            <!-- https://trello.com/c/ntnL5ag8/740-do-not-contact-toggle -->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Do Not Contact TT for Inv/CI</label>
              <div class="col-sm-4">
                <DxSwitch :value="customer.policies.donotcontactttforinvci" 
                  @value-changed="setCustomerDeep({path: 'policies.donotcontactttforinvci', data: $event.value })" />
              </div>
            </div>
             <!-- https://trello.com/c/ntnL5ag8/740-do-not-contact-toggle -->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Do Not Contact TT for CO/SOC</label>
              <div class="col-sm-4">
                <DxSwitch :value="customer.policies.donotcontactttforcosoc" 
                  @value-changed="setCustomerDeep({path: 'policies.donotcontactttforcosoc', data: $event.value })" />
              </div>
            </div>

            <!-- Telephone Lettings -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Telephone Lettings</label>
                <div class="col-sm-3">
                    <input type="text" class="form-control"  v-model.lazy="telephoneLetting" autocomplete="telephoneletting"/>
                </div>
            </div>

            <!-- Emergency Out of hours -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Emergency Out of hours</label>
                <div class="col-sm-3">
                    <input type="text" class="form-control" v-model.lazy="emergencyphone" autocomplete="emergencyphone"/>
                </div>
            </div>

             <!-- Points of contact -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Points of contact</label>
                <div class="col-sm-9">
                    <div class="row">
                      <div class="col-2">Name</div>
                      <div class="col-2">Email</div>
                      <div class="col-2">Work</div>
                      <div class="col-2">Mobile</div>
                      <div class="col-1">cc to confirmation</div>
                      <div class="col-1">cc to feedback</div>
                    </div>
                    <div class="row mb-2" v-for="(poc, $pocindex) in customer.policies.contactlist" :key="$pocindex">
                      <div class="col-2">
                        <input type="text" autocomplete="pocName" class="form-control" v-model="poc.name" @change="setCustomerDeep({path: `policies.contactlist[${$pocindex}].name`, data: poc.name})"/>
                      </div>
                      <div class="col-2">
                        <input type="email" autocomplete="pocEmail" class="form-control" v-model="poc.email" 
                              :class="{ 'is-invalid': !isupdateEmailValid && poc.email }" 
                              @input="updatevalidateEmail(poc.email)"
                              @change="setCustomerDeep({path: `policies.contactlist[${$pocindex}].email`, data: poc.email})"/>
                      </div>
                      <div class="col-2">
                        <input type="tel" autocomplete="pocPhone" class="form-control" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" v-model="poc.phone" @change="setCustomerDeep({path: `policies.contactlist[${$pocindex}].phone`, data: poc.phone})"/>
                      </div>
                      <div class="col-2">
                        <input type="tel" autocomplete="pocMobile" class="form-control" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" v-model="poc.mobile" @change="setCustomerDeep({path: `policies.contactlist[${$pocindex}].mobile`, data: poc.mobile})"/>
                      </div>
                      <div class="col-1">
                        <DxSwitch v-model="poc.confirmationcc"  @value-changed="setCustomerDeep({path: `policies.contactlist[${$pocindex}].confirmationcc`, data: $event.value})"/>
                      </div>
                      <div class="col-1">
                        <DxSwitch v-model="poc.feedbackcc" @value-changed="setCustomerDeep({path: `policies.contactlist[${$pocindex}].feedbackcc`, data: $event.value})"/>
                      </div>
                      <div class="col-1">
                        <button type="button" class="btn btn-danger" @click.prevent="deletePointofcontact($pocindex)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-2"><input type="text" class="form-control" v-model="newcontact.name" autocomplete="newcontactname" /></div>
                      <div class="col-2"><input type="email" class="form-control" :class="{ 'is-invalid': !isEmailValid && newcontact.email }" v-model="newcontact.email" @input="validateEmail" autocomplete="newcontactemail"/></div>
                      <div class="col-2"><input type="tel" class="form-control" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" v-model="newcontact.phone" autocomplete="newcontactephone"/></div>
                      <div class="col-2"><input type="tel" class="form-control" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" v-model="newcontact.mobile" autocomplete="newcontactemobile"/></div>
                      <div class="col-1"><DxSwitch v-model="newcontact.confirmationcc" @value-changed="newcontact.confirmationcc = $event.value"/></div>
                      <div class="col-1"><DxSwitch v-model="newcontact.feedbackcc" @value-changed="newcontact.feedbackcc = $event.value" /></div>
                      <div class="col-1">
                        <button type="button" class="btn btn-outline-dark" :class="{ disabled: !canSavePOC() }" :disabled="!canSavePOC()" @click="addPointofcontact()">
                          <i class="fas fa-save"></i>
                        </button>
                      </div>
                    </div>
                </div>
            </div>

            <!-- Inspection in absence -->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Inspection in absence</label>
              <div class="col-sm-4">
                <DxSwitch :value="inspectioninabs" @value-changed="inspectioninabs = $event.value"  />
              </div>
            </div>

            <!-- Inv and CI inspections flex -->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Inv and CI inspections flex</label>
              <div class="col-sm-4">
                <DxSwitch :value="invciflex" :disabled="!inspectioninabs" @value-changed="invciflex = $event.value"/>
              </div>
            </div>

             <!-- Inv and CI inspections flex AM -->
             <div class="form-group row">
              <label class="col-sm-3 col-form-label">Inv and CI inspections flex AM</label>
              <div class="col-sm-4">
                <DxSwitch :value="invciflexam" :disabled="!inspectioninabs" @value-changed="invciflexam = $event.value"/>
              </div>
            </div>

             <!-- CO and SOC inspections flex -->
             <div class="form-group row">
              <label class="col-sm-3 col-form-label" >CO and SOC inspections flex</label>
              <div class="col-sm-4">
                <DxSwitch :value="cosocflex" :disabled="!inspectioninabs" @value-changed="cosocflex = $event.value"/>
              </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-9 offset-3">
                <div class="row">
                    <div class="col-sm-2">Booked by</div>
                    <div class="col-md-2">Landlord</div>
                    <div class="col-md-8">Email</div>
                </div>
                </div>
            </div>

            <!-- Onsite feedback to -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Onsite feedback to</label>
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-md-2">
                            <DxSwitch :value="onsitefeedbacktobookedby" @value-changed="onsitefeedbacktobookedby = $event.value"/>
                        </div>
                        <div class="col-md-2">
                            <DxSwitch :value="onsitefeedbacktolandlord" @value-changed="onsitefeedbacktolandlord = $event.value"/>
                        </div>
                        <div class="col-sm-8">
                          <div class="row">
                            <div class="col-4"><input type="email" class="form-control"  :class="{ 'is-invalid': !isEmailValid && onsitefeedbacktonewemail }" v-model="onsitefeedbacktonewemail" @input="validateEmail" autocomplete="onsitefeedbacktoemail"/></div>
                            <div class="col-1">
                            <button type="button" 
                                    class="btn btn-outline-dark" 
                                    :class="{ disabled: !isEmailValid || !onsitefeedbacktonewemail }" 
                                    :disabled="!isEmailValid || !onsitefeedbacktonewemail" 
                                    @click="addEmail('onsitefeedbackto', onsitefeedbacktonewemail)">
                                    <i class="fas fa-save"></i>
                            </button>
                          </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-12">
                              <span class="multiselecttags" v-if="onsitefeedbacktoemail">
                                <span v-for="(email, $index) in onsitefeedbacktoemail" :key="$index" class="multiselecttag">{{ email }} <i tabindex="1" class="multiselect__tag-icon" @click="deleteEmail('onsitefeedbackto', $index)" ></i></span>
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Compliance form to -->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Compliance form to</label>
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-md-2">
                            <DxSwitch :value="complianceformtobookedby" @value-changed="complianceformtobookedby = $event.value"/>
                        </div>
                        <div class="col-md-2">
                            <DxSwitch :value="complianceformtolandlord" @value-changed="complianceformtolandlord = $event.value"/>
                        </div>
                        <div class="col-sm-8">
                          <div class="row">
                            <div class="col-4"><input type="email" class="form-control"  :class="{ 'is-invalid': !isEmailValid && complianceformtonewemail }" v-model="complianceformtonewemail" @input="validateEmail" autocomplete="complianceformtoemail"/></div>
                            <div class="col-1">
                            <button type="button" 
                                    class="btn btn-outline-dark" 
                                    :class="{ disabled: !isEmailValid || !complianceformtonewemail }" 
                                    :disabled="!isEmailValid || !complianceformtonewemail" 
                                    @click="addEmail('complianceformto', complianceformtonewemail)">
                                    <i class="fas fa-save"></i>
                            </button>
                          </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-12">
                              <span class="multiselecttags" v-if="complianceformtoemail">
                                <span v-for="(email, $index) in complianceformtoemail" :key="$index" class="multiselecttag">{{ email }} <i tabindex="1" class="multiselect__tag-icon" @click="deleteEmail('complianceformto', $index)" ></i></span>
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

             <!-- CO cleaning feedback to -->
             <div class="form-group row">
                <label class="col-sm-3 col-form-label">CO cleaning feedback to</label>
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-md-2">
                            <DxSwitch :value="cocleaningfeedbacktobookedby" @value-changed="cocleaningfeedbacktobookedby = $event.value"/>
                        </div>
                        <div class="col-md-2">
                            <DxSwitch :value="cocleaningfeedbacktolandlord" @value-changed="cocleaningfeedbacktolandlord = $event.value"/>
                        </div>
                        <div class="col-sm-8">
                             <div class="row">
                            <div class="col-4"><input type="email" class="form-control" :class="{ 'is-invalid': !isEmailValid && cocleaningfeedbacktonewemail }"  v-model="cocleaningfeedbacktonewemail"  @input="validateEmail" autocomplete="cocleaningfeedbacktoemail"/></div>
                            <div class="col-1">
                            <button type="button" 
                                    class="btn btn-outline-dark" 
                                    :class="{ disabled: !isEmailValid || !cocleaningfeedbacktonewemail }" 
                                    :disabled="!isEmailValid || !cocleaningfeedbacktonewemail" 
                                    @click="addEmail('cocleaningfeedbackto', cocleaningfeedbacktonewemail)">
                                    <i class="fas fa-save"></i>
                            </button>
                          </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-12">
                              <span class="multiselecttags" v-if="cocleaningfeedbacktoemail">
                                <span v-for="(email, $index) in cocleaningfeedbacktoemail" :key="$index" class="multiselecttag">{{ email }} <i tabindex="1" class="multiselect__tag-icon" @click="deleteEmail('cocleaningfeedbackto', $index)" ></i></span>
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Confirm Access -->
            <!-- <div class="form-group row">
              <label class="col-sm-3 col-form-label">Confirm Access</label>
              <div class="col-sm-4">
                <DxSwitch  :value="confirmaccess" @value-changed="confirmaccess = $event.value"/>
              </div>
            </div> -->

            <!-- Key release required  -->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Key release required </label>
              <div class="col-sm-4">
                <DxSwitch :value="keyreleasereq" @value-changed="keyreleasereq = $event.value"/>
              </div>
            </div>

            <!-- Opening Hours-->
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Opening Hours</label>
                <div class="col-6">
                    <div class="row" v-for="(oh, $ohindex) in customer.policies.openinghours" :key="$ohindex">
                        <div class="col-sm-1">
                            {{ dayOfWeek[$ohindex] }}
                        </div>
                        <label class="col-sm-1">Closed:</label>
                        <div class="col-sm-1">
                          <DxSwitch v-model="oh.closed" @value-changed="setCustomerDeep({path:`policies.openinghours[${$ohindex}].closed`, data:$event.value});"
/>
                        </div>
                        <div class="col-sm-2">
                            <singleselect-text
                              v-model="oh.from"
                              :options="getTimeOptionsForFrom(oh.to)"
                              :disabled="isDropdownDisabled(oh)"
                              :allow-empty="true"
                              :taggable="false"
                              @select="setOpeningTimeFrom($ohindex, $event)"
                            />
                        </div>
                        <div class="col-sm-2">
                          <singleselect-text
                              v-model="oh.to"
                              :options="getTimeOptionsForTo(oh.from)"
                              :disabled="oh.closed"
                              :allow-empty="true"
                              :taggable="false"
                              @select="setOpeningTimeTo($ohindex, $event)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Misc Notes  -->
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Misc Notes</label>
              <div class="col-sm-6">
                <textarea
                  class="form-control"
                  rows="10"
                  v-model.lazy="customer.policies.uniquenotes"
                  @change="editCurrentCustomer()"
                ></textarea>
              </div>
            </div>

               <!-- Long Term Notes  -->
               <div class="form-group row">
              <label class="col-sm-3 col-form-label">Long Term PI Notes</label>
              <div class="col-sm-6">
                <textarea
                  class="form-control"
                  rows="10"
                  v-model.lazy="customer.policies.longtermpinotes"
                  @change="editCurrentCustomer()"
                ></textarea>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import moment from 'moment-timezone';
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { Contact, Customer } from "@/models";
  import { DxSwitch } from 'devextreme-vue/switch';  
  const dayOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const newcontact = ref(new Contact);
  const onsitefeedbacktonewemail = ref('');
  const complianceformtonewemail = ref('');
  const cocleaningfeedbacktonewemail= ref('');
  const isEmailValid = ref(true);
  const isupdateEmailValid = ref(true);

  const store = useStore();
  const customer = computed((): Customer => store.getters['customers/current']);
  const editCustomer = async (customer: Customer) => await store.dispatch('customers/editCustomer', customer);
  const setCustomerDeep = async (payload: {
    path: string;
    data: any;
  }) => {
    await store.dispatch('customers/setCustomerDeep', payload);
  }

  const isDropdownDisabled = (oh) => {
    return oh.closed;
  };

  const timeoptions = ref([]);

  onMounted(() => {
    timeoptions.value = [];
    let startmoment = moment().set("hour", 0).set("minute", 0);
    for (let i = 0; i < 96; i++) {
      const hourMinutes = startmoment.format("HH:mm");
      timeoptions.value.push(hourMinutes);
      startmoment.add(15, "minutes");
    }
  });

  const getTimeOptionsForFrom = (toTime: string) => {
    const toMoment = moment(toTime, "HH:mm").subtract(15, 'minutes');
    const fromOptions = timeoptions.value.filter((time) => {
      const fromMoment = moment(time, "HH:mm");
      return fromMoment.isSameOrBefore(toMoment);
    });
  return fromOptions;
};

  const getTimeOptionsForTo = (fromTime: string) => {
      const fromMoment = moment(fromTime, "HH:mm").add(15, 'minutes');
      const toOptions = timeoptions.value.filter((time) => {
        const toMoment = moment(time, "HH:mm");
        return toMoment.isSameOrAfter(fromMoment);
      });
    return toOptions;
  };

  const setOpeningTimeFrom = (index: number, val: string) => {
    setCustomerDeep({path:`policies.openinghours[${index}].from`, data: val});
  }
  const setOpeningTimeTo = (index: number, val: string) => {
    setCustomerDeep({path:`policies.openinghours[${index}].to`, data: val});
  }

  const deletePointofcontact = (index: number) => {
    if(index < 0) return;
    const list: Contact[] = customer.value.policies.contactlist;
    list.splice(index, 1);
    setCustomerDeep({path: 'policies.contactlist', data: [...list]});
  }

  const addPointofcontact = () => {
    if(!newcontact.value.name) return false;
    if(!newcontact.value.phone && !newcontact.value.mobile && !newcontact.value.email) return false;
    const list: Contact[] = customer.value.policies.contactlist;
    list.push(new Contact(newcontact.value));
    newcontact.value = new Contact;
    setCustomerDeep({path: 'policies.contactlist', data: [...list]});
  }

  const canSavePOC = () => {
    if(!newcontact.value.name) return false;
    if(!newcontact.value.phone && !newcontact.value.mobile && !newcontact.value.email) return false;

    let checkname = customer.value.policies.contactlist.filter((f)=> f.name == newcontact.value.name);
    if(checkname.length > 0 ){
      return false;
    }

    return true;
  }
  
  const editCurrentCustomer = () => {
    editCustomer(customer.value);
  };

  //TelephoneLetting
  const telephoneLetting = computed({
    get: () => { return customer.value.policies.phone},
    set: (val: string) => { setCustomerDeep({path: 'policies.phone', data: val})}
  });

  //Emergency Out of Hours
  const emergencyphone = computed({
    get: () => { return customer.value.policies.emergencyphone},
    set: (val: string) => { setCustomerDeep({path: 'policies.emergencyphone', data: val})}
  });

  //Inspection in absence
  const inspectioninabs = computed({
    get: () => { return customer.value.policies.inspectioninabs},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.inspectioninabs', data: val})}
  });

  //Inv and CI inspections flex
  const invciflex = computed({
    get: () => { return customer.value.policies.invciflex},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.invciflex', data: val})}
  });

   //Inv and CI inspections flex AM
  const invciflexam = computed({
    get: () => { return customer.value.policies.invciflexam},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.invciflexam', data: val})}
  });

  //CO and SOC inspections flex
  const cosocflex = computed({
    get: () => { return customer.value.policies.cosocflex},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.cosocflex', data: val})}
  });

  //Onsite feedback to booked by
  const onsitefeedbacktobookedby = computed({
    get: () => { return customer.value.policies.onsitefeedbackto.bookedby},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.onsitefeedbackto.bookedby', data: val})}
  });
  
  //Onsite feedback to landlord
  const onsitefeedbacktolandlord = computed({
    get: () => { return customer.value.policies.onsitefeedbackto.landlord},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.onsitefeedbackto.landlord', data: val})}
  });

  //Onsite feedback to Email
  const onsitefeedbacktoemail = computed({
    get: () => { return customer.value.policies.onsitefeedbackto.email},
    set: (val: string[]) => { setCustomerDeep({path: 'policies.onsitefeedbackto.email', data: val})}
  });

  // Compliance form to booked by
  const complianceformtobookedby = computed({
    get: () => { return customer.value.policies.complianceformto.bookedby},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.complianceformto.bookedby', data: val})}
  });
  
  // Compliance form tolandlord
  const complianceformtolandlord = computed({
    get: () => { return customer.value.policies.complianceformto.landlord},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.complianceformto.landlord', data: val})}
  });

  // Compliance form to to Email
  const complianceformtoemail = computed({
    get: () => { return customer.value.policies.complianceformto.email},
    set: (val: string[]) => { setCustomerDeep({path: 'policies.complianceformto.email', data: val})}
  });

  // CO cleaning feedback to booked by
  const cocleaningfeedbacktobookedby = computed({
    get: () => { return customer.value.policies.cocleaningfeedbackto.bookedby},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.cocleaningfeedbackto.bookedby', data: val})}
  });

  // CO cleaning feedback to landlord
  const cocleaningfeedbacktolandlord = computed({
    get: () => { return customer.value.policies.cocleaningfeedbackto.landlord},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.cocleaningfeedbackto.landlord', data: val})}
  });

  // CO cleaning feedback to Email
  const cocleaningfeedbacktoemail = computed({
    get: () => { return customer.value.policies.cocleaningfeedbackto.email},
    set: (val: string[]) => { setCustomerDeep({path: 'policies.cocleaningfeedbackto.email', data: val})}
  });

  //Confirm Access
  const confirmaccess = computed({
    get: () => { return customer.value.policies.confirmaccess},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.confirmaccess', data: val})}
  });

  //Key release required
  const keyreleasereq = computed({
    get: () => { return customer.value.policies.keyreleasereq},
    set: (val: boolean) => { setCustomerDeep({path: 'policies.keyreleasereq', data: val})}
  });

  const deleteEmail = (key: string, index: number) => {
    const list: string[] = customer.value.policies[key].email;
    list.splice(index, 1);
    setCustomerDeep({path: key, data: [...list]});
  }
  const addEmail = (key: string, newvalue: string) => {
    const list: string[] = customer.value.policies[key].email;
    const index = list.findIndex((e: string) => e === newvalue);
    
    if(index < 0) { 
      list.push(newvalue);
      setCustomerDeep({path: key, data: [...list]});

      switch (key) {
        case 'onsitefeedbackto':
          onsitefeedbacktonewemail.value = '';
          break;
        case 'complianceformto':
        complianceformtonewemail.value = '';
          break;
        case 'cocleaningfeedbackto':
        cocleaningfeedbacktonewemail.value = '';
          break;
      }
    }
  }
  const updatevalidateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isupdateEmailValid.value = regex.test(email);
  };

  //validate email
  const validateEmail = () => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isEmailValid.value = regex.test(cocleaningfeedbacktonewemail.value || complianceformtonewemail.value || onsitefeedbacktonewemail.value || newcontact.value.email);
  };
</script>
  
  <style scoped lang="scss">
  .multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 20px 4px 10px;
  border-radius: 10px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #d5d5d5;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselecttags {
  display: block;
  font-size: 14px;
}
.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: initial;
    width: 22px;
    text-align: center;
    line-height: 22px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 5px;
}
  </style>