import { Booking, PresetError } from "./models";
import moment from "moment-timezone";
import { prependDate } from "@/utilities";

const corejobtypes = ["inventory", "checkin", "checkout", "soc"];

export function checkBooking(booking: Booking): PresetError[] {
  if (corejobtypes.indexOf(booking.jobtype) < 0) return [];
  if (booking.subtype) return [];

  const preset1Errors: PresetError[] = checkPreset1(booking);
  const preset2Errors: PresetError[] = checkPreset2(booking);
  const preset3Errors: PresetError[] = checkPreset3(booking);
  const preset4Errors: PresetError[] = checkPreset4(booking);
  const preset5Errors: PresetError[] = checkPreset5(booking);
  const preset6Errors: PresetError[] = checkPreset6(booking);
  const preset7Errors: PresetError[] = checkPreset7(booking);
  const preset8Errors: PresetError[] = checkPreset8(booking);
  const preset9Errors: PresetError[] = checkPreset9(booking);

  const secondaryErrors: PresetError[] = checkSecondaryIssues(booking);
  return [
    ...preset1Errors,
    ...preset2Errors,
    ...preset3Errors,
    ...preset4Errors,
    ...preset5Errors,
    ...preset6Errors,
    ...preset7Errors,
    ...preset8Errors,
    ...preset9Errors,
    ...secondaryErrors,
  ];
}

// 1. No PDF Upload Doc for CO off upload - MISSING
export function checkPreset1(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (
    booking.jobtype === "checkout" &&
    booking.internaljobtype.endsWith("off upload")
  ) {
    if (
      (!booking.dataentrydocuments ||
        booking.dataentrydocuments.length === 0) &&
      booking.checkoutWithoutPreviousReport
    ) {
      errors.push(
        new PresetError({
          message: "Upload missing",
          presetnumber: 1,
          category: "primary",
        })
      );
    }
  }
  return errors;
}

// 2. Missing upload ref for CO off upload (skull and cross bones) - MISSING
export function checkPreset2(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  var showerror = false;
  if (
    booking.jobtype === "checkout" &&
    booking.internaljobtype === "Check-Out - off upload"
  ) {
    if (
      (!booking.emaillogs.dataentryconfirmationsentdate ||
        !booking.dataentryreport) &&
      booking.dataentryreport?.dataentrystatus != "Upload Complete"
    ) {
      // If email hasn't been sent to splash, return true
      showerror = true;
    }
  }

  if (showerror) {
    errors.push(
      new PresetError({
        message: "Upload not typed up",
        presetnumber: 2,
        category: "primary",
      })
    );
  }
  return errors;
}

// 3. Appointment time falls outside of booking time
export function checkPreset3(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (booking.appointmenttimeoutsidebooking) {
    errors.push(
      new PresetError({
        message: "Check-In time outside of booking slot",
        presetnumber: 3,
        category: "primary",
      })
    );
  }
  return errors;
}

// 4. Appointment time is not the same as booking start time when access is via tenant
export function checkPreset4(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  let apptime = moment(booking.appointmenttime).utc().format("h:mm A");
  let sttime = moment(booking.startdate).utc().format("h:mm A");
  if (
    apptime === "12:00 AM" ||
    apptime === "12:15 AM" ||
    apptime === "12:20 AM" ||
    apptime === "12:25 AM"
  ) {
    return errors; // Return empty error array
  } else if (booking.keypickup === "Meet Tenant" && apptime != sttime) {
    errors.push(
      new PresetError({
        message: "Time conflict when access via Tenant",
        presetnumber: 4,
        category: "primary",
      })
    );
  }
  return errors;
}

// 5. Access not confirmed for CO
export function checkPreset5(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (
    (booking.jobtype === "checkout" || booking.jobtype === "soc") &&
    booking.keypickup === "Meet Tenant" &&
    booking.tenantattending === "unsure"
  ) {
    errors.push(
      new PresetError({
        message: "Tenant access for Check-Out unconfirmed",
        presetnumber: 5,
        category: "primary",
      })
    );
  }
  return errors;
}

// 6. Access unconfirmed (toggle grey far RHS of access on BF) - however, it excludes any client that has ‘key confirmation not required’ within personal branch settings
export function checkPreset6(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (booking.internaljobtype === "Check-In - back to back") {
    return errors;
  }
  if (
    (booking.keypickup != "From Agency" &&
      booking.keypickup != "Meet Tenant" &&
      !booking.confirmaccess) ||
    (booking.keypickup === "From Agency" &&
      !booking.confirmaccess &&
      booking.customer.policies.accessconfirm)
  ) {
    errors.push(
      new PresetError({
        message: "Key access unconfirmed (excluding via TT for Check-Out)",
        presetnumber: 6,
        category: "primary",
      })
    );
  }
  return errors;
}

// 7. Linked to agency opening times in logistics', access set to via agency and booking start time falls outside of agency opening hours.
export function checkPreset7(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (
    booking.keypickup != "From Agency" ||
    booking.internaljobtype === "Check-In - back to back"
  ) {
    return errors;
  }
  const dow = moment(booking.startdate).utc().day();
  const dayofweek = moment().weekday(dow).format("dddd");
  const agencyclosed =
    booking?.customer?.policies?.openinghours[dow - 1]?.closed;
  const agencyopenfrom =
    booking?.customer?.policies?.openinghours[dow - 1]?.from;
  const agencyopento = booking?.customer?.policies?.openinghours[dow - 1]?.to;
  if (agencyclosed) {
    errors.push(
      new PresetError({
        message: `Access via agency but agency is shut`,
        presetnumber: 7,
        category: "primary",
      })
    );
  }

  const from = prependDate(
    moment.utc(agencyopenfrom, "HH:mm").format("hh:mm A"),
    booking.startdate,
    booking.enddateAsDate
  );
  const to = prependDate(
    moment.utc(agencyopento, "HH:mm").format("hh:mm A"),
    booking.startdate,
    booking.enddateAsDate
  );
  if (
    agencyopenfrom &&
    agencyopento &&
    (moment.utc(booking.startdate).isBefore(moment.utc(from)) ||
      moment.utc(booking.startdate).isAfter(moment.utc(to)))
  ) {
    errors.push(
      new PresetError({
        message: "Access via agency but agency is shut",
        presetnumber: 7,
        category: "primary",
      })
    );
  }
  return errors;
}

// 8. Provisional booking (all job types).
export function checkPreset8(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (booking.provisional) {
    errors.push(
      new PresetError({
        message: "Provisional booking",
        presetnumber: 8,
        category: "primary",
      })
    );
  }
  return errors;
}
// 9. Access via concierge and no email attached
export function checkPreset9(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  if (
    booking.keypickup === "Via Concierge" &&
    booking.accessattachments.length === 0
  ) {
    errors.push(
      new PresetError({
        message: "Access via concierge and no email attached",
        presetnumber: 9,
        category: "primary",
      })
    );
  }
  return errors;
}

export function checkSecondaryIssues(booking: Booking): PresetError[] {
  const errors: PresetError[] = [];
  let acSettings = booking.customer.accountsettings;
  //Tenant data
  if (
    booking.keypickup === "Meet Tenant" ||
    booking.releasekeysto === "Meet Tenant"
  ) {
    let checkTenantName = booking.tenantsarray.filter((f) => f.ttname);
    let checkTenantEmail = booking.tenantsarray.filter((f) => f.ttemail);
    let checkTenantMobile = booking.tenantsarray.filter((f) => f.ttmobile);
    if (!checkTenantName.length) {
      errors.push(
        new PresetError({
          message: "Missing tenant name",
          presetnumber: 100,
          category: "secondary",
        })
      );
    }
    // if (!checkTenantEmail.length) {
    //   errors.push(
    //     new PresetError({
    //       message: "Missing tenant email",
    //       presetnumber: 200,
    //       category: "secondary",
    //     })
    //   );
    // }
    // if (!checkTenantMobile.length) {
    //   errors.push(
    //     new PresetError({
    //       message: "Missing tenant mobile",
    //       presetnumber: 300,
    //       category: "secondary",
    //     })
    //   );
    // }
    if (!checkTenantMobile.length && !checkTenantEmail.length) {
      errors.push(
        new PresetError({
          message: "Missing tenant email/mobile",
          presetnumber: 1100,
          category: "secondary",
        })
      );
    }
  }
  // Landlord Data
  let checkLandlordName = booking.landlordsarray.filter((f) => f.llname);
  let checkLandlordEmail = booking.landlordsarray.filter((f) => f.llemail);
  if (!checkLandlordName.length && acSettings.llnamerequired) {
    errors.push(
      new PresetError({
        message: "Missing landlord name",
        presetnumber: 400,
        category: "secondary",
      })
    );
  }
  if (!checkLandlordEmail.length && acSettings.llemailrequired) {
    errors.push(
      new PresetError({
        message: "Missing landlord  email",
        presetnumber: 500,
        category: "secondary",
      })
    );
  }
  if (booking.appointmenttimeoutsidebooking) {
    errors.push(
      new PresetError({
        message: "Check-In/Check-Out time conflicting with schedule",
        presetnumber: 600,
        category: "secondary",
      })
    );
  }
  if (!booking.worksorder && booking.customer.workordernumberrequired) {
    errors.push(
      new PresetError({
        message: "Works order/purchase order number required",
        presetnumber: 700,
        category: "secondary",
      })
    );
  }
  let paymentAdvanceFlag = false;
  if (booking.jobtype === "checkout" || booking.jobtype === "soc") {
    paymentAdvanceFlag = acSettings.paymentinadvcosoc;
  } else if (booking.jobtype === "inventory" || booking.jobtype === "checkin") {
    paymentAdvanceFlag = acSettings.paymentinadvinvci;
  }
  let paidFlagArray = booking.invoices.map((f) => f?.fees[0]?.paid);
  const allFlagged = paidFlagArray.every((item) => item);
  if (!booking.creditapproved) {
    if (paymentAdvanceFlag && !allFlagged) {
      errors.push(
        new PresetError({
          message: "Not paid",
          presetnumber: 800,
          category: "secondary",
        })
      );
    }
  }

  if (
    booking.jobtype === "checkin" &&
    booking.releasekeysto === "Meet Tenant" &&
    (booking.keystobereleased === "No" || booking.keystobereleased === "TBC") &&
    booking.customer.policies.keyreleasereq
  ) {
    errors.push(
      new PresetError({
        message: "Key release unconfirmed",
        presetnumber: 900,
        category: "secondary",
      })
    );
  }

  if (
    booking?.connectedbooking?.internaljobtype != "Check-In - back to back" &&
    booking.releasekeysto === "TBC" &&
    booking.customer.policies.keyreleasereq
  ) {
    errors.push(
      new PresetError({
        message: "Key return TBC",
        presetnumber: 1000,
        category: "secondary",
      })
    );
  }
  return errors;
}
