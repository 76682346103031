<template>
    <div>
      <div>
        <div>
          <h3 class="mb-0">Time</h3>
        </div>
        <div v-if="booking?.startdate || booking?.enddate">
          {{ booking.starttime }}
          to
          {{ booking.endtime }}
        </div>
      </div>

      <div>
        <div  class="mt-4">
          <h3 class="mb-0" @click="onAddressclick">Address</h3>
        </div>
        <div @click="onAddressclick">
          {{ actProperty.formatAddress(booking?.address, ", ") }}
        </div>
      </div>

      <div>
        <div v-if="booking?.customer" class="mt-4">
          <h3 class="mb-0">Client</h3>
        </div>
        <div v-if="booking?.customer &&
        (booking.customer.companyName || booking.customer.branchName)
        ">
          {{ booking.customer.companyName }} -
          {{ booking.customer.branchName }}
        </div>
      </div>
      <div v-if="booking.subtype != 'Prep'">
        <div class="mt-4">
          <h3 class="mb-0">Inspector</h3>
        </div>
        <div v-if="booking?.inspector">
          {{ booking.inspector.name }}
        </div>
      </div>
  
      <div class="mt-4" v-if="leadbooking?.propertyspec">
        <div>
          <h3 class="mb-0">Property</h3>
        </div>
        <div>
          <div v-if="leadbooking.propertyspec.propertytype" class="mr-4">
            Property type: {{ leadbooking.propertyspec.propertytype }}
          </div>
          <div v-if="leadbooking.propertyspec.furnished" class="mr-4">
            Furnished: {{ leadbooking.propertyspec.furnished }}
          </div>
          <div v-if="propertyspec">
            {{ propertyspec }}
          </div>
        </div>
      </div>

      <div v-if="booking.pinotes" class="mt-4">
        <h3 class="mb-0">Notes</h3>
      </div>
      <div v-if="booking.pinotes">
        {{ booking.pinotes }}
      </div>

    </div>
  </template>
  
  <script setup lang="ts">
  import { computed, PropType, inject, defineProps, ref } from 'vue';
  import _get from 'lodash/get';
    import { useStore } from 'vuex';
  import { Booking } from '@/models';
  
  const actProperty: any = inject('actProperty');
  
  const props = defineProps({
    booking: { type: Booking },
    leadbooking: { type: Booking },
  });
  const showfull = ref(false);
  const store = useStore();
  const cognitoUser = computed(() => store.getters['auth/cognitoUser']);
  const dictionary = computed(() => store.getters['dictionary/current']);
  const internaljobtypedisplayname = computed((): Map<string, string> => store.getters['diary/internaljobtypedisplayname']);
  const isDiaryUser = computed(() => {
    const assignedGroups = _get(cognitoUser.value, 'signInUserSession.accessToken.payload["cognito:groups"]', []);
    return assignedGroups.some((group: string) => group === "DiaryUser");
  });
  const pischeduler = computed(() => screen.width <= 760 && isDiaryUser.value);
  
  function onAddressclick() {
    if (pischeduler.value) {
      const address = actProperty.formatAddress(props.booking?.address, ", ");
      window.open(`http://maps.google.com/?q=${encodeURIComponent(address)}`, '_blank', 'noreferrer');
    }
  }
  
  const propertyspec = computed(() => {
    let lines = [];
    const spec = props.leadbooking?.propertyspec;
    if (spec?.bedrooms) lines.push(`${spec.bedrooms} bedrooms`);
    if (spec?.bathrooms) lines.push(`${spec.bathrooms} bathrooms`);
    if (spec?.receptions) lines.push(`${spec.receptions} receptions`);
    if (spec?.other) lines.push(`${spec?.other} other`);
    return lines.join(", ");
  });
  
  const customLabelForInternaljobtype = (internaljobtype: string): string => {
    return actProperty.customLabelForInternaljobtype(internaljobtypedisplayname.value, internaljobtype)
  }

  const init = (showfullFlag: boolean) => {
    showfull.value = showfullFlag;
  };
  
  </script>
  