/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import _ from "lodash";
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { DiaryState } from "./types";
import {
  Address,
  PropertySpec,
  Booking,
  Customer,
  Inspector,
  Report,
  GetaddressResponse,
  EmailLogs,
  SMSLogs,
  EmailLog,
  Tenant,
  Bookedby,
  Landlord,
} from "@/models";

export const getters: GetterTree<DiaryState, RootState> = {
  booking: (state: DiaryState): Booking => state.booking,
  list: (state: DiaryState): Booking[] => state.list,
  currentdate: (state: DiaryState): Date => state.currentdate,

  // What & When
  jobtypelist: (state: DiaryState): string[] => state.jobtypelist,
  jobtype: (state: DiaryState): string => state.booking.jobtype,
  internaljobtypelist: (state: DiaryState): string[] =>
    state.internaljobtypelist,
  internaljobtypedisplayname: (state: DiaryState): Map<string, string> =>
    state.internaljobtypedisplayname,
  offactreporttypes: (state: DiaryState): string[] => state.offactreporttypes,
  internaljobtype: (state: DiaryState): string => state.booking.internaljobtype,
  jobtypemap: (state: DiaryState): Map<string, string[]> => state.jobtypemap,
  startdate: (state: DiaryState): string => state.booking.startdate,
  enddate: (state: DiaryState): string => state.booking.enddate,
  address: (state: DiaryState): Address => state.booking.address,
  propertyspec: (state: DiaryState): PropertySpec => state.booking.propertyspec,
  propertytypelist: (state: DiaryState): string[] => state.propertytypelist,
  getaddressresponse: (state: DiaryState): GetaddressResponse =>
    state.getaddressresponse,
  previousreport: (state: DiaryState): Report => state.booking.previousreport,
  dataentryreport: (state: DiaryState): Report | undefined =>
    state.booking.dataentryreport,
  cleaningnotes: (state: DiaryState): string => state.booking.cleaningnotes,

  // Scheduling
  inspector: (state: DiaryState): Inspector => state.booking.inspector,
  recommendedtime: (state: DiaryState): number | undefined =>
    state.booking.recommendedtime,
  appointmenttime: (state: DiaryState): string | undefined =>
    state.booking.appointmenttime,
  recommendedappointmenttime: (state: DiaryState): number | undefined =>
    state.booking.recommendedappointmenttime,

  // Who For & How Much
  customer: (state: DiaryState): Customer => state.booking.customer,
  invoicecustomer: (state: DiaryState): Customer =>
    state.booking.invoicecustomer,
  managedtype: (state: DiaryState): string => state.booking.managedtype,
  bookedby: (state: DiaryState): Bookedby[] => state.booking.bookedby,
  bookedbyarray: (state: DiaryState): Bookedby[] => state.booking.bookedbyarray,
  landlords: (state: DiaryState): Landlord[] => state.booking.landlords,
  landlordsarray: (state: DiaryState): Landlord[] =>
    state.booking.landlordsarray,
  worksorder: (state: DiaryState): string => state.booking.worksorder,
  paymentinadv: (state: DiaryState): boolean => state.booking.paymentinadv,
  invoices: (state: DiaryState): Report[] => state.booking.invoices,
  paid: (state: DiaryState): boolean => state.booking.paid,
  paymentdate: (state: DiaryState): string => state.booking.paymentdate,
  development: (state: DiaryState): Customer => state.booking.development,

  // Access
  keypickup: (state: DiaryState): string => state.booking.keypickup,
  keypickupcompnayname: (state: DiaryState): string =>
    state.booking.keypickupcompanyname,
  keypickupbranchname: (state: DiaryState): string =>
    state.booking.keypickupbranchname,
  keypickupnotes: (state: DiaryState): string => state.booking.keypickupnotes,
  confirmaccess: (state: DiaryState): boolean => state.booking.confirmaccess,
  accessissues: (state: DiaryState): string => state.booking.accessissues,
  releasekeysto: (state: DiaryState): string => state.booking.releasekeysto,
  releasekeystocompanyname: (state: DiaryState): string =>
    state.booking.releasekeystocompanyname,
  releasekeystobranchname: (state: DiaryState): string =>
    state.booking.releasekeystobranchname,
  releasekeystonotes: (state: DiaryState): string =>
    state.booking.releasekeystonotes,
  keystobereleased: (state: DiaryState): string =>
    state.booking.keystobereleased,
  tenants: (state: DiaryState): Tenant[] => state.booking.tenants,
  tenantsarray: (state: DiaryState): Tenant[] => state.booking.tenantsarray,
  agencyaddress: (state: DiaryState): Address => state.booking.agencyaddress,
  keypickupfromaddress: (state: DiaryState): Address =>
    state.booking.keypickupfromaddress,
  keyreleasetoaddress: (state: DiaryState): Address =>
    state.booking.keyreleasetoaddress,
  tenantattending: (state: DiaryState): string => state.booking.tenantattending,
  donotcontacttt: (state: DiaryState): boolean => state.booking.donotcontacttt,

  // Confirmations
  emaillogs: (state: DiaryState): EmailLogs => state.booking.emaillogs,
  smslogs: (state: DiaryState): SMSLogs => state.booking.smslogs,

  // Other
  internalnotes: (state: DiaryState): string => state.booking.internalnotes,
  clientnotes: (state: DiaryState): string => state.booking.clientnotes,
  longtermnotes: (state: DiaryState): string => state.booking.longtermnotes,

  cancelled: (state: DiaryState): boolean => state.booking.cancelled,

  currentlog: (state: DiaryState): EmailLog => state.currentlog,

  updates: (state: DiaryState): string[] => state.updates,
  hasUnsavedChanges: (state: DiaryState): boolean => state.updates.length > 0,

  isInspectorlistEmpty: (state: DiaryState): boolean =>
    state.inspectorlist.length === 0,
  inspectorlist: (state: DiaryState): Inspector[] => state.inspectorlist,
  postcodesearchlist: (state: DiaryState): Booking[] =>
    state.postcodesearchlist,

  isCustomerlistEmpty: (state: DiaryState): boolean =>
    state.customerlist.length === 0,
  customerlist: (state: DiaryState): Customer[] => state.customerlist,
  selectedCustomername: (state: DiaryState): string =>
    state.selectedCustomername,

  user: (state: DiaryState, rootState: RootState): string =>
    _.get(rootState.auth.cognitoUser, "attributes.email", ""),

  showbookingswithproblems: (state: DiaryState): boolean =>
    state.showbookingswithproblems,
  showportfoliobookings: (state: DiaryState): boolean =>
    state.showportfoliobookings,

  // Parked booking
  parkedbookings: (state) => state.parkedbookings,
};
