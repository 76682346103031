<template>
  <div class="card bg-light booking-section">
    <div
      class="card-header booking-section-header"
      @click="expanded = !expanded"
    >
      <div class="row">
        <div class="col-md-9">
          <h3 class="mb-0">What &amp; When</h3>
        </div>
        <div class="col-md-3 text-right">
          <button
            type="button"
            class="close ml-auto p-1 pl-2 pr-2"
            aria-label="Close"
            style="color: white"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <!--div class="card-body" v-if="isQCuser && !expanded">
      <div v-if="!isNewBooking()">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-4">{{ jobtype.displayname }}</div>
              <div class="col-4">{{ internaljobtype }}</div>
              <div class="col-4">
                {{ actProperty.formatDateForDisplay(currentdate) }}
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                {{ address.displayaddress }}
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="row">
                  <div class="col-2">{{ bedrooms }} Bedrooms</div>
                  <div class="col-2">{{ bathrooms }} Bathroom</div>
                  <div class="col-2">{{ receptions }} Receptions</div>
                  <div class="col-2">{{ other }} Others</div>
                  <div class="col-2">{{ furnished }}</div>
                  <div class="col-2">{{ sqft }} sqft</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-md-4">
                <span> Provisional: {{ provisional ? "Yes" : "No" }} </span>
              </div>
              <div
                class="col-md-4"
                v-if="booking.basereport && booking.basereport.ref"
              >
                <ul class="previousreportdetails">
                  <li>
                    <h5>Previous report details:</h5>
                  </li>
                  <li>
                    <strong
                      >
                      {{ 
                       actProperty.formatReportType(
                          booking.basereport.type,
                          dictionary
                        )
                       }}
                      {{
                        actProperty.formatDateForDisplay(
                          booking.basereport.date
                        )
                      }}</strong
                    >
                  </li>
                  <li>Ref: {{ booking.basereport.ref }}</li>
                  <li v-if="previousreportpi">{{ previousreportpi }}</li>
                  <li v-if="previousreportcleanliness">
                    {{ previousreportcleanliness }}
                  </li>
                  <li v-if="previousreportitemcount">
                    {{ previousreportitemcount }}
                  </li>
                  <li>
                    <ProgressButton
                      @click="
                        actProperty.copyDownloadButton(
                          booking.basereport,
                          'report'
                        )
                      "
                      @dblclick="generateReportPdf(booking.basereport, $event)"
                      class="btn btn-outline-primary"
                      :title="
                        actProperty.formatReportType(
                          booking.basereport.type,
                          dictionary
                        )
                      "
                    >
                      <i class="far fa-file-pdf"></i>
                      {{
                        actProperty.formatReportType(
                          booking.basereport.type,
                          dictionary
                        )
                      }}
                    </ProgressButton>

                    <ProgressButton
                      v-if="booking.basereport.type == 'checkout'"
                      @click="
                        actProperty.copyDownloadButton(
                          booking.basereport,
                          'summary'
                        )
                      "
                      @dblclick="
                        generateReportSummaryPdf(booking.basereport, $event)
                      "
                      class="btn btn-outline-primary"
                      title="Check-Out Summary"
                    >
                      <i class="far fa-file-pdf"></i> Summary
                    </ProgressButton>
                  </li>
                </ul>
              </div>
              <div
                class="col-md-2"
                v-if="
                  (!booking.previousreport || !booking.basereport.ref) &&
                  offupload
                "
              >
                <div>
                  <span>
                    <label class="col-form-label"
                      ><b>Upload report details:</b></label
                    >

                    <Documents
                      v-if="booking.dataentrydocuments.length"
                      :readonly="true"
                      :buttontext="'PDF'"
                      :entity="'booking'"
                      :key="booking.dataentrydocuments.length"
                      :documents="booking.dataentrydocuments"
                      :path="'dataentrydocuments'"
                      @documentadded="dataentrydocumentadded"
                    />
                  </span>
                </div>
                <ul
                  v-if="
                    booking.dataentryreport &&
                    booking.dataentryreport.ref &&
                    booking.dataentryreport.dataentrystatus ===
                      'Upload Complete'
                  "
                  style="padding-left: 10px"
                >
                  <li>Ref: {{ booking.dataentryreport.ref }}</li>
                  <li v-if="dataentryreportitemcount">
                    {{ dataentryreportitemcount }}
                  </li>
                </ul>
              </div>
              <div class="col-md-2" v-if="offupload"></div>
            </div>
          </div>
        </div>
      </div>
    <div--->
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-2">
              <label class="col-form-label">Post code</label>
              <input
                type="text"
                class="form-control height-43"
                style="min-width: 100px"
                v-model.lazy="postcode"
                @click.stop="showAddressChangedWarning('postcode')"
                @change="sortInspectors"
                @keyup.enter="postcodeSearch($event)"
                :ref="(el) => (dynamicRefs['postcode'] = el)"
                autocomplete="off"
              />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Address</label>
              <input
                type="text"
                class="form-control height-43"
                v-model.lazy="line1"
                autocomplete="addressline1"
                :ref="(el) => (dynamicRefs['line1'] = el)"
                @click="showAddressChangedWarning('line1')"
                @keyup.enter="addressSearch($event)"
              />
            </div>
            <div class="col-md-3">
              <label class="col-form-label" style="color: transparent">.</label>
              <input
                type="text"
                class="form-control height-43"
                v-model.lazy="line2"
                :ref="(el) => (dynamicRefs['line2'] = el)"
                @click="showAddressChangedWarning('line2')"
                autocomplete="addressline2"
              />
            </div>
            <div class="col-md-2">
              <label class="col-form-label">Town</label>
              <input
                type="text"
                class="form-control height-43"
                v-model.lazy="town"
                :ref="(el) => (dynamicRefs['town'] = el)"
                @click="showAddressChangedWarning('town')"
                autocomplete="addresstown"
              />
            </div>
            <div class="col-md-2">
              <label class="col-form-label">County</label>
              <input
                type="text"
                class="form-control height-43"
                v-model.lazy="county"
                @click="showAddressChangedWarning('county')"
                :ref="(el) => (dynamicRefs['county'] = el)"
                autocomplete="addresscounty"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4">
              <label class="col-form-label">Job Type</label>
              <div>
                <singleselect-text
                  v-model="jobtype"
                  :options="jobtypeoptions"
                  :custom-label="customLabelForReportType"
                  :allow-empty="false"
                  :taggable="false"
                >
                  <template #clear v-if="jobtype">
                    <i
                      @mousedown.prevent.stop="clearJobtype()"
                      class="multiselect__clear fa fa-times"
                      aria-label="Clear Job Type"
                    ></i>
                  </template>
                </singleselect-text>
              </div>
            </div>
            <div class="col-md-4">
              <label class="col-form-label">Internal Job Type</label>
              <div class="row">
                <div class="col-md-12">
                  <singleselect-text
                    v-model="internaljobtype"
                    :options="reorderedinternaljobtypelist"
                    :custom-label="customLabelForInternaljobtype"
                    :allow-empty="false"
                    :taggable="false"
                    :disabled="!jobtype || jobtype.slug == ''"
                    :class="{ outline: prepsubbooking }"
                  >
                    <template #clear v-if="internaljobtype">
                      <i
                        @mousedown.prevent.stop="setInternaljobtype('')"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Internal Job Type"
                      ></i>
                    </template>
                    <template #option="props">
                      <span :class="{'strikethrought': booking?.previousbooking && booking?.previousbooking?.id && (props.option === 'Inv CI - new to ACT' || props.option === 'Inv CI - old report on file, charge CI rate')}">{{ props.option }}</span>
                    </template>
                  </singleselect-text>
                </div>
              </div>
              <!-- <div class="row" v-if="prepsubbooking">
                <div class="col-md-12">Prep job on {{ prepadate }}</div>
              </div> -->
            </div>
            <div class="col-md-4">
              <label class="col-form-label">Date</label>
              <div :class="{ outline: currentdatesameastoday() }">
                <DxDateBox
                  v-model="currentdate"
                  @value-changed="setBookingDate"
                  display-format="dd/MM/yyyy"
                  type="date"
                  picker-type="calendar"
                  :calendarOptions="{ firstDayOfWeek: 1, showTodayButton: true }"
                  apply-value-mode="instantly"
                >
                  <DxDateBoxButton name="dropDown"/>
                </DxDateBox>
                <div v-if="currentdatesameastoday()">
                  Date has not been changed
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-3 darker-grey-background">
        <div class="card-body mb-3">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-2">
                  <label class="col-form-label">Bedrooms</label>
                  <singleselect-text
                    :options="numberofroomsoptions"
                    :allow-empty="true"
                    :taggable="false"
                    v-model.lazy="bedrooms"
                  />
                </div>
                <div class="col-md-2">
                  <label class="col-form-label">Bathrooms</label>
                  <singleselect-text
                    :options="numberofroomsoptions"
                    :allow-empty="true"
                    :taggable="false"
                    v-model.lazy="bathrooms"
                  />
                </div>
                <div class="col-md-2">
                  <label class="col-form-label">Receptions</label>
                  <singleselect-text
                    :options="numberofroomsoptions"
                    :allow-empty="true"
                    :taggable="false"
                    v-model.lazy="receptions"
                  />
                </div>
                <div
                  class="col-md-2"
                  :class="{ 'pr-0': previousreportotherroomlist.length }"
                >
                  <label class="col-form-label">Other</label>
                  <singleselect-text
                    :options="numberofroomsoptions"
                    :allow-empty="true"
                    :taggable="false"
                    v-model.lazy="other"
                  >
                    <template #clear v-if="previousreportotherroomlist.length">
                      <i
                        id="previousreportotherroomlisttooltip"
                        class="multiselect__clear fa fa-question"
                        @mouseenter="showTooltip"
                        @mouseleave="hideTooltip"
                      ></i>
                    </template>
                    <template #singleLabel="props">
                      <div class="row">
                        <div v-if="props.option > 0" class="col-md-12">
                          {{ props.option }}
                        </div>
                        <div v-else class="col-md-12"></div>
                      </div>
                    </template>
                  </singleselect-text>
                  <DxTooltip
                    ref="otherroomstooltip"
                    :hide-on-outside-click="false"
                    target="#previousreportotherroomlisttooltip"
                    :contentTemplate="otherroomstooltipHtml"
                  >
                  </DxTooltip>
                </div>

                <div class="col-md-2 pl-0">
                  <!--label class="col-form-label" style="color: transparent"
                    >.</label
                  >
                  <singleselect-text
                    class="height-40"
                    v-if="previousreportotherroomlist.length"
                    :options="previousreportotherroomlist"
                    :allow-empty="true"
                    :taggable="false"
                  /-->
                </div>

                <div class="col-md-2 pr-0">
                  <span>
                    <label class="col-form-label">Floorplan</label>
                    <FloorplanComponent
                      :key="floorplan.length"
                      :floorplan="floorplan"
                      :path="'floorplan'"
                      @clicked="showFloorplanDocument"
                    />
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <label class="col-form-label">Square feet</label>
                  <input
                    type="number"
                    min="0"
                    class="form-control height-43"
                    v-model.lazy="sqft"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-form-label">Property type</label>
                  <div>
                    <singleselect-text
                      v-model="propertytype"
                      :options="propertytypelist"
                      :allow-empty="true"
                      :taggable="false"
                    >
                      <template #clear v-if="propertytype">
                        <i
                          @mousedown.prevent.stop="setPropertytype('')"
                          class="multiselect__clear fa fa-times"
                          aria-label="Clear Property Type"
                        ></i>
                      </template>
                    </singleselect-text>
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label">Furnish</label>
                  <div>
                    <singleselect-text
                      v-model="furnished"
                      :options="furnishedOptions"
                      :allow-empty="true"
                      :taggable="false"
                    >
                      <template #clear v-if="furnished">
                        <i
                          @mousedown.prevent.stop="setFurnished('')"
                          class="multiselect__clear fa fa-times"
                          aria-label="Clear Furnished"
                        ></i>
                      </template>
                    </singleselect-text>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Details of report that was selected from Postcode search -->
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <div class="dx-field">
                      <div class="dx-field-label">Provisional</div>
                      <div class="dx-field-value">
                        <DxSwitch
                          :value="provisional"
                          @value-changed="provisional = $event.value"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="booking.provisional">
                    <!-- <label class="col-form-label">Why is it provisional?</label> -->
                    <textarea
                      class="form-control"
                      rows="1"
                      v-model.lazy="provisionalnotes"
                    ></textarea>
                  </div>
                  <div v-if="booking.jobtype && booking.internaljobtype">
                    <label class="col-form-label" v-if="offupload"
                      >Notes (based on uploaded report)</label
                    >
                    <label
                      class="col-form-label"
                      v-else-if="
                        offactreporttypes.includes(booking.internaljobtype)
                      "
                      >Notes (based on previous report notes)</label
                    >
                    <textarea
                      v-if="
                        offupload ||
                        offactreporttypes.includes(booking.internaljobtype)
                      "
                      class="form-control"
                      rows="5"
                      v-model.lazy="cleaningnotes"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div v-if="prepandKeyJobData && prepandKeyJobData.length > 0">
                    <div
                      v-for="(prepdata, $prepdata) in prepandKeyJobData"
                      :key="$prepdata"
                    >
                      <ul class="previousreportdetails">
                        <li>
                          <span class="text-flex">
                            <h5>{{ bookingTitle(prepdata) }}:</h5>
                            <span class="ml-1">{{
                              prepdata.inspector.name
                            }}</span>
                          </span>
                        </li>
                        <li>
                          <span class="text-flex"
                            ><strong>Date :</strong>
                            <div
                              v-if="prepdata?.startdate || prepdata?.enddate"
                              class="ml-1"
                            >
                              {{ prepdata.bookingdate }} -
                              {{ prepdata.starttime }}
                              to
                              {{ prepdata.endtime }}
                            </div>
                          </span>
                        </li>
                        <li
                          v-if="
                            prepdata.subtype === 'Prep' &&
                            booking.invoices?.length > 0 &&
                            booking.invoices[0].firstexportedby
                          "
                        >
                          <span class="text-flex"
                            ><strong>Ref :</strong>
                            <span class="ml-1">{{
                              booking.invoices[0].ref
                            }}</span></span
                          >
                          <span
                            v-if="
                              booking?.invoices[0]?.propertyDetails
                                ?.generalNotes
                            "
                            ><strong> General Notes :</strong
                            ><span
                              class="ml-1"
                              v-html="
                                booking?.invoices[0]?.propertyDetails
                                  .generalNotes
                              "
                            ></span
                          ></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="booking.basereport && booking.basereport.ref">
                    <ul class="previousreportdetails">
                      <li>
                        <h5>Previous report details:</h5>
                        <button type="button" class="close" aria-label="Clear">
                          <span aria-hidden="true" @click="removepreviousreport"
                            >&times;</span
                          >
                        </button>
                      </li>
                      <li>
                        <strong
                          >
                          {{ 
                       actProperty.formatReportType(
                          booking.basereport.type,
                          dictionary
                        )
                       }}
                          {{
                            actProperty.formatDateForDisplay(
                              booking.basereport.date
                            )
                          }}</strong
                        >
                      </li>
                      <li>Ref: {{ booking.basereport.ref }}</li>
                      <li v-if="previousreportpi">{{ previousreportpi }}</li>
                      <li v-if="previousreportcleanliness">
                        {{ previousreportcleanliness }}
                      </li>
                      <li v-if="previousreportitemcount">
                        {{ previousreportitemcount }}
                      </li>
                      <li>
                        <!-- New generate report buttons to get around the pdf timeout issues -->
                        <ProgressButton
                          @click="
                            actProperty.copyDownloadButton(
                              booking.basereport,
                              'report'
                            )
                          "
                          @dblclick="
                            generateReportPdf(booking.basereport, $event)
                          "
                          class="btn btn-outline-primary"
                          :title="
                            actProperty.formatReportType(
                              booking.basereport.type,
                              dictionary
                            )
                          "
                        >
                          <i class="far fa-file-pdf"></i>
                          {{
                            actProperty.formatReportType(
                              booking.basereport.type,
                              dictionary
                            )
                          }}
                        </ProgressButton>

                        <!-- CO reports have an extra PDF download (Summary) -->
                        <ProgressButton
                          v-if="booking.basereport.type == 'checkout'"
                          @click="
                            actProperty.copyDownloadButton(
                              booking.basereport,
                              'summary'
                            )
                          "
                          @dblclick="
                            generateReportSummaryPdf(booking.basereport, $event)
                          "
                          class="btn btn-outline-primary"
                          title="Check-Out Summary"
                        >
                          <i class="far fa-file-pdf"></i> Summary
                        </ProgressButton>
                      </li>
                    </ul>
                  </div>
                  <!-- <div v-else-if="booking.jobtype && booking.internaljobtype">
                    <label class="col-form-label" v-if="offupload"
                      >Notes (based on uploaded report)</label
                    >
                    <label
                      class="col-form-label"
                      v-else-if="
                        offactreporttypes.includes(booking.internaljobtype)
                      "
                      >Notes (based on previous report notes)</label
                    >
                    <textarea
                      v-if="
                        offupload ||
                        offactreporttypes.includes(booking.internaljobtype)
                      "
                      class="form-control"
                      rows="5"
                      v-model.lazy="cleaningnotes"
                    ></textarea>
                  </div> -->
                </div>

                <div
                  class="col-md-2"
                  v-if="
                    (!booking.previousreport || !booking.previousreport.ref) &&
                    offupload
                  "
                >
                  <div>
                    <span>
                      <label class="col-form-label"
                        >Upload report details:</label
                      >
                      <Documents
                        :buttontext="'PDF'"
                        :entity="'booking'"
                        :key="booking.dataentrydocuments.length"
                        :documents="booking.dataentrydocuments"
                        :path="'dataentrydocuments'"
                        @documentadded="dataentrydocumentadded"
                        @clicked="showDataentryDocument"
                      />
                    </span>
                  </div>
                  <ul
                    v-if="
                      booking.dataentryreport &&
                      booking.dataentryreport.ref &&
                      booking.dataentryreport.dataentrystatus ===
                        'Upload Complete'
                    "
                    style="padding-left: 10px"
                  >
                    <li>Ref: {{ booking.dataentryreport.ref }}</li>
                    <li v-if="dataentryreportitemcount">
                      {{ dataentryreportitemcount }}
                    </li>
                  </ul>
                </div>
                <div class="col-md-2" v-if="offupload">
                  <label class="col-form-label">Email to dataentry:</label>
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Send email to data entry"
                  >
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      @click="showDataentryConfirmationModal"
                      :title="'Send email to data entry'"
                    >
                      <img
                        v-if="emaillogs.dataentryacknowledgementreceiveddate"
                        class="trafficlight"
                        src="~@/assets/images/traffic-green.png"
                      />
                      <img
                        v-else-if="!emaillogs.dataentryconfirmationsentdate"
                        class="trafficlight"
                        src="~@/assets/images/traffic-red.png"
                      />
                      <img
                        v-else-if="emaillogs.dataentryconfirmationsentdate"
                        class="trafficlight"
                        src="~@/assets/images/traffic-amber.png"
                      />
                      Email
                    </button>
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      @click="showDataentryEmailLogsModal"
                      v-if="emaillogs.dataentryemaillogs.length"
                    >
                      <i class="fa fa-history"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <EmailModal
      :id="`dataentry-email-confirmation-modal`"
      :name="`dataentry-email-confirmation-modal`"
      ref="dataentryconfirmationEmailModal"
      title="Email notification to data entry"
      reporttype=""
      target="Data Entry"
      chronorder="Initial confirmation"
      customer=""
      :fromaddress="fromaddress"
      :toaddresses="dataentryemailaddress"
      label="Data Entry"
    />
    <EmailLogsModal
      :name="`dataentryconfirmation-emaillogs-modal`"
      ref="dataentryconfirmationEmailLogsModal"
      :target="'Data Entry'"
      title="Email Logs"
    />
    <AlertDialog ref="alertDialog" name="wwalertdialog" />
    <BookingDetailModal
      id="whatwhen-booking-detail-modal"
      ref="bookingDetailModal"
    />
    <PostcodeReportSearchModal
      :refname="'cms-search-modal'"
      ref="cmsSearchModal"
      @previousbookingselected="applycommonconversionrules"
      @showmatchingproperywarning="
        showIncorrectEntrySelectedFromAddressSearchWarning
      "
      @googlebookingselected="googlebookingselected"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  ref,
  inject,
  defineEmits,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment-timezone";
import { postfixUTCTime } from "@/utilities";
import { convert, convertcommon } from "@/bookingconverters";
import { DxSwitch } from "devextreme-vue/switch";

import {
  Booking,
  Report,
  Room,
  Section,
  Type,
  Address,
  PropertySpec,
  GetaddressResponse,
  SearchAddressResponse,
  Inspector,
  Fee,
  DictionaryReportType,
  EmailLog,
  PresetError,
Tenant,
} from "@/models";

import { DxDateBox, DxButton as DxDateBoxButton } from "devextreme-vue/date-box";
import FloorplanComponent from "@/components/floorplan/Floorplans.vue";
import Documents from "@/components/document/Documents.vue";
import { useToast } from "vue-toastification";

import PostcodeReportSearchModal from "@/components/modals/PostcodeReportSearchModal.vue";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import ProgressButton from "@/components/ProgressButton.vue";
import BookingDetailModal from "@/components/modals/BookingDetailModal.vue";

import { DxTooltip } from "devextreme-vue/tooltip";
import Ably from "ably";

import EmailModal from "@/components/modals/EmailModal.vue";
import EmailLogsModal from "@/components/modals/EmailLogsModal.vue";
import index from "../index.vue";

const emit = defineEmits(["showfloorplandocument", "showdataentrydocument"]);

const store = useStore();
const route = useRoute();
const router = useRouter();
const toasted = useToast();
const expanded = ref(false);

const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const dataentryconfirmationEmailModal = ref(null);
const dataentryconfirmationEmailLogsModal = ref(null);
const alertDialog = ref(null);
const cmsSearchModal = ref(null);
const otherroomstooltip = ref(null);

const dynamicRefs = ref({});
const bookingDetailModal = ref(null);

const hasUnsavedChanges = computed(
  () => store.getters["diary/hasUnsavedChanges"]
);
const dictionary = computed(() => store.getters["dictionary/current"]);
const isQCuser = computed(() => store.getters["auth/isQCUser"]);
const isBookingManagement = computed(
  () => store.getters["auth/isBookingManagement"]
);
const statecurrentdate = computed(() => store.getters["diary/currentdate"]);
const booking = computed(() => store.getters["diary/booking"]);
const inspectors = computed(() => store.getters["diary/inspectorlist"]);
const reportTypes = computed(() => store.getters["dictionary/reportTypes"]);
const jobtypelist = computed(() => store.getters["diary/jobtypelist"]);
const jobtypeslug = computed(() => store.getters["diary/jobtype"]);
const offactreporttypes = computed(
  () => store.getters["diary/offactreporttypes"]
);
const internaljobtypeslug = computed(
  () => store.getters["diary/internaljobtype"]
);
const jobtypemap = computed(() => store.getters["diary/jobtypemap"]);
const startdate = computed(() => store.getters["diary/startdate"]);
const enddate = computed(() => store.getters["diary/enddate"]);
const appointmenttime = computed(() => store.getters["diary/appointmenttime"]);
const _cleaningnotes = computed(() => store.getters["diary/cleaningnotes"]);
const propertytypelist = computed(
  () => store.getters["diary/propertytypelist"]
);
const propertyspec = computed(
  (): PropertySpec => store.getters["diary/propertyspec"]
);
const address = computed(() => store.getters["diary/address"]);
const emaillogs = computed(() => store.getters["diary/emaillogs"]);
const systemproperties = computed(
  () => store.getters["dictionary/systemproperties"]
);
const internaljobtypedisplayname = computed(
  (): Map<string, string> => store.getters["diary/internaljobtypedisplayname"]
);
const removeRequest = (id: string) => store.commit("app/removeRequest", id);
const setCurrentdate = (currentdate: Date) =>
  store.commit("diary/setCurrentdate", currentdate);
const setJobtype = (type: DictionaryReportType) =>
  store.commit("diary/setJobtype", type);
const setInternaljobtype = (jobtype: string) => {
  store.commit("diary/setInternaljobtype", jobtype);
};
const setBooking = (booking: Booking) =>
  store.commit("diary/setBooking", booking);
const setStartdate = (startdate: string) =>
  store.commit("diary/setStartdate", startdate);
const setEnddate = (enddate: string) =>
  store.commit("diary/setEnddate", enddate);
const setAppointmenttime = (appointmenttime: string) =>
  store.commit("diary/setAppointmenttime", appointmenttime);
const setPaymentdate = (paymentdate: string) =>
  store.commit("diary/setPaymentdate", paymentdate);
const setPostcodeReports = (reports: Report[]) =>
  store.commit("reports/setPostcodeReports", reports);
const setPostcodeBookings = (reports: Booking[]) =>
  store.commit("diary/setPostcodeBookings", reports);
const resetUnsavedChanges = () => store.commit("diary/resetUnsavedChanges");
const setCurrentEmailLog = (log: EmailLog) =>
  store.commit("diary/setCurrentEmailLog", log);

const sortInpectorsInState = (params: {
  previousreport: Report;
  previousbooking: Booking;
  postcode: string;
}) => store.dispatch("diary/sortInpectors", params);
const generateReportPdfAction = (report: Report) =>
  store.dispatch("reports/generateReportPdf", report);
const generateReportSummaryPdfAction = (report: Report) =>
  store.dispatch("reports/generateReportSummaryPdf", report);
const getBookings = (payload?: { date?: string }) =>
  store.dispatch("diary/getBookings", payload);
const setBookingDeep = (payload: { path: string; data: any }) =>
  store.dispatch("diary/setBookingDeep", payload);
const setAddressDeep = (payload: { path: string; data: any }) =>
  store.dispatch("diary/setAddressDeep", payload);
const setPropertyspecDeep = (payload: { path: string; data: any }) =>
  store.dispatch("diary/setPropertyspecDeep", payload);
const searchReports = (postcode: string) =>
  store.dispatch("reports/searchReports", postcode);
const searchGetaddressByPostcode = (postcode: string) =>
  store.dispatch("diary/searchGetaddressByPostcode", postcode);
const searchGetaddressByAddress = (postcode: string) =>
  store.dispatch("diary/searchGetaddressByAddress", postcode);
const getEmailLogs = (bookingid: string) =>
  store.dispatch("diary/getEmailLogs", bookingid);
const searchBookings = (params) =>
  store.dispatch("diary/searchBookings", params);
const reorderedinternaljobtypelist = ref([]);

const numberofroomsoptions = ref([
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]);
const furnishedOptions = ref([
  "Unfurnished",
  "Part furnished",
  "Fully furnished",
  "Fully furnished with linen",
]);
onMounted(() => {
  if (currentdate.value && currentdatewatch.value !== '2050-12-31') {
    getBookings({
      date: formatDate(currentdate.value),
    });
  }
});

const currentdate = computed({
  get: () => statecurrentdate.value,
  set: (val: Date) => setCurrentdate(val),
});
const currentdatewatch = computed(() =>
  moment(currentdate.value).format("YYYY-MM-DD")
);

watch(
  () => currentdatewatch.value,
  () => {
    if (currentdate.value && currentdatewatch.value !== '2050-12-31') {
      getBookings({ date: formatDate(currentdate.value) }).then(() =>
        sortInspectors()
      );
    }
  }
);

const jobtypeoptions = computed((): DictionaryReportType[] => {
  let list = jobtypelist.value.map((jt: string) => {
    let dictionaryReportType: DictionaryReportType | undefined =
      reportTypes.value.find((type: DictionaryReportType) => type.slug === jt);
    if (!dictionaryReportType) {
      dictionaryReportType = prepareDictionaryReportType(jt);
    }
    return dictionaryReportType;
  });
  return list;
});

const prepareDictionaryReportType = (val: string): DictionaryReportType => {
  let type = new DictionaryReportType();
  if (val) {
    let name = val
      .split(" ")
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
    return DictionaryReportType.parse({
      id: val,
      slug: val,
      name: name,
      displayname: name,
      createallowed: false,
      dictionaryflag: false,
    });
  }
  return type;
};

const jobtype = computed({
  get: (): DictionaryReportType | undefined => {
    let type: DictionaryReportType | undefined;
    if (dictionary.value && dictionary.value.reporttypes) {
      type = dictionary.value.reporttypes.find(
        (t) => t.slug === jobtypeslug.value
      );
    }
    if (!type) type = prepareDictionaryReportType(jobtypeslug.value);
    return type;
  },
  set: (value: DictionaryReportType | undefined) => {
    if (value) {
      setJobtype(value);
      if (value.slug === "checkout") {
        if (
          booking.value.previousbooking?.id ||
          booking.value.previousreport?.id
        ) {
          setInternaljobtype("Check-Out - off ACT report");
        } else if (
          booking.value.previousreport &&
          !booking.value.previousreport.id
        ) {
          setInternaljobtype("Check-Out - off upload");
        }
      }
    }
  },
});

const internaljobtype = computed({
  get: (): string => internaljobtypeslug.value,
  set: (value: string) => setInternaljobtype(value),
});

watch(
  () => jobtype.value,
  (newValue: any, oldValue: any) => {
    if (!oldValue || (newValue && newValue.slug != oldValue.slug)) {
      if (jobtype.value) {
        let templist = jobtypemap.value.get(jobtype.value?.slug);
        if (jobtype.value.slug === "checkin") {
          if (
            booking.value.previousbooking?.id &&
            booking.value.previousbooking.internaljobtype ===
              "Check-Out - off upload"
          ) {
            // Remove "Check-In - off ACT report"
            templist = [
              "Check In - last CO, from upload",
              "Check-In - back to back",
            ];
          } else {
            // Remove "Check-In - off upload",
            templist = ["Check-In - off ACT report", "Check-In - back to back"];
          }
        }
        if (templist) reorderedinternaljobtypelist.value = templist;

        if (
          reorderedinternaljobtypelist.value &&
          reorderedinternaljobtypelist.value.length == 1
        ) {
          setInternaljobtype(reorderedinternaljobtypelist.value[0]);
        }
        let index = reorderedinternaljobtypelist.value.indexOf(
          internaljobtype.value
        );
        if (index < 0) setInternaljobtype("");
      }

      if (
        !booking.value.id &&
        booking.value?.previousbooking?.id &&
        !oldValue.slug
      ) {
        // First time setting jobtype on a new booking
        // If converting from old booking, apply conversion rules

        // Exclude back-to-back CI, this will be done under @Watch("internaljobtype")
        if (newValue?.slug != "checkin") {
          if (booking.value?.previousbooking?.inspector?.id) {
            // This is just to load fully loaded inspector object inside previous booking
            // This is later used to terermine timing of the booking
            fullloadInspector(booking.value.previousbooking);
          }
          convert(booking.value.previousbooking, booking.value, setBookingDeep);
        }
      }
    }
  }
);

const applycommonconversionrules = (selectedbooking: Booking) => {
  convertcommon(selectedbooking, booking.value, setBookingDeep);
  channel.publish("commoncovertapplied", {
    deviceid: deviceid,
    bookingid: booking.value.id,
  });
};
const googlebookingselected = (selectedbooking: Booking) => {
  const modal = bookingDetailModal.value as any;
  modal.init(selectedbooking);
  modal.show();
};

watch(
  () => internaljobtype.value,
  () => {
    // Only process back-to-back CI, rest will be taken care under @Watch("jobtype")
    if (isNewBooking() && jobtype.value?.slug === "checkin") {
      if (booking.value?.previousbooking?.inspector?.id) {
        // This is just to load fully loaded inspector object inside previous booking
        // This is later used to terermine timing of the booking
        fullloadInspector(booking.value.previousbooking);
      }
      if (booking.value?.previousbooking?.id) {
        convert(booking.value.previousbooking, booking.value, setBookingDeep);
        if (booking.value.internaljobtype === Booking.B2BCI) {
          setCurrentdate(booking.value.previousbooking.startdateAsDate);
        }
      }
    }
  }
);

const fullloadInspector = (book: Booking) => {
  let inspector: Inspector | undefined = inspectors.value.find(
    (i: Inspector) => i.id === booking.value.previousbooking.inspector.id
  );
  if (inspector?.id) book.inspector = new Inspector(inspector);
};

const setBookingDate = (e: any) => {
  let selecteddate = formatDate(e.value);
  let currentstartdate = formatDate(booking.value.startdateAsDate);
  let bookingdate = moment(e.value).utc().format(actProperty.bookingdateformat);
  setStartdate(postfixUTCTime(bookingdate, startdate.value));
  setEnddate(postfixUTCTime(bookingdate, enddate.value));
  if(appointmenttime.value)
    setAppointmenttime(postfixUTCTime(bookingdate, appointmenttime.value));
  setPaymentdate(bookingdate);

  channel.publish("currentdateChanged", {
    deviceid: deviceid,
    bookingdate: bookingdate,
    newbooking: isNewBooking(),
    postcode: postcode.value,
  });
  if (booking.value.id && currentstartdate != selecteddate) {
    // If this is an existing booking, unlock the appointmenttime
    // Reset all communication flags
    // We essentially need to resend all notifications
    setBookingDeep({ path: "emaillogs.clientconfirmationsentdate", data: "" });
    setBookingDeep({ path: "emaillogs.tenantconfirmationsentdate", data: "" });
    setBookingDeep({ path: "emaillogs.dataentryconfirmationsentdate", data: "" });
    setBookingDeep({
      path: "emaillogs.landlordconfirmationsentdate",
      data: "",
    });
    setBookingDeep({
      path: "emaillogs.tenantconfirmationreceiveddate",
      data: "",
    });
    setBookingDeep({ path: "smslogs.tenantconfirmationsentdate", data: "" });
    setBookingDeep({
      path: "smslogs.tenantconfirmationreceiveddate",
      data: "",
    });
    setBookingDeep({ path: "preferredappointmenttime", data: 0 });
    setBookingDeep({ path: "tenantattending", data: "unsure" });
    //https://trello.com/c/GgMBtP84/764-1-access-via-tt-toggle-reset-when-date-changed
    setBookingDeep({ path: "confirmaccess", data: false});
    booking.value.tenantsarray.forEach((obj:Tenant) => obj.attending = "");
    setBookingDeep({ path: "tenants", data: booking.value.tenantsarray});
    showDateChangeReminderModal();
  }
};

const isNewBooking = () => {
  return route.params.id == "new";
};

const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};

const cleaningnotes = computed({
  get: () => _cleaningnotes.value,
  set: (val) => setBookingDeep({ path: "cleaningnotes", data: val }),
});

const line1 = computed({
  get: () => address.value.line1,
  set: (data) => setAddressDeep({ path: "line1", data }),
});

const line2 = computed({
  get: () => address.value.line2,
  set: (data) => setAddressDeep({ path: "line2", data }),
});

const town = computed({
  get: () => address.value.town,
  set: (data) => setAddressDeep({ path: "town", data }),
});

const postcode = computed({
  get: () => address.value.postcode,
  set: (val: string) => {
    setAddressDeep({ path: "postcode", data: val.toString().toUpperCase() });
  },
});

const county = computed({
  get: () => address.value.county,
  set: (data) => setAddressDeep({ path: "county", data }),
});

const propertytype = computed({
  get: () => propertyspec.value.propertytype,
  set: (data) => setPropertyspecDeep({ path: "propertytype", data }),
});
const setPropertytype = (data: string) => {
  setPropertyspecDeep({ path: "propertytype", data });
};

const bedrooms = computed({
  get: () => propertyspec.value.bedrooms,
  set: (data) => setPropertyspecDeep({ path: "bedrooms", data }),
});

const bathrooms = computed({
  get: () => propertyspec.value.bathrooms,
  set: (data) => setPropertyspecDeep({ path: "bathrooms", data }),
});

const receptions = computed({
  get: () => propertyspec.value.receptions,
  set: (data) => setPropertyspecDeep({ path: "receptions", data }),
});

const other = computed({
  get: () => propertyspec.value.other,
  set: (data) => setPropertyspecDeep({ path: "other", data }),
});

const sqft = computed({
  get: () => propertyspec.value.sqft,
  set: (data) => setPropertyspecDeep({ path: "sqft", data }),
});

const floorplan = computed({
  get: () => propertyspec.value.floorplan,
  set: (data) => setPropertyspecDeep({ path: "floorplan", data }),
});
const showFloorplanDocument = (index: number) => {
  emit("showfloorplandocument", index);
};

const furnished = computed({
  get: () => propertyspec.value.furnished,
  set: (data) => setPropertyspecDeep({ path: "furnished", data }),
});
const setFurnished = (data: string) => {
  setPropertyspecDeep({ path: "furnished", data });
};

const provisional = computed({
  get: () => booking.value.provisional,
  set: (data) => setBookingDeep({ path: "provisional", data }),
});
const provisionalnotes = computed({
  get: () => booking.value.provisionalnotes,
  set: (data) => setBookingDeep({ path: "provisionalnotes", data }),
});

const removepreviousreport = () => {
  setBookingDeep({ path: "previousreport", data: new Report() });
  setBookingDeep({ path: "previousbooking", data: new Booking() });
  // https://trello.com/c/7sCyIhnK/748-ability-to-clear-previous-report-details
  // Added Bilal Patel
  setBookingDeep({ path: "dataentryreport", data: new Report() });
};

const sortInspectors = () => {
  sortInpectorsInState({
    previousreport: booking.value?.basereport,
    previousbooking: booking.value?.previousbooking,
    postcode: postcode.value,
  });
};

const addressSearch = ($event: any) => {
  let address = $event.target.value;
  searchReports(address);
  search(address);
  const modal = cmsSearchModal.value;
  modal.init();

  searchGetaddressByAddress(address)
    .then((response) => {
      const list = processGetAddressResponse(response);
      setPostcodeReports(list);
    })
    .catch(() => setPostcodeReports([]));
  modal.show();
};

const postcodeSearch = ($event: any) => {
  let postcode = $event.target.value.toUpperCase();
  searchReports(postcode);
  search(postcode);
  const modal = cmsSearchModal.value;
  modal.init();

  searchGetaddressByPostcode(postcode)
    .then((response) => {
      const list = processGetPostcodeResponse(response);
      list.forEach((r) => (r.address.postcode = postcode));
      setPostcodeReports(list);
    })
    .catch(() => setPostcodeReports([]));
  modal.show();
  channel.publish("postcodeSearch", {
    postcode,
    deviceid: deviceid,
    newbooking: isNewBooking(),
  });
};

const search = (val: string) => {
  searchBookings({ anytext: val, cancelled: "false", sort: "created_at", limit: 100 }).then((bookings) => {
    bookings.sort((b1: Booking, b2: Booking) => {
      if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
        return -1;
      } else if (moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))) {
        return 1;
      } else {
        return 0;
      }
    });
    setPostcodeBookings(bookings);
  });
};

const processGetPostcodeResponse = (response: GetaddressResponse) => {
  return response.addresses.map((address) => {
    let splitaddressarray = address.split(",");
    let report = new Report();
    report.address = new Address({
      line1: splitaddressarray[0],
      line2: splitaddressarray[1],
      town: splitaddressarray[5],
    });
    return report;
  });
};

const processGetAddressResponse = (response: SearchAddressResponse) => {
  return response.addresses.map((address) => {
    let splitaddressarray = address.split(",");
    let report = new Report();
    report.address = new Address({
      line1: splitaddressarray[0],
      line2: splitaddressarray[1],
      town: splitaddressarray[2],
    });
    return report;
  });
};

const offupload = computed(() => {
  return (
    internaljobtype.value && internaljobtype.value === "Check-Out - off upload"
  );
});

const previousreportpi = computed(() => {
  let pi = booking.value.previousreportpi;
  if (pi) pi = `PI: ${pi}`;
  return pi;
});

const previousreportcleanliness = computed(() => {
  let cleanliness = "";
  if (
    booking.value?.basereport &&
    booking.value.basereport.propertyDetails &&
    booking.value.basereport.propertyDetails.generalNotes
  ) {
    let indexa =
      booking.value.basereport.propertyDetails.generalNotes.indexOf(
        "Cleanliness"
      );
    let indexb =
      booking.value.basereport.propertyDetails.generalNotes.indexOf("\n");
    if (indexa >= 0 && indexb >= 0) {
      cleanliness =
        booking.value.basereport.propertyDetails.generalNotes.substring(
          indexa,
          indexb
        );
    }
  }
  return cleanliness;
});

const previousreportitemcount = computed(() => {
  let itemcount = 0;
  if (booking.value?.basereport && booking.value.basereport.rooms) {
    booking.value.basereport.rooms.forEach((r) => {
      r.sections.forEach((s) => {
        s.types.forEach((t) => {
          itemcount += t.items.length;
        });
      });
    });
  }
  return itemcount ? "Item count: " + itemcount : "";
});

const previousreportref = computed(() => {
  return booking.value?.basereport?.ref;
});

watch(
  () => previousreportref.value,
  () => {
    sortInspectors();
  }
);

watch(
  () => postcode.value,
  () => {
    if (!isNewBooking()) {
      channel.publish("postcodeSearch", {
        postcode: postcode.value,
        deviceid: deviceid,
        newbooking: false,
      });
    } else if (!postcode.value) {
      channel.publish("postcodeSearch", {
        postcode: postcode.value,
        deviceid: deviceid,
        newbooking: true,
      });
    }
  }
);

const showDataentryDocument = (index: number) => {
  emit("showdataentrydocument", index);
};

const dataentrydocumentadded = () => {
  // Add new report for dataentry
  /*let dataentryreport = new Report();
  dataentryreport.type = "inventory";
  dataentryreport.address = new Address(booking.value.address);
  dataentryreport.address.line1 = "UPLOAD " + booking.value.address.line1;
  addDataentryReport(dataentryreport)
    .then((report: Report) => {
      this.setDataentryreport(report);
      toasted.success(`Report entry created for dataentry`);
    })
    .catch((err: any) => actProperty.displayError(err));*/
};

const dataentryreportitemcount = computed(() => {
  let itemcount = 0;
  if (booking.value?.dataentryreport && booking.value?.dataentryreport?.rooms) {
    booking.value?.dataentryreport.rooms.forEach((r: Room) => {
      r.sections.forEach((s: Section) => {
        s.types.forEach((t: Type) => {
          itemcount += t.items.length;
        });
      });
    });
  }
  return itemcount ? "Item count: " + itemcount : "";
});

/**
 * Generate INV/CI/CO Report PDF
 */
const generateReportPdf = (report: Report, requestId: string): void => {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
};

/**
 * Generate COSM Report PDF
 */
const generateReportSummaryPdf = (report: Report, requestId: string): void => {
  generateReportSummaryPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} Summary PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
};

const customLabelForReportType = (type: DictionaryReportType) => {
  return type.displayname;
};
const customLabelForInternaljobtype = (internaljobtype: string): string => {
  return actProperty.customLabelForInternaljobtype(
    internaljobtypedisplayname.value,
    internaljobtype
  );
};

const previousreportotherroomlist = computed(() => {
  let list: string[] = [];
  if (
    booking.value &&
    booking.value?.basereport &&
    booking.value?.basereport?.rooms
  ) {
    let roomtypes: string[] = ["bedroom", "bathroom", "ensuite", "reception"];
    list = booking.value?.basereport?.rooms
      .filter((r) => {
        let index = roomtypes.findIndex((type: string) => type === r.type);
        return index < 0;
      })
      .map((room: Room) => room.name);
  }
  return list;
});

const showAddressChangedWarning = (field: string) => {
  if (isBookingManagement.value || isQCuser.value) return;
  // Don't show alert for a new booking
  if (!booking.value.id) return;

  const modal = alertDialog.value as any;
  if (modal) {
    modal.init(
      "Warning",
      "If you change the address of this booking, you are overriding an existing booking",
      "Yes, I understand",
      "",
      field
    );
    modal.show();
  }
};

const showIncorrectEntrySelectedFromAddressSearchWarning = (field: string) => {
  if (isBookingManagement.value || isQCuser.value) return;
  const modal = alertDialog.value as any;
  if (modal) {
    modal.init(
      "Warning",
      "You have selected a property address from postcode search, but the same property already exists on our system. You should select from either list of old reports or bookings",
      "Yes, I understand",
      "",
      field
    );
    modal.show();
  }
};

const showDateChangeReminderModal = () => {
  if (isBookingManagement.value || isQCuser.value) return;
  // Don't show reminder for a new booking
  if (!booking.value.id) return;

  const modal = alertDialog.value as any;
  if (modal) {
    modal.init(
      "Reminder",
      "Please inform TT of the new date.",
      "Got it",
      "",
      "reminder"
    );
    modal.show();
  }
};

const prepadate = computed(() => {
  let pdate = "";
  if (prepsubbooking.value) {
    pdate = prepsubbooking.value.bookingdate;
  }
  return pdate;
});
const prepsubbooking = computed(() => {
  let sub: Booking | undefined = undefined;
  if (booking.value.subbookings?.length) {
    sub = booking.value.subbookings.find(
      (b: Booking) => b.subtype === Booking.PREP
    );
  }
  return sub;
});

const showTooltip = () => {
  let tooltip = otherroomstooltip.value as any;
  tooltip.instance.show();
  tooltip.instance.repaint();
};
const hideTooltip = () => {
  let tooltip = otherroomstooltip.value as any;
  tooltip.instance.hide();
};

const otherroomstooltipHtml = computed(() => {
  let tiptext = `<div class="text-left">`;
  previousreportotherroomlist.value.forEach(
    (room: string) => (tiptext = `${tiptext}${room}<br/>`)
  );
  return `${tiptext}</div>`;
});

const unlockBooking = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch("diary/unlockBooking", booking).then((b: Booking) => {
      channel.publish("bookingunlocked", { bookingid: b.id });
    });
  }
};

const resetForm = () => {
  if (hasUnsavedChanges.value) {
    let confirm = actProperty.confirmPrompt(
      "You have unsaved changes. Do you want to continue without saving your changes?"
    );
    confirm
      .then(() => {
        unlockBooking(booking.value);
        resetUnsavedChanges();
        const newbooking = initnewbooking();
        setBooking(newbooking);
        channel.publish("unselectBooking", { deviceid: deviceid });
        router.push("/diary/new").catch((err) => {});
      })
      .catch((err) => actProperty.displayError(err));
  } else {
    unlockBooking(booking.value);
    const newbooking = initnewbooking();
    setBooking(newbooking);
    channel.publish("unselectBooking", { deviceid: deviceid });
    router.push("/diary/new").catch((err) => {});
  }
};

const initnewbooking = () => {
  const invoice = new Report({ fees: [new Fee()] });
  const booking = new Booking({ invoices: [invoice] });
  currentdate.value =   moment("2050-12-31", "YYYY-MM-DD")
      .utc()
      .toDate();
  setCurrentdate(currentdate.value);
  const fiveam = moment(currentdate.value)
    .utc()
    .startOf("day")
    .add(5, "hours")
    .format(actProperty.bookingDateFormat);
  booking.startdate = fiveam;
  booking.enddate = fiveam;
  booking.appointmenttime = fiveam;
  return booking;
};

const currentdatesameastoday = () => {
  return moment(currentdate.value).isSame(new Date(), "day");
};

const fromaddress = computed(() => {
  let prop = systemproperties.value.find(
    (sp) => sp.propertykey === "booking.email.fromaddress"
  );
  return prop ? prop?.value : "";
});

const dataentryemailaddress = computed(() => {
  let prop = systemproperties.value.find(
    (sp) => sp.propertykey === "dataentry.email"
  );
  let emailaddresses = [];
  if (prop && prop.value) {
    emailaddresses = prop.value.split(",");
  }
  return emailaddresses.filter((e) => e);
});

const showDataentryConfirmationModal = () => {
  if (booking.value.dataentryreport?.ref) {
    const modal = dataentryconfirmationEmailModal.value;
    modal.init();

    modal.show();
  } else {
    const modal = alertDialog.value;
    modal.init(
      "Warning",
      "Please save the booking first and then try to send email confirmation",
      "Ok"
    );
    modal.show();
  }
};

const showDataentryEmailLogsModal = () => {
  const modal = dataentryconfirmationEmailLogsModal.value as any;
  getEmailLogs(booking.value.id);
  if (emaillogs.value?.dataentryemaillogs?.length)
    setCurrentEmailLog(emaillogs.value.dataentryemaillogs[0]);
  else setCurrentEmailLog(new EmailLog());
  modal.show();
};

const clearJobtype = () => {
  setJobtype(new DictionaryReportType());
};
const bookingTitle = (booking: Booking) => {
  let bookingtitle = actProperty.getBookingTitle(booking, dictionary.value);
  if (booking.jobtype && booking.titlenotes) {
    bookingtitle = `${bookingtitle}: ${booking.titlenotes}`;
  } else {
    bookingtitle = `${bookingtitle}`;
  }
  return bookingtitle;
};

const prepandKeyJobData = computed(() => {
  let prepandKeyJob = booking.value.subbookings.filter(
    (f: any) => f.subtype === "Prep" || f.subtype === "Key"
  );
  return prepandKeyJob;
});
</script>

<style scoped lang="scss">
.context-message {
  color: #a4a4a4;
  margin-bottom: 11px;
}

h5 {
  color: #686868;
}

.booking-info {
  color: #253746;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-30 {
  margin-top: 30px;
}

.previousreportdetails {
  margin: 5px;
  margin-left: 0px;
  list-style-type: none;
  padding-inline-start: 5px;
}

.inner {
  position: absolute;
}

.strikethrought {
  text-decoration: line-through
}
</style>

<style lang="scss">
.height-43 {
  min-height: 43px !important;
}

.height-40 > .multiselect__tags {
  max-height: 40px !important;
}

.trafficlight {
  height: 20px;
  width: auto;
}

.darker-grey-background {
  background-color: #f1f3f5;
}

.hide {
  display: none;
}
</style>

<style lang="scss">
.outline .dx-datebox {
  border: 2px solid red !important;
}

.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: 30%;
}

.dx-field-label {
  width: 70%;
  color: #212529;
}

.whatwhenpl {
  padding-left: 10%;
}

#booking-detail-modal-Backdrop,
#whatwhen-booking-detail-modal {
  z-index: 1060;
}
.text-flex {
  display: flex;
}
</style>