<template>
  <div
    :id="name"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`errormodal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="error-modal-label">
            PvBookings Upload File Errors
          </h3>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body"
          style="
            height: 80vh; /* Set the height to 80% of the viewport height */
            overflow-y: auto; /* Enable vertical scrollingif content overflows vertically */
            overflow-x: hidden;
          "
        >
          <div class="col-md-12" v-if="errors && errors.length">
            <ul class="error-list">
              <li v-for="(error, index) in errors" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
          <div class="col-md-12" v-else>
            <p>No errors found.</p>
          </div>
        </div>
        <div
          class="modal-footer d-flex align-items-center justify-content-between"
        >
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from "jquery";
import { ref, defineProps, defineExpose } from "vue";

const errors = ref([]);
const props = defineProps<{
  name: string;
}>();
// Initialize component with props
const init = (errorsdata: []) => {
  if (errorsdata.length) {
    errors.value = errorsdata;
  }
};
const show = () => {
  if ($(`#${props.name}` + "Backdrop").length == 0) {
    const backdropDiv = $(
      '<div class="modal-backdrop fade show" id="' +
        props.name +
        'Backdrop"></div>'
    );
    $("body").append(backdropDiv);
    $(`#${props.name}`).show();
  }
};
const hide = () => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + "Backdrop").remove();
    $(`#${props.name}`).hide();
  }
};
defineExpose({ init, show, hide });
</script>

<style scoped lang="scss">
.alert-dialog {
  z-index: 1051;
}
.error-list {
  list-style-type: none;
  padding: 0;
}

.error-list li {
  color: red; /* Set text color to red */
  font-weight: bold; /* Optional: Make the errors bold for emphasis */
  margin-bottom: 5px; /* Optional: Add spacing between errors */
}
</style>
