<template>
  <div @focusout="closeDropdown">
    <DxDateBox
      v-show="changesdatepvmid === pvm.id"
      class="form-control height-43"
      v-model="scheduleddate"
      display-format="dd/MM/yyyy"
      type="date"
      picker-type="calendar"
      apply-value-mode="instantly"
      :calendarOptions="{ firstDayOfWeek: 1 }"
      @value-changed="setScheduledDate(pvm.id, $event.value)"
      :ref="'descheduleddate-' + pvm.id"
    />
    <div
      v-show="changesdatepvmid !== pvm.id"
      :class="{ 'transparent-text': !pvm.scheduleddate }"
      @click="changeSDateReport()"
    >
      {{
        pvm.scheduleddate
          ? actProperty.formatDateForDisplay(pvm.scheduleddate)
          : "Select"
      }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps, inject } from "vue";
import { useStore } from "vuex";
import { Booking, Inspector } from "@/models";
import moment from "moment-timezone";
import { DxDateBox } from "devextreme-vue/date-box";
const store = useStore();
const actProperty: any = inject("actProperty");
const pvbookings = computed(
  () => store.getters["pvbookings/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changesdatepvmid: string;
}>();
const emit = defineEmits(["schedulatedatepvmselected"]);

const getBookingsForPI = async (params): Promise<Booking[]> => {
  return await store.dispatch("pvbookings/getBookingsForPI", params);
};
const setPvBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/setBookings", bookings);
};
const scheduledDateSelect = ref<{ [key: string]: HTMLElement | undefined }>({});
const sethasUnsavedChanges = (data: boolean) =>
  store.commit("pvbookings/sethasUnsavedChanges", data);
const { pvm, changesdatepvmid } = toRefs(props);

const changeSDateReport = () => {
  emit("schedulatedatepvmselected", pvm.value.id);

  const dropdown = scheduledDateSelect.value[
    `descheduleddate-${pvm.value.id}`
  ] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const sortedinspectorlist = computed(() => {
  const list = store.getters["pvbookings/inspectorlist"];
  return list.sort((i1: Inspector, i2: Inspector) =>
    i1.name.localeCompare(i2.name)
  );
});
const setScheduledDate = async (id: string, $event: any) => {
  if ($event === "Invalid date" || $event === null) return true;
  const pmIndex = pvbookings.value.findIndex(
    (r: Booking) => r.id === id
  );    
  pvbookings.value[pmIndex].scheduleddate =
    moment($event).format("YYYY-MM-DD[T]HH:mm");  
  if (
    pvbookings.value[pmIndex].scheduleddate &&
    pvbookings.value[pmIndex].inspector.id
  ) {    
    pvbookings.value[pmIndex].status = "Scheduled and in queue";
  }
  sethasUnsavedChanges(true);
  pvm.value.id = id;
  setPvBookings(pvbookings.value);
  let booking = pvbookings.value.find((f: Booking) => f.id == pvm.value.id);
  if (booking.scheduleddate && booking.inspector.id) {
    const cachedate = moment(booking.scheduleddate).utc().format("DD-MM-YYYY");
    await getBookingsForPI({
      date: cachedate,
      inspectorid: booking.inspector.id,
      fieldlist: "id,startdate,enddate,jobtype,all_day,address",
      period: "days",
    });
    let inspectorIndex = sortedinspectorlist.value.findIndex(
      (f: Inspector) => f.id === booking.inspector.id
    );
    const stime = actProperty.determineViableSlot(
      booking,
      sortedinspectorlist.value[inspectorIndex]
    );
    let scheduledateAsDate = moment(booking.scheduleddate).utc().toDate();
    booking.startdate = actProperty.prependDate(
      stime,
      scheduledateAsDate,
      scheduledateAsDate
    );
    booking.enddate = moment(booking.startdate)
      .utc()
      .add(30, "minutes")
      .format(actProperty.bookingdateutcformat);
    if (booking.startdate && booking.enddate) {
      let bookings =
        sortedinspectorlist.value[inspectorIndex].bookingsmap.get(cachedate);
      let bIndex = bookings?.findIndex((f: Booking) => f.id === booking.id);
      if (bIndex === -1) {
        bookings.push(booking); // Add a copy of the new booking
      } else {
        bookings[bIndex] = booking; // Update with a copy of the booking
      }
      sortedinspectorlist.value[inspectorIndex].bookingsmap.set(
        cachedate,
        bookings
      );
      const updateIndex = pvbookings.value.findIndex(
        (r: Booking) => r.id === booking.id
      );
      if (updateIndex != -1) {
        pvbookings.value[updateIndex] = booking;
        if(pvbookings.value[pmIndex].booking && pvbookings.value[pmIndex].booking?.id) {
          pvbookings.value[pmIndex].booking = booking;
        }
      }
    }
  } else {
    const startTime = "T09:00:00.000Z";
    let date = pvbookings.value[pmIndex].scheduleddate ? pvbookings.value[pmIndex].scheduleddate : pvbookings.value[pmIndex].duedate;
    pvbookings.value[pmIndex].startdate = subjobendDate(startTime, date) ;
    pvbookings.value[pmIndex].enddate = subjobendDate(startTime, date);
    if(pvbookings.value[pmIndex].booking && pvbookings.value[pmIndex].booking?.id) {
      pvbookings.value[pmIndex].booking.startdate = subjobendDate(startTime, date) ;
      pvbookings.value[pmIndex].booking.enddate = subjobendDate(startTime, date);
    }
  }
  emit("schedulatedatepvmselected", "");
};
const subjobendDate = (time, date) => {
  let value = "";
  if (time) {
    let dt = date;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};
const closeDropdown = () => {
  emit("schedulatedatepvmselected", "");
};
const scheduleddate = ref<Date | null>(null); // Use `ref` in Vue 3
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
