<template>
  <div class="customers list">
    <div class="row mb-3">
      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Due date</label>
              <div class="col-6">
                <DxDateRangeBox
                  v-model="computedDateRange"
                  :min="minDate"
                  :max="maxDate"
                  displayFormat="dd/MM/yyyy"
                  label="Select Date Range"
                />
              </div>
              <div class="col-3" style="padding-top: 10px">
                <button class="btn btn-outline-secondary" @click="showAll()">
                  Show All
                </button>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">PostCode</label>
              <div class="col-10">
                <multiselect-text
                  v-model="postCodeData"
                  :options="uniquePostcodes"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                >
                  <!-- Template for rendering options with checkboxes -->
                  <template #option="{ option, selected, disabled }">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        :checked="postCodeData.includes(option)"
                        :disabled="disabled"
                      />
                      <span style="padding-left: 10px"> {{ option }}</span>
                    </div>
                  </template>
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen"
                      >{{ values.length }} options selected</span
                    >
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Status</label>
              <div class="col-10">
                <multiselect-text
                  v-model="statusData"
                  :options="statusList"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                >
                  <!-- Template for rendering options with checkboxes -->
                  <template #option="{ option, selected, disabled }">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        :checked="statusData.includes(option)"
                        :disabled="disabled"
                      />
                      <span style="padding-left: 10px"> {{ option }}</span>
                    </div>
                  </template>
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="statusData.length"
                      v-show="!isOpen"
                      >{{ statusData.length }} options selected</span
                    >
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Client</label>
              <div class="col-10">
                <singleselect-text
                  v-model="clientData"
                  :options="uniqueClients"
                  :allow-empty="true"
                  :taggable="false"
                >
                  <template #clear v-if="clientData">
                    <i
                      @mousedown.prevent.stop="clientData = ''"
                      class="multiselect__clear fa fa-times"
                      aria-label="Clear Client"
                    ></i>
                  </template>
                </singleselect-text>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Branch</label>
              <div class="col-10">
                <singleselect-text
                  v-model="branchData"
                  :options="uniqueBranch"
                  :allow-empty="true"
                  :taggable="false"
                >
                  <template #clear v-if="branchData">
                    <i
                      @mousedown.prevent.stop="branchData = ''"
                      class="multiselect__clear fa fa-times"
                      aria-label="Clear Branch"
                    ></i>
                  </template>
                </singleselect-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dateRange[0] != null && dateRange[1] != null" class="col-12">
        <div class="col-3">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Scope</label>
            <div class="col-6">
              <singleselect-text
                v-model="weekData"
                :options="weekList"
                :allow-empty="true"
                :taggable="false"
              >
                <template #clear v-if="weekData">
                  <i
                    @mousedown.prevent.stop="weekData = ''"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Scope"
                  ></i>
                </template>
              </singleselect-text>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!pvbookingsData.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No Pv Booking found</h2>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th @click="sortTable('jobtype')" class="jobtype">
            Jobtype
            <span v-if="sortKey === 'jobtype'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('duedate')" class="duedate">
            Due Date
            <span v-if="sortKey === 'duedate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th class="slottype" @click="sortTable('slottype')">Slot Type</th>
          <th @click="sortTable('scheduleddate')" class="scheduledate">
            Scheduled Date
            <span v-if="sortKey === 'scheduleddate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th class="slot">Time</th>
          <th @click="sortTable('daterangedate')" class="daterangedate">
            Date Range
            <span v-if="sortKey === 'daterangedate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('status')" class="status">
            Status
            <span v-if="sortKey === 'status'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('postcode')" class="postcode">
            PostCode
            <span v-if="sortKey === 'postcode'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('address')" class="address">
            Address
            <span v-if="sortKey === 'address'"
              >{{ sortOrder === "asc" ? "▲" : "▼" }}
            </span>
          </th>
          <th @click="sortTable('pi')" class="pi">
            PI
            <span v-if="sortKey === 'pi'"
              >{{ sortOrder === "asc" ? "▲" : "▼" }}
            </span>
          </th>
          <th @click="sortTable('customer')" class="customer">
            Customer
            <span v-if="sortKey === 'customer'"
              >{{ sortOrder === "asc" ? "▲" : "▼" }}
            </span>
          </th>
          <th @click="sortTable('branch')" class="branch">
            Branch
            <span v-if="sortKey === 'branch'"
              >{{ sortOrder === "asc" ? "▲" : "▼" }}
            </span>
          </th>
          <th class="action">Action</th>
        </tr>
      </thead>
      <tbody class="no-select">
        <tr v-for="(pvm, rowIndex) in sortedData" :key="pvm.id">
          <td>{{ pvm.jobtype.toLocaleUpperCase() }}</td>
          <td>
            <DueDateSelect
              :pvm="pvm"
              :changeddatepvmid="changeddatepvmid"
              @duedatepvmselected="changeddatepvmid = $event"
            />
          </td>
          <td
            contenteditable="false"
            @mousedown="startDrag(rowIndex, pvm.id, 'slottype')"
            @mouseup="endDrag(pvm.id, 'slottype')"
            @mousemove="handleDrag(rowIndex, 'slottype')"
            :class="{
              dragging:
                dragging &&
                draggingfield === 'slottype' &&
                rowIndex >= startRow &&
                rowIndex <= endRow,
            }"
          >
            <SlotTypeSelect
              :pvm="pvm"
              :changeslottypepvmid="changeslottypepvmid"
              @slottypepvmselected="statuspvmselected"
            />
          </td>

          <td
            contenteditable="false"
            @mousedown="startDrag(rowIndex, pvm.id, 'scheduleddate')"
            @mouseup="endDrag(pvm.id, 'scheduleddate')"
            @mousemove="handleDrag(rowIndex, 'scheduleddate')"
            :class="{
              dragging:
                dragging &&
                draggingfield === 'scheduleddate' &&
                rowIndex >= startRow &&
                rowIndex <= endRow,
            }"
          >
            <ScheduledDateSelect
              :pvm="pvm"
              :changesdatepvmid="changesdatepvmid"
              @schedulatedatepvmselected="changesdatepvmid = $event"
            />
          </td>
          <td>
            {{
              pvm.starttime === pvm.endtime
                ? ""
                : `${pvm.starttime} - ${pvm.endtime}`
            }}
          </td>
          <td
            contenteditable="false"
            @mousedown="startDrag(rowIndex, pvm.id, 'daterange')"
            @mouseup="endDrag(pvm.id, 'daterange')"
            @mousemove="handleDrag(rowIndex, 'daterange')"
          >
            <DateRange
              :pvm="pvm"
              :changesdaterangepvmid="changesdaterangepvmid"
              @daterangepvmselected="changesdaterangepvmid = $event"
            />
          </td>
          <td
            contenteditable="false"
            @mousedown="startDrag(rowIndex, pvm.id, 'status')"
            @mouseup="endDrag(pvm.id, 'status')"
            @mousemove="handleDrag(rowIndex, 'status')"
          >
            {{ pvm.status }}
          </td>
          <td>
            {{ pvm.address.postcode }}
          </td>
          <td v-html="actProperty.formatAddress(pvm.address, ', ')"></td>
          <td
            contenteditable="false"
            @mousedown="startDrag(rowIndex, pvm.id, 'inspector')"
            @mouseup="endDrag(pvm.id, 'inspector')"
            @mousemove="handleDrag(rowIndex, 'inspector')"
            :class="{
              dragging:
                dragging &&
                draggingfield === 'inspector' &&
                rowIndex >= startRow &&
                rowIndex <= endRow,
            }"
          >
            <InspectorSelect
              :pvm="pvm"
              :changeinspectorpvmid="changeinspectorpvmid"
              @inspectorpvmselected="changeinspectorpvmid = $event"
            />
          </td>
          <td>{{ pvm.customer.companyName }}</td>
          <td>{{ pvm.customer.branchName }}</td>
          <td class="text-right">
            <ul v-if="pvm.booking && pvm.booking.id" class="list-unstyled mb-0">
              <li class="list-item" @click.stop>
                <button
                  class="btn btn-outline-danger"
                  @click="removeBooking(pvm)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <PvBookingsPagination
      v-if="sortedData.length"
      :total="pvmstate.totalcount"
      :current="pvmstate.currentPage"
      :limit="pvmstate.pageLimit"
      @pageReports="doReportsPaging"
    />
  </div>
  <AlertDialog
    ref="unSaveChangeAlertDialog"
    name="scalertdialog"
    @alertDialogClosed="alertDialogClosed"
  />
  <!-- Bottom navbar -->
  <nav class="navbar fixed-bottom navbar-dark bg-primary mt-2">
    <div class="container-fluid">
      <div class="mr-auto"></div>
      <div class="ml-auto text-light">
        <span v-if="hasUnsavedChanges">You have unsaved changes</span>
        <span v-else class="text-muted">No unsaved changes</span>
        &nbsp;
        <button
          class="btn btn-outline-light"
          :class="{ disabled: !hasUnsavedChanges }"
          @click.prevent="cancel()"
          :disabled="!hasUnsavedChanges"
        >
          <i class="fas fa-ban"></i> Cancel
        </button>
        &nbsp;
        <button
          class="btn btn-light"
          :class="{ disabled: !hasUnsavedChanges }"
          @click.prevent="save()"
          :disabled="!hasUnsavedChanges"
        >
          <i class="fas fa-save"></i> Save
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Booking, Inspector } from "@/models";
import { computed, onMounted, inject, ref, watch } from "vue";
import { useStore } from "vuex";
import { DxDateRangeBox } from "devextreme-vue/date-range-box";
import InspectorSelect from "@/components/pvbookings/InspectorSelect.vue";
import ScheduledDateSelect from "@/components/pvbookings/ScheduledDateSelect.vue";
import SlotTypeSelect from "@/components/pvbookings/SlotTypeSelect.vue";
import DueDateSelect from "@/components/pvbookings/DueDateSelect.vue";

import DateRange from "@/components/pvbookings/DateRange.vue";
import PvBookingsPagination from "@/components/pvbookings/PvBookingsPagination.vue";
import { useRouter, useRoute } from "vue-router";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import { useToast } from "vue-toastification";
import moment from "moment";
const pvmstate = computed(() => store.state.pvbookings);
const store = useStore();
const postCodeOptions = ref([]);
const clientOptions = ref("");
const tempPage = ref(1);
const branchOptions = ref("");
const monthOptions = ref("");
const unSaveChangeAlertDialog = ref(null);
const router = useRouter();
const route = useRoute();
const statusOptions = ref([]);
const pvbookingsData = ref<Booking[]>([]);
const realtime: any = inject("realtime");
const toasted = useToast();

const channel = realtime.channels.get("diary");
const actProperty: any = inject("actProperty");
const deviceid = actProperty.getDeviceId();
const hasBlockingRequests = computed(
  () => store.getters["app/hasBlockingRequests"]
);
const hasUnsavedChanges = computed(
  () => store.getters["pvbookings/hasUnsavedChanges"]
);
const inspectorlist = computed(() => store.getters["pvbookings/inspectorlist"]);
const pvbookings = computed(() => store.getters["pvbookings/getBookingsData"]);
const sethasUnsavedChanges = (data: boolean) =>
  store.commit("pvbookings/sethasUnsavedChanges", data);

const updatePvBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/updatePvBookings", bookings);
};
const updateSinglePvBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/updateSinglePvBookings", bookings);
};

const updateCachedBookings = (): Promise<Booking[]> => {
  return store.dispatch("pvbookings/updateCachedBookings");
};
const cancelBooking = async (booking: Booking) => {
  await store.dispatch("pvbookings/cancelBooking", booking);
};

const setPvBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/setBookings", bookings);
};
const getBookings = (payload: { startdate: string; enddate: string }) => {
  return store.dispatch("pvbookings/getBookings", payload);
};
const getBookingsForPI = async (params): Promise<Booking[]> => {
  return await store.dispatch("pvbookings/getBookingsForPI", params);
};
const getInspector = async (): Promise<Booking[]> => {
  return await store.dispatch("pvbookings/getInspectors");
};
const setCurrentPage = (page: number): Promise<any> => {
  return store.dispatch("pvbookings/setCurrentPage", page);
};
const getBookingWithoutStoringInState = (payload: {
  id: string;
  cancelled: string;
}) => {
  return store.dispatch("diary/getBookingWithoutStoringInState", payload);
};
const resetCurrentPage = (page: number): Promise<any> => {
  return store.dispatch("pvbookings/resetCurrentPage", page);
};
const dateRange = ref<[Date | null, Date | null]>([null, null]);
const changeinspectorpvmid = ref("");
const changesdatepvmid = ref("");
const changeddatepvmid = ref("");
const changesdaterangepvmid = ref("");
const changeslottypepvmid = ref("");
const computedDateRange = computed<[Date | null, Date | null]>({
  get() {
    return dateRange.value;
  },
  set(value: [Date | null, Date | null]) {
    dateRange.value = value;
    if (dateRange.value[0] && dateRange.value[1]) {
      getBookingsData();
    }
  },
});
const sortKey = ref(""); // Holds the key of the column being sorted
const sortOrder = ref<"asc" | "desc">("asc"); // Sorting order
const statusList: string[] = ["Select All", "Scheduled and in queue"];
const weekList: string[] = [
  "1 Week",
  "2 Weeks",
  "3 Weeks",
  "4 Weeks",
  "5 Weeks",
  "6 Weeks",
];

// Sorting function
const sortTable = (key: string) => {
  if (sortKey.value === key) {
    // If the same column is clicked, toggle the sort order
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    // If a new column is clicked, set to ascending order
    sortKey.value = key;
    sortOrder.value = "asc";
  }
};

// Computed property for sorted data
const sortedData = computed(() => {
  const sorted = [...pvbookingsData.value];
  // Define sorting logic based on the selected key
  if (sortKey.value) {
    sorted.sort((a, b) => {
      let compareA = a[sortKey.value] ?? "";
      let compareB = b[sortKey.value] ?? "";

      // Handle nested fields like postcode, customer, and address
      if (sortKey.value === "postcode") {
        compareA = a.address.postcode;
        compareB = b.address.postcode;
      }

      // Handle date sorting
      if (sortKey.value === "duedate") {
        compareA = new Date(a.duedate);
        compareB = new Date(b.duedate);
      }

      // Handle date sorting
      if (sortKey.value === "scheduleddate") {
        compareA = new Date(a.scheduleddate);
        compareB = new Date(b.scheduleddate);
      }

      // Handle date sorting
      if (sortKey.value === "daterangedate") {
        compareA = new Date(a.daterangestartdate);
        compareB = new Date(b.daterangeenddate);
      }

      // Handle date sorting
      if (sortKey.value === "status") {
        compareA = a.status;
        compareB = b.status;
      }

      // Handle date sorting
      if (sortKey.value === "pi") {
        compareA = a.inspector.name;
        compareB = b.inspector.name;
      }

      // Handle date sorting
      if (sortKey.value === "customer") {
        compareA = a.customer.companyName;
        compareB = b.customer.companyName;
      }

      // Handle date sorting
      if (sortKey.value === "branch") {
        compareA = a.customer.branchName;
        compareB = b.customer.branchName;
      }

      // Handle date sorting
      if (sortKey.value === "slottype") {
        compareA = a.slottype;
        compareB = b.slottype;
      }

      // For string comparison
      if (typeof compareA === "string") {
        compareA = compareA.toLowerCase();
        compareB = compareB.toLowerCase();
      }

      if (compareA < compareB) return sortOrder.value === "asc" ? -1 : 1;
      if (compareA > compareB) return sortOrder.value === "asc" ? 1 : -1;
      return 0;
    });
  }

  // Handle date sorting
  if (sortKey.value === "address") {
    sorted.sort((a: Booking, b: Booking) => {
      const direction = sortOrder.value === "asc" ? 1 : -1; // Determine sort direction

      // Compare `line1`
      let result = a.address.line1.localeCompare(b.address.line1) * direction;
      if (result !== 0) return result;

      // Compare `line2` (optional field)
      result =
        (a.address.line2 || "").localeCompare(b.address.line2 || "") *
        direction;
      if (result !== 0) return result;

      // Compare `town`
      result = a.address.town.localeCompare(b.address.town) * direction;
      if (result !== 0) return result;

      // Compare `county` (optional field)
      result =
        (a.address.county || "").localeCompare(b.address.county || "") *
        direction;
      if (result !== 0) return result;

      // Compare `postcode`
      return a.address.postcode.localeCompare(b.address.postcode) * direction;
    });
  }

  let filtered = [...sorted];

  // Filter pvbookingsData based on postcodes
  // Filter by postcode if postCodeData is not empty
  if (postCodeData.value.length) {
    filtered = filtered.filter((item: Booking) =>
      postCodeData.value.includes(item.address.postcode)
    );
  }

  // Filter by status if statusData is not empty
  if (statusData.value.length) {
    // filtered = filtered.filter((item: Booking) =>
    //   statusData.value.includes(item.id) // Assuming `item.status` is the status field in `Booking`
    // );
  }

  // Filter by client
  if (clientData.value.length) {
    filtered = filtered.filter((item: Booking) =>
      clientData.value.includes(item.customer.companyName)
    );
  }
  // Filter by client
  if (branchData.value.length) {
    filtered = filtered.filter((item: Booking) =>
      branchData.value.includes(item.customer.branchName)
    );
  }

  return filtered;
});
const postCodeData = computed({
  get() {
    return postCodeOptions.value;
  },
  set(val: string[]) {
    if (
      val.includes("Select All") &&
      !postCodeOptions.value.includes("Select All")
    ) {
      postCodeOptions.value = uniquePostcodes.value;
    } else if (
      !val.includes("Select All") &&
      postCodeOptions.value.includes("Select All")
    ) {
      postCodeOptions.value = [];
    } else {
      postCodeOptions.value = val.filter((f: any) => f !== "Select All");
    }
  },
});

const cancel = () => {
  router.go(0);
};
const clientData = computed({
  get() {
    return clientOptions.value;
  },
  set(val: string) {
    clientOptions.value = val;
  },
});
const branchData = computed({
  get() {
    return branchOptions.value;
  },
  set(val: string) {
    branchOptions.value = val;
  },
});
const statusData = computed({
  get() {
    return statusOptions.value;
  },
  set(val: string[]) {
    if (
      val.includes("Select All") &&
      !statusOptions.value.includes("Select All")
    ) {
      statusOptions.value = statusList;
    } else if (
      !val.includes("Select All") &&
      statusOptions.value.includes("Select All")
    ) {
      statusOptions.value = [];
    } else {
      statusOptions.value = val.filter((f: any) => f !== "Select All");
    }
  },
});
const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};
watch(
  () => route.query.page,
  async (newVal: any, oldVal: any) => {
    if (newVal) {
      const page = Array.isArray(newVal)
        ? parseInt(newVal[0], 10)
        : parseInt(newVal, 10);

      if (!isNaN(page)) {
        setCurrentPage(page); // Now it accepts a number
        await getBookingsData();
      } else {
        console.error("Invalid page parameter:", newVal);
      }
    }
  }
);
const getBookingsData = async () => {
  if (dateRange.value[0] && dateRange.value[1]) {
    // Format the start and end dates and make the API call
    await getBookings({
      startdate: formatDate(dateRange.value[0]), // Format the start date
      enddate: formatDate(dateRange.value[1]), // Format the end date
    });
  } else {
    await getBookings({
      startdate: "", // Format the start date
      enddate: "", // Format the end date
    });
  }
  pvbookingsData.value = pvbookings.value;
};
const uniquePostcodes = computed(() => {
  let postcodes = pvbookingsData.value.map((f: Booking) => f.address.postcode);

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(postcodes));

  // Add 'Select All' at the 0th index
  return ["Select All", ...uniquePostcodesArray];
});
const uniqueClients = computed(() => {
  let clients = pvbookingsData.value
    .filter((n: Booking) => n.customer?.companyName) // Ensure customer and companyName exist
    .map((f: Booking) => f.customer.companyName);

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(clients));

  // Add 'Select All' at the 0th index
  return [...uniquePostcodesArray];
});
const uniqueBranch = computed(() => {
  if (!clientData.value) return [];
  let clients = pvbookingsData.value
    .filter((f: Booking) => f.customer.companyName == clientData.value)
    .map((f: Booking) => f.customer.branchName);

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(clients));

  // Add 'Select All' at the 0th index
  return [...uniquePostcodesArray];
});

// Converted methods
const init = async () => {
  const page = Array.isArray(route.query.page)
    ? parseInt(route.query.page[0], 10)
    : parseInt(route.query.page, 10);

  if (!isNaN(page) && page !== 1) {
    setCurrentPage(page); // Now it accepts a number
  }
  await getBookingsData();
};
const showAll = async () => {
  dateRange.value = [null, null]; // Reset date range
  resetCurrentPage(1);
  setCurrentPage(1);
  await getBookingsData();
};
const minDate = new Date("2020-01-01"); // Very old date (acts as "infinity" in the past)
const maxDate = new Date("2050-12-31"); // Very distant future date (acts as "infinity" in the future)
// Lifecycle hook
const subjobendDate = (time, date) => {
  let value = "";
  if (time) {
    let dt = date;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};
onMounted(() => {
  if (pvmstate.value.currentPage !== 1) {
    doReportsPaging(pvmstate.value.currentPage);
  }
  channel.subscribe("appointmentCancelled", async (message: any) => {
    if (message.data) {
      let index = pvbookingsData.value.findIndex(
        (f: Booking) => f?.booking?.id === message.data
      );
      if (index == -1) return;
      const startTime = "T09:00:00.000Z";
      pvbookingsData.value[index].booking = new Booking();
      pvbookingsData.value[index].inspector = new Inspector();
      pvbookingsData.value[index].scheduleddate = "";
      pvbookingsData.value[index].slottype = "";
      pvbookingsData.value[index].status = "";
      pvbookingsData.value[index].startdate = subjobendDate(startTime, pvbookingsData.value[index].duedate);
      pvbookingsData.value[index].enddate = subjobendDate(startTime, pvbookingsData.value[index].duedate);
      delete pvbookingsData.value[index].qc;
      await updateSinglePvBookings([pvbookingsData.value[index]]);
    }
  });
  channel.subscribe("appointmentChanged", async (message: any) => {
    if (message?.data && message.data.bookingid) {
      let index = pvbookingsData.value.findIndex(
        (f: Booking) => f?.booking?.id === message.data?.bookingid
      );
      if (index == -1) return;
      getBookingWithoutStoringInState({
        id: message.data.bookingid,
        cancelled: "false",
      }).then(async (b: Booking) => {
        if (b.id) {
          pvbookingsData.value[index].booking = b;
          pvbookingsData.value[index].inspector = b.inspector;
          pvbookingsData.value[index].customer = b.customer;
          pvbookingsData.value[index].address = b.address;
          pvbookingsData.value[index].scheduleddate = moment(b.startDate).format("YYYY-MM-DD[T]HH:mm");
          pvbookingsData.value[index].startdate = b.startDate;
          pvbookingsData.value[index].enddate = b.endDate;
          delete pvbookingsData.value[index].qc;
          await updateSinglePvBookings([pvbookingsData.value[index]]);
        }
      });
    }
  });

  getInspector();
  init();
});

// Reactive state for drag-to-fill functionality
const dragging = ref(false);
const draggingfield = ref("");
const startRow = ref<number | null>(null);
const startValue = ref<string | null>(null);
const endValue = ref<string | null>(null);
const endRow = ref<number | null>(null);

// Function to get the inspector ID based on the name and set it in the appropriate cell
const getUpdateFieldById = async (
  rowIndex: number,
  name: string,
  id: string,
  fieldname: string
) => {
  if (!name) return;
  const pmIndex = pvbookingsData.value.findIndex((r: Booking) => r.id === id);
  if (fieldname === "inspector") {
    const inspector = inspectorlist.value.find(
      (f: Inspector) => f.name === name
    );
    if (inspector?.id) {
      sortedData.value[rowIndex].inspector = inspector;
      pvbookingsData.value[pmIndex].inspector = inspector;
      if (
        pvbookingsData.value[pmIndex].booking &&
        pvbookingsData.value[pmIndex].booking?.id
      ) {
        pvbookingsData.value[pmIndex].booking.inspector = inspector;
      }
      if (
        pvbookingsData.value[pmIndex].scheduleddate &&
        pvbookingsData.value[pmIndex].inspector.id
      ) {
        pvbookingsData.value[pmIndex].status = "Scheduled and in queue";
        sortedData.value[rowIndex].status = "Scheduled and in queue";
      }
    }
  } else if (fieldname === "status") {
    sortedData.value[rowIndex].status = name;
    pvbookingsData.value[pmIndex].status = name;
  } else if (fieldname === "scheduleddate" && name !== null) {
    sortedData.value[rowIndex].scheduleddate = name;
    pvbookingsData.value[pmIndex].scheduleddate = name;
    if (
      pvbookingsData.value[pmIndex].scheduleddate &&
      pvbookingsData.value[pmIndex].inspector.id
    ) {
      pvbookingsData.value[pmIndex].status = "Scheduled and in queue";
      sortedData.value[rowIndex].status = "Scheduled and in queue";
    }
  } else if (fieldname === "daterange" && name !== null) {
    sortedData.value[rowIndex].daterangestartdate = name;
    pvbookingsData.value[pmIndex].daterangestartdate = name;
    sortedData.value[rowIndex].daterangeenddate = endValue.value;
    pvbookingsData.value[pmIndex].daterangeenddate = endValue.value;
  } else if (fieldname === "slottype") {
    sortedData.value[rowIndex].slottype = name;
    pvbookingsData.value[pmIndex].slottype = name;
  }
  sethasUnsavedChanges(true);
};

// Function to initiate drag-to-fill (stores initial row and value)
const startDrag = (rowIndex: number, id: string, fieldname: string) => {
  dragging.value = true;
  draggingfield.value = fieldname;
  startRow.value = rowIndex;
  if (fieldname === "inspector") {
    startValue.value = sortedData.value[rowIndex].inspector.name; // Capture the initial value
  } else if (fieldname === "status") {
    startValue.value = sortedData.value[rowIndex].status; // Capture the initial value
  } else if (fieldname === "scheduleddate") {
    startValue.value = sortedData.value[rowIndex].scheduleddate; // Capture the initial value
  } else if (fieldname === "daterange") {
    startValue.value = sortedData.value[rowIndex].daterangestartdate; // Capture the initial value
    endValue.value = sortedData.value[rowIndex].daterangeenddate; // Capture the initial value
  } else if (fieldname === "slottype") {
    startValue.value = sortedData.value[rowIndex].slottype; // Capture the initial value
  }
  getUpdateFieldById(rowIndex, startValue.value, id, fieldname);
  endRow.value = rowIndex; // Initialize endRow as startRow to reset drag boundaries
  setPvBookings(pvbookingsData.value);
};

// Function to finalize the drag-to-fill action, filling cells downward only
const endDrag = async (id: string, fieldname: string) => {
  if (
    dragging.value &&
    startRow.value !== null &&
    startValue.value !== null &&
    endRow.value !== null &&
    endRow.value > startRow.value // Ensure we’re only dragging downward
  ) {
    // Fill cells only downward from startRow to endRow
    const start = startRow.value;
    const end = endRow.value;

    for (let i = start; i <= end; i++) {
      getUpdateFieldById(i, startValue.value, id, fieldname);
    }
  }

  // Reset start and end rows after drag
  dragging.value = false;
  startRow.value = null;
  endRow.value = null;
  draggingfield.value = "";
  setPvBookings(pvbookingsData.value);
  let changeBookings = pvbookingsData.value.filter((b: Booking) => b.changed);
  for (let b of changeBookings) {
    if (b.scheduleddate && b.inspector.id) {
      const cachedate = moment(b.scheduleddate).utc().format("DD-MM-YYYY");
      await getBookingsForPI({
        date: cachedate,
        inspectorid: b.inspector.id,
        fieldlist: "id,startdate,enddate,jobtype,all_day,address",
        period: "days",
      });
      let inspectorIndex = inspectorlist.value.findIndex(
        (f: Inspector) => f.id === b.inspector.id
      );
      const stime = actProperty.determineViableSlot(
        b,
        inspectorlist.value[inspectorIndex]
      );
      let scheduledateAsDate = moment(b.scheduleddate).utc().toDate();
      b.startdate = actProperty.prependDate(
        stime,
        scheduledateAsDate,
        scheduledateAsDate
      );
      b.enddate = moment(b.startdate)
        .utc()
        .add(30, "minutes")
        .format(actProperty.bookingdateutcformat);
      if (b.startdate && b.enddate) {
        let bookings =
          inspectorlist.value[inspectorIndex].bookingsmap.get(cachedate);
        let bIndex = bookings?.findIndex((f: Booking) => f.id === b.id);
        if (bIndex === -1) {
          bookings.push(b); // Add a copy of the new booking
        } else {
          bookings[bIndex] = b; // Update with a copy of the booking
        }
        inspectorlist.value[inspectorIndex].bookingsmap.set(
          cachedate,
          bookings
        );
        const updateIndex = pvbookingsData.value.findIndex(
          (r: Booking) => r.id === b.id
        );
        if (updateIndex != -1) {
          pvbookingsData.value[updateIndex] = b;
          if (
            pvbookingsData.value[updateIndex].booking &&
            pvbookingsData.value[updateIndex].booking?.id
          ) {
            pvbookingsData.value[updateIndex].booking = b;
          }
        }
      }
      else {
        const startTime = "T09:00:00.000Z";
        const pmIndex = pvbookingsData.value.findIndex((r: Booking) => r.id === b.id);
        let date = pvbookings.value[pmIndex].scheduleddate ? pvbookings.value[pmIndex].scheduleddate : pvbookings.value[pmIndex].duedate;
        pvbookings.value[pmIndex].startdate = subjobendDate(startTime, date) ;
        pvbookings.value[pmIndex].enddate = subjobendDate(startTime, date);
        if(pvbookings.value[pmIndex].booking && pvbookings.value[pmIndex].booking?.id) {
          pvbookings.value[pmIndex].booking.startdate = subjobendDate(startTime, date) ;
          pvbookings.value[pmIndex].booking.enddate = subjobendDate(startTime, date);
        }
      }
    }
  }
  updateCachedBookings();
};

const removeBooking = async (booking: Booking) => {
  actProperty
    .confirmPrompt()
    .then(async () => {
      await cancelBooking(booking);
      channel.publish("appointmentCancelled", booking.id);
    })
    .then(() => toasted.success("Booking deleted"))
    .catch((err: any) => actProperty.displayError(err));
};

// Function to track the drag row index (determines the end row)
// Only allow downward dragging (endRow >= startRow)
const handleDrag = (rowIndex: number, fieldname: string) => {
  if (dragging.value && rowIndex > startRow.value) {
    endRow.value = rowIndex;
  }
};
const doReportsPaging = (page: number): void => {
  tempPage.value = page;
  if (hasUnsavedChanges.value) {
    showUnsavedChangesAlert();
    return;
  }
  setCurrentPage(page);
  const queryParams = { ...route.query };
  queryParams.page = page + ""; // hack to coerce to string
  router.push({ path: "pvbookings", query: queryParams });
};

const save = async () => {
  try {
    const data = pvbookingsData.value.map((booking: Booking) => {
      delete booking.qc;
      return booking;
    });
    let updateBookings = await updatePvBookings(data);
    pvbookingsData.value = updateBookings;
    let bookinglist = updateBookings
      .filter((f: Booking) => f.booking && f.booking.id) // Filter out items without booking or booking.id
      .map((m: Booking) => ({
        id: m.booking.id, // Extract booking.id
        date: moment(m.scheduleddate).format("DD-MM-YYYY"), // Format the scheduleddate
      }));
    channel.publish("PvAppointmentsAdded", {
      deviceid: deviceid,
      bookinglist: bookinglist,
    });
    sethasUnsavedChanges(false);
    toasted.success("Successfully Records Updated");
  } catch (err: any) {
    actProperty.displayError(err);
  }
};
const showUnsavedChangesAlert = () => {
  let message =
    "There are unsaved changes. Please first save all the changes to load next pages";
  const modal = unSaveChangeAlertDialog.value as any;
  if (modal) {
    modal.init("Warning", message, "Ok", "Cancel");
    modal.show();
  }
};
const alertDialogClosed = (id: string, buttontext: string) => {
  if (buttontext === "Ok") {
    const queryParams = { ...route.query };
    setCurrentPage(tempPage.value);
    queryParams.page = tempPage.value + ""; // hack to coerce to string
    router.push({ path: "pvbookings", query: queryParams });
  }
};
const statuspvmselected = (data: string) => {
  changeslottypepvmid.value = data;
};
const weekData = computed({
  get() {
    return monthOptions.value;
  },
  async set(val: string) {
    monthOptions.value = val;
    let week = parseInt(val.split(" ")[0]);
    let todaymoment = moment(dateRange.value[1]).utc();
    let enddate = todaymoment.add(week * 7, "days").format("DD-MM-YYYY");
    await getBookings({
      startdate: formatDate(dateRange.value[0]), // Format the start date
      enddate: enddate, // Format the end date
    });
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  &.jobtype {
    width: 5rem;
  }
  &.slottype {
    width: 6rem;
  }
  &.slot {
    width: 6rem;
  }
  &.duedate {
    width: 6rem;
  }
  &.scheduledate {
    width: 8rem;
  }
  &.schedate {
    width: 8rem;
  }
  &.status {
    width: 10rem;
  }
  &.postcode {
    width: 8rem;
  }
  &.address {
    width: 12rem;
  }
  &.pi {
    width: 8rem;
  }
  &.customer {
    width: 7rem;
  }
  &.branch {
    width: 7rem;
  }
  &.daterangedate {
    width: 8rem;
  }
  &.action {
    width: 4rem;
  }
}

.dragging {
  background-color: #a9d3f855;
}

.no-select {
  user-select: none;
}
</style>
