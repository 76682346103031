import { Module } from "vuex";
import { RootState } from "../types";
import { PvBookingsState } from "./types";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

// Default state
export const state: PvBookingsState = {
  currentTenancyId: "",
  tenancies: [],
  list: [],
  originallist: [],
  currentPage: 1,
  pageLimit: 20,
  totalcount: 0,
  hasUnsavedChanges: false,
  customerlist: [],
  inspectorlist: [],
};

// Use namespaced Vuex modules
const namespaced: boolean = true;

// Module export
const tenancies: Module<PvBookingsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default tenancies;
