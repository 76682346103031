import _castArray from "lodash/castArray";
import _get from "lodash/get";
import moment from "moment-timezone";
import { rrulestr, RRuleSet } from "rrule";
import { Auditlog, QCRule, TravelTime } from "@/models";
import {
  datetimeToUTC,
  toCamelCase,
  omitDeep,
  schedulerdateutcformat,
  schedulerenddateutcformat,
} from "@/utilities";

import {
  Base,
  Address,
  Customer,
  Inspector,
  PropertySpec,
  Report,
  Document,
  EmailLogs,
  SMSLogs,
  Tenant,
  Landlord,
  Bookedby,
  Fee,
  Attachments,
  EmailAttachment,
  PresetError,
  Qc,
} from "@/models";
import app from "@/store/app";
import { split } from "lodash";
import { globalEventBus } from "vue-toastification";

const timezone = process.env.VUE_APP_TIMEZONE || "Europe/London";
const bookingdateformat = "YYYY-MM-DD[T]HH:mm:ss.SSSZ";
export class Booking extends Base {
  // Subtype job names
  static PREP: string = "Prep";
  static SHARED: string = "Shared";
  static KEY: string = "Key";
  static REVISIT: string = "Revisit";
  static GOOGLE: string = "Google";
  static PERSONAL: string = "Personal";
  static CIOFFACTREPORT: string = "Check-In - off ACT report";
  static B2BCI: string = "Check-In - back to back";

  public id: string;
  public allDay: boolean;
  public repeat: string;
  public recurrenceRule: string;
  public recurrenceExclDates: string;
  public description: string;
  public summary: string;
  public location: string;
  public provisional: boolean;
  public issued: boolean;
  public callbackrequested: boolean;

  public googleid: string;
  public googlecalendaremail: string;

  public attachments: Attachments;

  // For prep and split bookings
  public connectedbooking: Booking;
  //public prep: boolean;
  //public prepbase: boolean;
  //public share: boolean;

  public subbookings: Booking[];
  public leadbooking: Booking | undefined;
  public subtype: string;
  public previousbooking: Booking;

  // What & When
  public jobtype: string;
  public internaljobtype: string;
  public startdate: string;
  public enddate: string;
  public status: string;

  public duedate: string;
  public daterangestartdate: string;
  public daterangeenddate: string;
  public scheduleddate: string;
  public booking: Booking;
  public slottype: string;

  public appointmenttime: string;
  public address: Address;
  public propertyspec: PropertySpec;
  public previousreport: Report;
  public dataentryreport: Report | undefined;
  public dataentrydocuments: Document[];

  public preferredduration: number;
  public preferredappointmenttime: number;
  public cleaningnotes: string;
  public provisionalnotes: string;

  // Scheduling
  public inspector: Inspector;
  public recommendedtime: number | undefined;
  public recommendedappointmenttime: number;
  public appointmentchanged: boolean;
  //public sharingallowed: boolean;
  //public prepallowed: boolean;

  // Who For & How Much
  public companyName: string;
  public customer: Customer;
  public invoicecustomer: Customer;
  public managedtype: string;
  public bookedby: Bookedby[];
  public landlords: Landlord[];
  public worksorder: string;
  public worksorderdocuments: Document[];
  public worksordernotes: string;
  public paymentinadv: boolean;
  public invoices: Report[];
  public paid: boolean;
  public paymentdate: string;
  public development: Customer;

  // Access
  public keypickup: string;
  public keypickupcompanyname: string;
  public keypickupbranchname: string;
  public keypickupnotes: string;
  public confirmaccess: boolean;
  public accessissues: string;
  public releasekeysto: string;
  public releasekeystocompanyname: string;
  public releasekeystobranchname: string;
  public releasekeystonotes: string;
  public keystobereleased: string;
  public tenancyid: string;
  public pmname: string;
  public pmemail: string;
  public tenants: Tenant[];
  public agencyaddress: Address;
  public keypickupfromaddress: Address;
  public keyreleasetoaddress: Address;
  public tenantattending: string;
  public donotcontacttt: boolean;
  public accessattachments: Document[];
  public accessnotes: string;

  public emaillogs: EmailLogs;
  public smslogs: SMSLogs;
  public emailattachments: EmailAttachment[];

  // Other
  public internalnotes: string;
  public clientnotes: string;
  public longtermnotes: string;
  public logisticsnotes: string;
  public qcnotes: string;
  public pinotes: string;
  public titlenotes: string;
  public internalnotesdocuments: Document[];
  public pinotesdocuments: Document[];

  public cancelled: boolean;
  public fixedtimenotes: string;
  public cancelnotes: string;
  public _updated: boolean;
  public _inspectorid: string;

  public auditlogs: Auditlog[];
  public auditfieldlist: string[];
  public auditvaluelist: string[];

  public locked: boolean;
  public lockeduntil: string;
  public lockedby: string;
  public lockedbydevice: string;
  public inprogress: boolean;

  public createdAt: string;
  public updatedAt: string;

  public fromDurationText: string = "";
  public fromDurationSecs: number = -1;
  public topFrom = false;
  public toDurationText: string = "";
  public toDurationSecs: number = -1;
  public topTo = false;
  public traveltime: TravelTime = new TravelTime();

  // Issue Report
  public report: Report;
  public subreports: Report[];

  // Management filters
  public preseterrors: PresetError[] = [];
  public creditapproved: boolean;

  public zinspectorid: string = "";
  public qc: Qc;

  // booking change detection fields
  public changed: boolean = false;

  public constructor(data?: Partial<Booking>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, "id", "");
    this.allDay = _get(data, "allDay", false);
    this.repeat = _get(data, "repeat", "Doesn't repeat");
    const recurrence_rule = _get(data, "recurrenceRule", "");
    if (recurrence_rule) {
      this.recurrenceRule = recurrence_rule;
      const rruleOnly = recurrence_rule
        .split("\n")
        .find((line) => line.startsWith("RRULE"));
      if (rruleOnly) this.recurrenceRule = rruleOnly.replaceAll("RRULE:", "");
    }
    this.recurrenceExclDates = _get(data, "recurrenceExclDates", "");

    this.description = _get(data, "description", "");
    this.summary = _get(data, "summary", "");
    this.location = _get(data, "location", "");
    this.provisional = _get(data, "provisional", false);
    this.issued = _get(data, "issued", false);
    this.callbackrequested = _get(data, "callbackrequested", false);

    this.googleid = _get(data, "googleid", "");
    this.googlecalendaremail = _get(data, "googlecalendaremail", "");
    if (data?.booking && data?.booking?.id)
      this.booking = new Booking(_get(data, "booking"));
    if (data?.connectedbooking && data?.connectedbooking?.id)
      this.connectedbooking = new Booking(_get(data, "connectedbooking"));

    //this.prep = _get(data, 'prep', false);
    //this.prepbase = _get(data, 'prepbase', false);
    //this.share = _get(data, 'share', false);
    this.subbookings = _castArray(_get(data, "subbookings", [])).map(
      (x: Partial<Booking>) => new Booking(x)
    );
    if (data?.leadbooking && data?.leadbooking?.id)
      this.leadbooking = new Booking(_get(data, "leadbooking"));

    this.subtype = _get(data, "subtype", "");

    this.attachments = new Attachments(_get(data, "attachments"));

    // What & When
    this.jobtype = _get(data, "jobtype", "");
    this.internaljobtype = _get(data, "internaljobtype", "");
    this.startdate = _get(data, "startdate", "");
    this.enddate = _get(data, "enddate", "");

    this.duedate = _get(data, "duedate", "");
    this.daterangestartdate = _get(data, "daterangestartdate", "");
    this.daterangeenddate = _get(data, "daterangeenddate", "");
    this.scheduleddate = _get(data, "scheduleddate", "");
    this.status = _get(data, "status", "");
    this.slottype = _get(data, "slottype", "");

    this.appointmenttime = _get(data, "appointmenttime", "");
    this.address = new Address(_get(data, "address"));
    this.propertyspec = new PropertySpec(_get(data, "propertyspec"));
    if (data?.previousbooking && data?.previousbooking?.id)
      this.previousbooking = new Booking(_get(data, "previousbooking"));
    if (data?.previousreport && data?.previousreport?.id) {
      this.previousreport = new Report(_get(data, "previousreport"));
    }
    if (data?.dataentryreport && data?.dataentryreport?.id) {
      this.dataentryreport = new Report(_get(data, "dataentryreport"));
    }
    this.dataentrydocuments = _castArray(
      _get(data, "dataentrydocuments", [])
    ).map((x: Partial<Document>) => new Document(x));

    this.preferredduration = _get(data, "preferredduration", 0);
    this.preferredappointmenttime = _get(data, "preferredappointmenttime", 0);
    this.appointmentchanged = _get(data, "appointmentchanged", false);
    this.cleaningnotes = _get(data, "cleaningnotes", "");
    this.provisionalnotes = _get(data, "provisionalnotes", "");

    // Scheduling
    this.inspector = new Inspector(_get(data, "inspector"));
    this._inspectorid = this.inspector.id;
    this.recommendedtime = _get(data, "recommendedtime", undefined);
    this.recommendedappointmenttime = _get(
      data,
      "recommendedappointmenttime",
      0
    );
    //this.sharingallowed = _get(data, 'sharingallowed', true);
    //this.prepallowed = _get(data, 'prepallowed', true);

    // Who For & How Much
    this.companyName = _get(data, "companyName", "");
    this.customer = new Customer(_get(data, "customer"));
    this.invoicecustomer = new Customer(_get(data, "invoicecustomer"));
    this.managedtype = _get(data, "managedtype", "");
    this.bookedby = _castArray(_get(data, "bookedby", [new Bookedby()])).map(
      (x: Partial<Bookedby>) => new Bookedby(x)
    );
    this.landlords = _castArray(_get(data, "landlords", [new Landlord()])).map(
      (x: Partial<Landlord>) => new Landlord(x)
    );
    this.worksorder = _get(data, "worksorder", "");
    this.worksorderdocuments = _castArray(
      _get(data, "worksorderdocuments", [])
    ).map((x: Partial<Document>) => new Document(x));
    this.worksordernotes = _get(data, "worksordernotes", "");
    this.paymentinadv = _get(data, "paymentinadv", false);
    this.invoices = _castArray(
      _get(data, "invoices", [new Report({ fees: [new Fee()] })])
    ).map((x: Partial<Report>) => new Report(x));
    this.paid = _get(data, "paid", false);
    this.creditapproved = _get(data, "creditapproved", false);
    this.paymentdate = _get(data, "paymentdate", datetimeToUTC());
    this.development = new Customer(_get(data, "development"));

    // Access
    this.keypickup = _get(data, "keypickup", "");
    this.keypickupcompanyname = _get(data, "keypickupcompanyname", "");
    this.keypickupbranchname = _get(data, "keypickupbranchname", "");
    this.keypickupnotes = _get(data, "keypickupnotes", "");
    this.confirmaccess = _get(data, "confirmaccess", false);
    this.accessissues = _get(data, "accessissues", "");
    this.releasekeysto = _get(data, "releasekeysto", "");
    this.releasekeystocompanyname = _get(data, "releasekeystocompanyname", "");
    this.releasekeystobranchname = _get(data, "releasekeystobranchname", "");
    this.releasekeystonotes = _get(data, "releasekeystonotes", "");
    this.keystobereleased = _get(data, "keystobereleased", "");
    this.tenants = _castArray(_get(data, "tenants", [new Tenant()])).map(
      (x: Partial<Tenant>) => new Tenant(x)
    );
    this.agencyaddress = new Address(_get(data, "agencyaddress"));
    this.keypickupfromaddress = new Address(_get(data, "keypickupfromaddress"));
    this.keyreleasetoaddress = new Address(_get(data, "keyreleasetoaddress"));
    this.tenantattending = _get(data, "tenantattending", "unsure");
    this.donotcontacttt = _get(data, "donotcontacttt", false);
    this.accessattachments = _castArray(
      _get(data, "accessattachments", [])
    ).map((x: Partial<Document>) => new Document(x));
    this.accessnotes = _get(data, "accessnotes", "");

    // Confirmations
    this.emaillogs = new EmailLogs(_get(data, "emaillogs"));
    this.smslogs = new SMSLogs(_get(data, "smslogs"));
    this.emailattachments = _castArray(_get(data, "emailattachments", [])).map(
      (x: any) => {
        const emailatt = new EmailAttachment();
        if (x?.file) {
          const lastindex = x.file.lastIndexOf("/");
          emailatt.filename =
            lastindex >= 0 ? x.file.substring(lastindex + 1) : "";
        }
        emailatt.path = x.file;
        return emailatt;
      }
    );

    // Other
    this.internalnotes = _get(data, "internalnotes", "");
    this.clientnotes = _get(data, "clientnotes", "");
    this.longtermnotes = _get(data, "longtermnotes", "");
    this.logisticsnotes = _get(data, "logisticsnotes", "");
    this.qcnotes = _get(data, "qcnotes", "");
    this.pinotes = _get(data, "pinotes", "");
    this.titlenotes = _get(data, "titlenotes", "");
    this.internalnotesdocuments = _castArray(
      _get(data, "internalnotesdocuments", [])
    ).map((x: Partial<Document>) => new Document(x));

    this.pinotesdocuments = _castArray(_get(data, "pinotesdocuments", [])).map(
      (x: Partial<Document>) => new Document(x)
    );

    //IssueingReport
    this.report = new Report(_get(data, "report"));
    this.subreports = _castArray(_get(data, "subreports", [])).map(
      (x: Partial<Report>) => new Report(x)
    );

    this.cancelled = _get(data, "cancelled", false);
    this.cancelnotes = _get(data, "cancelnotes", "");
    this.fixedtimenotes = _get(data, "fixedtimenotes", "");

    // If this booking is coming from scheduler as a personal booking
    // Then try to partse date from there
    if (!this.startdate && data?.startDate) {
      this.startdate =
        moment(data?.startDate).utc(true).format("YYYY-MM-DD[T]HH:mm") +
        ":00.000Z";
    }
    if (!this.enddate && data?.endDate) {
      this.enddate =
        moment(data?.endDate).utc(true).format("YYYY-MM-DD[T]HH:mm") +
        ":00.000Z";
    }
    if ((!this.inspector || !this.inspector.id) && data?.employeeId) {
      if (!this.inspector) this.inspector = new Inspector();
      this.inspector.id = data?.employeeId;
    }

    this.locked = _get(data, "locked", false);
    this.lockeduntil = _get(data, "lockeduntil", "");
    this.lockedby = _get(data, "lockedby", "");
    this.lockedbydevice = _get(data, "lockedbydevice", "");

    this.inprogress = _get(data, "inprogress", false);

    this.preseterrors = _castArray(_get(data, "preseterrors", [])).map(
      (x: Partial<PresetError>) => new PresetError(x)
    );

    this.zinspectorid = _get(data, "zinspectorid", "");
    this.auditlogs = _castArray(_get(data, "auditlogs", [])).map(
      (x: Partial<Auditlog>) => new Auditlog(x)
    );
    this.auditfieldlist = _castArray(_get(data, "auditfieldlist", []));
    this.auditvaluelist = _castArray(_get(data, "auditvaluelist", []));
    if (this.auditfieldlist.length === 0) this.preserveoriginalvalues();

    this.traveltime = new TravelTime(_get(data, "traveltime"));
    this.qc = new Qc(_get(data, "qc"));
    this.tenancyid = _get(data, "tenancyid", "");
    this.pmname = _get(data, "pmname", "");
    this.pmemail = _get(data, "pmemail", "");
    this.createdAt = _get(data, "createdAt", "");
    this.updatedAt = _get(data, "updatedAt", "");

    this.changed = _get(data, "changed", false);
  }

  // Static factory method
  static forSubbooking(data: any): Booking {
    const copy = new Booking(data);
    copy.tenants = [];
    copy.bookedby = [];
    copy.landlords = [];
    copy.subbookings = [];
    copy.previousbooking = undefined;
    // copy.subtype = undefined; https://trello.com/c/VgAwOkKo/584-key-collection-job-changed-to-ci
    copy.attachments = undefined;
    copy.dataentrydocuments = [];
    copy.internalnotesdocuments = [];
    copy.pinotesdocuments = [];
    copy.emailattachments = [];
    copy.emailattachmentdocuments = [];
    copy.emaillogs = undefined;
    copy.smslogs = undefined;
    copy.invoices = [];
    copy.auditlogs = [];
    copy.auditfieldlist = [];
    copy.auditvaluelist = [];
    copy.accessattachments = [];
    copy.worksorderdocuments = [];
    copy.dataentryreport = undefined;
    return copy;
  }

  get starttime() {
    return moment(this.startdate).utc().format("h:mm a");
  }
  get endtime() {
    return moment(this.enddate).utc().format("h:mm a");
  }
  get bookingdate() {
    return moment(this.startdate).utc().format("DD/MM/YYYY");
  }
  get bookingdatewithday() {
    return moment(this.startdate).utc().format("ddd DD/MM/YYYY");
  }

  get startdatefordiary() {
    if (this.duration === 15) {
      return moment(this.startdate)
        .utc()
        .subtract(450, "seconds")
        .format(bookingdateformat);
    } else {
      return this.startdate;
    }
  }
  set startdatefordiary(val: string) {
    this.startdate = val;
  }
  get enddatefordiary() {
    if (this.duration === 15) {
      return moment(this.enddate)
        .utc()
        .add(450, "seconds")
        .format(bookingdateformat);
    } else {
      return this.enddate;
    }
  }
  set enddatefordiary(val: string) {
    this.enddate = val;
  }

  get startDate() {
    return moment(this.startdate).utc().format("YYYY-MM-DD[T]HH:mm");
  }
  set startDate(val: string) {
    this.startdate =
      moment(val).utc(true).format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
  }
  get startdateAsDate(): Date {
    return moment(this.startdate).utc().toDate();
  }
  get endDate() {
    return moment(this.enddate).utc().format("YYYY-MM-DD[T]HH:mm");
  }
  set endDate(val: string) {
    this.enddate =
      moment(val).utc(true).format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
  }
  get enddateAsDate(): Date {
    return moment(this.enddate).utc().toDate();
  }

  get ismorningbooking(): boolean {
    const hours = this.startdateAsDate.getHours();
    return hours <= 13;
  }

  get appointmenttimeoutsidebooking(): boolean {
    if (!this.id) return false;
    if (this.subtype) return false;
    let apptime = moment(this.appointmenttime).utc().format("h:mm A");
    if (
      apptime === "12:00 AM" ||
      apptime === "12:15 AM" ||
      apptime === "12:20 AM" ||
      apptime === "12:25 AM"
    ) {
      return false;
    } else {
      return (
        moment.utc(this.startdate).isAfter(moment.utc(this.appointmenttime)) ||
        moment.utc(this.enddate).isBefore(moment.utc(this.appointmenttime))
      );
    }
  }

  get isAllDay(): boolean {
    if (!this.id) return false;
    if (this.subtype) return false;
    if (this.allDay) return true;
    if (
      moment.utc(this.startdate).hour() <= 4 ||
      moment.utc(this.enddate).hour() > 23
    )
      return true;
    return false;
  }

  get checkoutWithoutPreviousBooking(): boolean {
    if (
      this.jobtype === "checkout" &&
      this.internaljobtype.endsWith("off ACT report") &&
      (!this.previousbooking || !this.previousbooking.id)
    ) {
      return true;
    }

    return false;
  }

  get checkoutWithoutPreviousReport(): boolean {
    if (
      this.jobtype === "checkout" &&
      this.internaljobtype.endsWith("off ACT report") &&
      (!this.previousreport || !this.previousreport.id)
    ) {
      return true;
    }

    return false;
  }

  get checkoutWithoutDataentry(): boolean {
    if (
      this.jobtype === "checkout" &&
      this.internaljobtype.endsWith("off upload") &&
      !this.subtype
    ) {
      if (
        !this.emaillogs.dataentryconfirmationsentdate &&
        !this.emaillogs.dataentryacknowledgementreceiveddate
      ) {
        // If email hasn't been sent to splash, return true
        return true;
      } else {
        // If relay on three days rule
        let differenceindays = moment
          .duration(moment(this.startDate).diff(moment(new Date())))
          .asDays();
        if (differenceindays > 3) return false;
        if (
          !this.dataentryreport ||
          this.dataentryreport.dataentrystatus != "Upload Complete"
        )
          return true;
      }
    }

    return false;
  }

  get appointmenttimeForDisplay() {
    let apptime = moment(this.appointmenttime).utc().format("h:mm a");
    if (apptime === "12:00 am") apptime = "No Tenant but Fixed Time";
    if (apptime === "12:15 am") apptime = "Flexi all day";
    if (apptime === "12:20 am") apptime = "Flexi AM";
    if (apptime === "12:25 am") apptime = "Flexi PM";
    return apptime;
  }

  get duration() {
    let diff = 0;
    if (this.startdate && this.enddate) {
      diff = moment
        .duration(moment.utc(this.enddate).diff(moment.utc(this.startdate)))
        .asMinutes();
    }
    return diff;
  }

  get employeeId() {
    let id =
      this.inspector && !this.inspector.inactive
        ? this.inspector.id
        : this.zinspectorid;
    return id;
  }
  set employeeId(val: string) {
    if (!this.inspector) this.inspector = new Inspector();
    this.inspector.id = val;
  }

  get subject() {
    return (
      this.jobtype + " - " + this.addressPreviewLine1 + this.addressPreviewLine2
    );
  }

  get addressPreviewLine1() {
    return `${this.addressLine1}${this.addressLine1 ? ", " : ""}`;
  }
  get addressPreviewLine2() {
    return `${this.addressPostcode}`;
  }
  get addressPreview() {
    let lines = [];
    if (this.address.line1.trim().length > 0)
      lines.push(this.address.line1.trim());
    if (this.address.line2.trim().length > 0)
      lines.push(this.address.line2.trim());
    if (this.address.town.trim().length > 0)
      lines.push(this.address.town.trim());
    if (this.address.postcode.trim().length > 0)
      lines.push(this.address.postcode.trim());
    return lines.join(", ");
  }

  get addressLine1(): string {
    return this.address.line1;
  }

  get addressPostcode(): string {
    return this.address.postcode;
  }

  get updated() {
    return this._updated;
  }
  set updated(val: boolean) {
    this._updated = val;
  }

  get propertytypegroup() {
    let group: string | undefined = undefined;
    if (this.propertyspec) {
      const unfurnished =
        !this.propertyspec.furnished ||
        this.propertyspec.furnished === "Unfurnished" ||
        this.propertyspec.furnished === "Part furnished";
      if (
        this.propertyspec.propertytype &&
        this.propertyspec.propertytype === "Studio"
      ) {
        group = "Studio" + (unfurnished ? "" : " Furnished");
      } else if (this.propertyspec.bedrooms == 1) {
        group = `1 Bedroom ${unfurnished ? "Unfurnished" : "Furnished"}`;
      } else if (this.propertyspec.bedrooms == 2) {
        if (
          this.propertyspec.propertytype &&
          (this.propertyspec.propertytype === "Flat" ||
            this.propertyspec.propertytype === "Cottage")
        )
          group = `2 Bedroom ${unfurnished ? "Unfurnished" : "Furnished"} ${
            this.propertyspec.propertytype
          }`;
        else
          group = `2 Bedroom ${
            unfurnished ? "Unfurnished" : "Furnished"
          } Cottage`;
      } else if (this.propertyspec.bedrooms) {
        group = `${this.propertyspec.bedrooms} Bedroom ${
          unfurnished ? "Unfurnished" : "Furnished"
        } House`;
      }
    }
    return group;
  }

  get text() {
    return this.summary;
  }

  get startpostcodes(): string[] {
    var postcode: string[] = [this.address?.postcode];
    switch (this.keypickup) {
      case "From Agency":
        postcode = [
          this.keypickupfromaddress?.postcode,
          this.address?.postcode,
        ];
        break;
      case "Misc":
        if (this.keypickupfromaddress?.postcode) {
          postcode = [
            this.keypickupfromaddress?.postcode,
            this.address?.postcode,
          ];
        }
        break;
      case "":
      case "Meet Tenant":
      case "Via Concierge":
      case "Via Landlord":
        postcode = [this.address?.postcode];
        break;
    }
    return postcode;
  }
  get startpostcode(): string {
    var postcode: string = this.address?.postcode;
    if (this.keypickup === "From Agency" && this.keypickupfromaddress?.postcode)
      postcode = this.keypickupfromaddress?.postcode;
    return postcode;
  }

  get endpostcodes(): string[] {
    var postcode: string[] = [this.address?.postcode];
    switch (this.releasekeysto) {
      case "To Agency":
        postcode = [this.address?.postcode, this.keyreleasetoaddress?.postcode];
        break;
      case "Misc":
        if (this.keyreleasetoaddress?.postcode) {
          postcode = [
            this.address?.postcode,
            this.keyreleasetoaddress?.postcode,
          ];
        }
        break;
      case "":
      case "Meet Tenant":
      case "Via Concierge":
      case "Via Landlord":
        postcode = [this.address?.postcode];
        break;
    }
    return postcode;
  }
  get endpostcode(): string {
    var postcode: string = this.address?.postcode;
    if (
      this.releasekeysto === "To Agency" &&
      this.keyreleasetoaddress?.postcode
    )
      postcode = this.keyreleasetoaddress?.postcode;
    return postcode;
  }

  get propertypostcode(): string {
    if (this.address && this.address.postcode) return this.address.postcode;
    else return "";
  }
  get agencypostcode(): string {
    if (this.agencyaddress && this.agencyaddress.postcode)
      return this.agencyaddress.postcode;
    else return "";
  }

  get tenantsarray(): Tenant[] {
    if (!this.tenants || this.tenants.length == 0)
      this.tenants = [new Tenant()];
    return this.tenants;
  }
  set tenantsarray(tarray: Tenant[]) {
    this.tenants = tarray;
  }

  get landlordsarray(): Landlord[] {
    if (!this.landlords || this.landlords.length == 0)
      this.landlords = [new Landlord()];
    return this.landlords;
  }
  set landlordsarray(larray: Landlord[]) {
    this.landlords = larray;
  }
  get landlordpresent() {
    return (
      this.landlords.filter(
        (l: Landlord) => l.llname || l.llemail || l.llmobile
      ).length > 0
    );
  }
  get landlordname() {
    return this.landlords
      .filter((l: Landlord) => l.llname)
      .map((l: Landlord) => l.llname)
      .join(", ");
  }
  get landlordemail() {
    return this.landlords
      .filter((l: Landlord) => l.llemail)
      .map((l: Landlord) => l.llemail)
      .join(", ");
  }
  get landlordmobile() {
    return this.landlords
      .filter((l: Landlord) => l.llmobile)
      .map((l: Landlord) => l.llmobile)
      .join(", ");
  }

  get landlordmobilearray() {
    return this.landlords
      .filter((l: Landlord) => l.llmobile)
      .map((l: Landlord) => l.llmobile);
  }

  get bookedbyarray(): Bookedby[] {
    if (!this.bookedby || this.bookedby.length == 0)
      this.bookedby = [new Bookedby()];
    return this.bookedby;
  }
  set bookedbyarray(barray: Bookedby[]) {
    this.bookedby = barray;
  }
  get bookedbypresent() {
    return (
      this.bookedby.filter((b: Bookedby) => b.bbname || b.bbemail || b.bbmobile)
        .length > 0
    );
  }
  get bookedbyname() {
    return this.bookedby
      .filter((b: Bookedby) => b.bbname)
      .map((b: Bookedby) => b.bbname)
      .join(", ");
  }
  get bookedbymobile() {
    return this.bookedby
      .filter((b: Bookedby) => b.bbmobile)
      .map((b: Bookedby) => b.bbmobile)
      .join(", ");
  }
  get bookedbyemail() {
    return this.bookedby
      .filter((b: Bookedby) => b.bbemail)
      .map((b: Bookedby) => b.bbemail)
      .join(", ");
  }

  get appointmentconfirmationsent() {
    let sent = false;
    if (
      this.emaillogs.clientconfirmationsentdate ||
      this.emaillogs.landlordconfirmationsentdate
    )
      sent = true;
    if (this.smslogs.tenantconfirmationsentdate) sent = true;
    return sent;
  }

  get basereport() {
    let report = undefined;
    if (
      this.internaljobtype?.endsWith("off upload") &&
      this.dataentryreport?.id
    ) {
      report = this.dataentryreport;
    } else if (this.previousreport?.id) {
      report = this.previousreport;
    } else if (this.previousbooking?.id) {
      if (this.previousbooking.report?.id) {
        report = this.previousbooking.report;
      } else if (this.previousbooking?.invoices?.length) {
        report = this.previousbooking.invoices[0];
      }
    }
    return report;
  }

  get targetreport() {
    let report = undefined;
    if (this.report?.id) report = this.report;
    else if (this.invoices?.length) report = this.invoices[0];
    return report;
  }

  get bookingrequiringpreviousreport() {
    if (
      this.internaljobtype === "Inv CI - off upload" ||
      this.internaljobtype === "CI off ACT report" ||
      this.internaljobtype === "Check-In - off ACT report" ||
      this.internaljobtype === "Check-Out - off ACT report"
    ) {
      return true;
    } else {
      return false;
    }
  }

  get previousreportpi() {
    let pi = "";
    if (this.previousbooking?.id) {
      pi = this.previousbooking.inspector.name;
    } else if (this.previousreport?.id) {
      let pisig = undefined;
      if (this.previousreport.signatures) {
        pisig = this.previousreport?.signatures.find((s) => s.type === "pi");
      }
      if (pisig) {
        pi = pisig.name;
      } else {
        pi = this.previousreport.preparedBy;
      }
    }
    return pi;
  }

  get isAfterconnectedbooking() {
    let flag = false;
    if (this.connectedbooking?.id) {
      const bstartdae = moment(this.startdate).utc();
      const cbstartdate = moment(this.connectedbooking.startdate).utc();
      flag = bstartdae.isAfter(cbstartdate);
    }
    return flag;
  }

  get isAfterpreviousbooking() {
    let flag = true;
    if (this.previousbooking?.id) {
      const bstartdae = moment(this.startdate).utc();
      const cbstartdate = moment(this.previousbooking.startdate).utc();
      flag = bstartdae.isAfter(cbstartdate);
    } else if (this.previousreport?.id) {
      const bstartdae = moment(this.startdate).utc();
      const cbstartdate = moment(this.previousreport.date).utc();
      flag = bstartdae.isAfter(cbstartdate);
    }
    return flag;
  }

  get tenantNameList() {
    const data = this.tenants
      .filter((f) => f.ttname != "")
      .map((t) => t.ttname);
    return data.length > 0 ? data : [];
  }

  public isTenantEmail(email: string) {
    var result = false;
    if (this.tenants?.length) {
      const index = this.tenants.findIndex(
        (t) => t?.ttemail?.trim() === email?.trim()
      );
      result = index >= 0;
    }
    return result;
  }

  public isMasterBooking() {
    let result = true;
    if (this.googleid) result = false;
    if (this.subtype) result = false;
    if (!this.jobtype) result = false;
    if (this.jobtype === Booking.PERSONAL) result = false;
    return result;
  }

  public isPrepOrSharedOrKeyOrRevisit() {
    let result = false;
    if (
      this.subtype === Booking.PREP ||
      this.subtype === Booking.SHARED ||
      this.subtype === Booking.KEY ||
      this.subtype === Booking.REVISIT
    ) {
      result = true;
    }
    return result;
  }

  public preserveoriginalvalues() {
    this.auditfieldlist = [];
    this.auditvaluelist = [];
    this.auditfieldlist.push("startdate");
    this.auditvaluelist.push(
      Booking.formatFieldValue("startdate", this.startdate)
    );
    this.auditfieldlist.push("starttime");
    this.auditvaluelist.push(this.starttime);
    this.auditfieldlist.push("enddate");
    this.auditvaluelist.push(Booking.formatFieldValue("enddate", this.enddate));
    this.auditfieldlist.push("endtime");
    this.auditvaluelist.push(this.endtime);
    this.auditfieldlist.push("appointmenttime");
    this.auditvaluelist.push(
      Booking.formatFieldValue("appointmenttime", this.appointmenttime)
    );
    this.auditfieldlist.push("clientnotes");
    this.auditvaluelist.push(this.clientnotes);
    this.auditfieldlist.push("logisticsnotes");
    this.auditvaluelist.push(this.logisticsnotes);
    this.auditfieldlist.push("qcnotes");
    this.auditvaluelist.push(this.qcnotes);
    this.auditfieldlist.push("pinotes");
    this.auditvaluelist.push(this.pinotes);
    this.auditfieldlist.push("inspector.id");
    this.auditvaluelist.push(this.inspector.id);
    this.auditfieldlist.push("cancelnotes");
    this.auditvaluelist.push(this.cancelnotes);
    this.auditfieldlist.push("fixedtimenotes");
    this.auditvaluelist.push(this.fixedtimenotes);
    this.auditfieldlist.push("issued");
    this.auditvaluelist.push(this.issued ? "Yes" : "No");
  }

  public static getFieldDisplayName(field: string) {
    let displayname: string = "";
    switch (field) {
      case "startdate":
        displayname = "Start date";
        break;
      case "enddate":
        displayname = "End date";
        break;
      case "starttime":
        displayname = "Start time";
        break;
      case "endtime":
        displayname = "End time";
        break;
      case "inspector.id":
        displayname = "Inspector";
        break;
      case "appointmenttime":
        displayname = "Appointment time";
        break;
      case "clientnotes":
        displayname = "Client notes";
        break;
      case "logisticsnotes":
        displayname = "Logistics notes";
        break;
      case "internalnotes":
        displayname = "ACT office notes";
        break;
      case "longtermnotes":
        displayname = "Long term notes";
        break;
      case "qcnotes":
        displayname = "Quality control notes";
        break;
      case "pinotes":
        displayname = "PI notes";
        break;
      case "fixedtimenotes":
        displayname = "Fixed Time notes";
        break;
      case "cancelnotes":
        displayname = "Cancellation notes";
        break;
      case "titlenotes":
        displayname = "Title notes";
        break;
      case "issued":
        displayname = "Issued";
        break;
    }
    return displayname;
  }

  public static getFieldCaption(field: string) {
    let caption: string = "";
    switch (field) {
      case "pinotes":
        caption = "(seen by office via CMS and PI on the app)";
        break;
      case "internalnotes":
        caption = "(seen by office only via CMS)";
        break;
      case "clientnotes":
        caption =
          "(Seen in confirmation to client but not seen by PI on tablet)";
        break;
      case "titlenotes":
        caption = "(seen by office via CMS and PI on the app)";
        break;
      case "longtermnotes":
        caption = "(seen by office via CMS and PI on the app)";
        break;
    }
    return caption;
  }

  public static formatFieldValue(field: string, val: string | undefined) {
    let returnvalue: string = "";
    if (val) returnvalue = val;
    switch (field) {
      case "startdate":
        returnvalue = moment(val).utc().format("DD/MM/YYYY");
        break;
      case "enddate":
        returnvalue = moment(val).utc().format("DD/MM/YYYY");
        break;
      case "appointmenttime":
        returnvalue = moment(val).utc().format("h:mm a");
        break;
    }
    return returnvalue;
  }

  public original(field: string): string | undefined {
    let value: string | undefined = "";
    let index = this.auditfieldlist.findIndex((f: string) => f === field);
    if (index >= 0) {
      value = this.auditvaluelist[index];
    }
    return value;
  }

  public changelist(): Map<string, string> {
    let result: Map<string, string> = new Map();
    if (this.auditfieldlist) {
      this.auditfieldlist.forEach((f: string) => {
        const originalvalue: string | undefined = this.original(f);
        const val = _get(this, f, "") as string;
        let newvalue: string = Booking.formatFieldValue(f, val);
        if (originalvalue && newvalue != originalvalue) {
          if (
            (f === "issued" && originalvalue === "Yes" && newvalue) ||
            (originalvalue === "No" && !newvalue)
          ) {
            return;
          } else {
            result.set(f, originalvalue);
          }
        }
      });
    }
    return result;
  }

  get appointmentconfirmationemailsent() {
    return (
      this.emaillogs?.clientconfirmationsentdate ||
      this.emaillogs?.tenantconfirmationsentdate ||
      this.smslogs?.tenantconfirmationsentdate
    );
  }

  public addSubbooking(subbooking: Booking) {
    const index = this.subbookings.findIndex(
      (b: Booking) => b.id === subbooking.id
    );
    if (index < 0) this.subbookings.push(subbooking);
  }
  public removeSubbooking(subbooking: Booking) {
    const index = this.subbookings.findIndex(
      (b: Booking) => b.id === subbooking.id
    );
    if (index >= 0) this.subbookings.splice(index, 1);
  }

  public isLocked(email: string, deviceid: string) {
    if (
      this.locked &&
      this.lockedby === email &&
      this.lockedbydevice === deviceid
    ) {
      return moment(this.lockeduntil).utc().isAfter(moment().utc());
    } else {
      return false;
    }
  }

  get fromDurationMins() {
    let value: string = "";
    if (this.fromDurationSecs) {
      value = `${Math.round(this.fromDurationSecs / 60)}`;
    }
    return value;
  }
  get toDurationMins() {
    let value: string = "0";
    if (this.toDurationSecs) {
      value = `${Math.round(this.toDurationSecs / 60)}`;
    }
    return value;
  }

  get emailattachmentdocuments() {
    var docs: Document[] = [];
    if (this.emailattachments.length) {
      docs = this.emailattachments.map((a) => {
        const doc: Document = new Document();
        doc.src = a.path;
        return doc;
      });
    }
    return docs;
  }
  set emailattachmentdocuments(docs: Document[]) {
    if (docs.length) {
      this.emailattachments = docs.map((d) => {
        const emailatt = new EmailAttachment();
        if (d?.src) {
          const lastindex = d.src.lastIndexOf("/");
          emailatt.filename =
            lastindex >= 0 ? d.src.substring(lastindex + 1) : "";
        }
        emailatt.generatingstatus = "finished";
        emailatt.path = d.src;
        return emailatt;
      });
    }
  }

  public clearTravelTimes() {
    this.fromDurationText = "";
    this.fromDurationSecs = -1;
    this.topFrom = false;
    this.toDurationText = "";
    this.toDurationSecs = -1;
    this.topTo = false;
    this.traveltime = new TravelTime();
  }

  public setAccessAfterTenantattendingChange() {
    // Based on the value of tenantattending, adjust key pickup or key release
    if (
      (this.jobtype === "inventory" || this.jobtype === "checkin") &&
      this.tenantattending === "no" &&
      this.releasekeysto === "Meet Tenant"
    ) {
      this.releasekeysto =
        this.keypickup === "From Agency" ? "To Agency" : this.keypickup;
      if (this.keypickupbranchname)
        this.releasekeystobranchname = this.keypickupbranchname;
      if (this.keypickupfromaddress)
        this.keyreleasetoaddress = new Address(this.keypickupfromaddress);
    } else if (
      (this.jobtype === "inventory" || this.jobtype === "checkin") &&
      this.tenantattending === "yes" &&
      this.releasekeysto != "Meet Tenant"
    ) {
      this.releasekeysto = "Meet Tenant";
      this.releasekeystobranchname = "";
      this.keyreleasetoaddress = new Address();
    } else if (
      (this.jobtype === "checkout" || this.jobtype === "soc") &&
      this.tenantattending === "no" &&
      this.keypickup === "Meet Tenant"
    ) {
      this.keypickup =
        this.releasekeysto === "To Agency" ? "From Agency" : this.releasekeysto;
      if (this.releasekeystobranchname)
        this.keypickupbranchname = this.releasekeystobranchname;
      if (this.keyreleasetoaddress)
        this.keypickupfromaddress = new Address(this.keyreleasetoaddress);
    } else if (
      (this.jobtype === "checkout" || this.jobtype === "soc") &&
      this.tenantattending === "yes"
    ) {
      this.confirmaccess = true;
      if (this.keypickup != "Meet Tenant") {
        this.keypickup = "Meet Tenant";
        this.keypickupbranchname = "";
        this.keypickupfromaddress = new Address();
      }
    }
  }

  // get exclDates() {
  //   const dates: Date[] = [];
  //   if(this.recurrenceExclDates) {
  //     const splits = this.recurrenceExclDates.split(",");
  //     splits.map((dt) => dates.push(moment(dt).utc().toDate()));
  //   }
  //   return dates;
  // }

  get recurrenceException() {
    return this.recurrenceExclDates;
  }

  public addExclDate(date: Date) {
    const newdate = moment(date).utc().format(schedulerdateutcformat);
    if (!this.recurrenceExclDates) {
      this.recurrenceExclDates = `${newdate}`;
    } else if (this.recurrenceExclDates.indexOf(newdate) === -1) {
      this.recurrenceExclDates = `${this.recurrenceExclDates},${newdate}`;
    }
  }

  public setRecurrenceEnddate(date: Date) {
    if (!this.recurrenceRule) return;
    const splits: string[] = this.recurrenceRule.split(";");
    if (splits.length > 0) {
      const untilIndex = splits.findIndex((s: string) => s.startsWith("UNTIL"));
      if (untilIndex >= 0) {
        splits.splice(untilIndex, 1);
      }
      splits.push(
        `UNTIL=${moment(date).utc().format(schedulerenddateutcformat)}`
      );
      this.recurrenceRule = splits.join(";");
    }
  }

  get clientqcrule(): QCRule {
    return this.customer.getQCRule(this.jobtype, "client");
  }
  get tenantqcrule(): QCRule {
    return this.customer.getQCRule(this.jobtype, "tenant");
  }
  get landlordqcrule(): QCRule {
    return this.customer.getQCRule(this.jobtype, "landlord");
  }
  get invoiceqcrule(): QCRule {
    return this.customer.getQCRule(this.jobtype, "invoice");
  }

  public checkRecurrance(date: Date) {
    if (!this.recurrenceRule) return false;

    const bookingstartdate = moment(this.startdate)
      .utc()
      .format("YYYYMMDD[T000000]");
    let filterdatemoment = moment(date).utc();
    let stdate = filterdatemoment.startOf("day").toDate();
    let endate = filterdatemoment.endOf("day").toDate();

    const ruleSet = new RRuleSet();
    const rRule = rrulestr(
      `DTSTART:${bookingstartdate}Z\n${this.recurrenceRule}`
    );
    ruleSet.rrule(rRule);
    if (this.recurrenceExclDates) {
      this.recurrenceExclDates.split(",").forEach((dt) => {
        if (dt) ruleSet.exdate(moment(dt).utc().toDate());
      });
    }

    const occurance = ruleSet.between(stdate, endate, true);
    return occurance.length > 0;
  }

  /**
   * Create a JSON representation of this object and it's descedents.
   *
   * @return {Object}
   */
  public toJSON() {
    const json = super.toJSON();

    // Convert the populated Office to the Office ID
    return {
      ...json,
      previousreport: this.previousreport?.id ?? "",
      dataentryreport: this.dataentryreport?.id ?? "",
      previousbooking: this.previousbooking?.id ?? "",
      connectedbooking: this.connectedbooking?.id ?? "",
      booking: this.booking?.id ?? "",
      recurrence_rule: this.recurrenceRule ?? "",
    };
  }

  /**
   * Convert class instance back into a plain old JSON object
   * and recursively remove all uuids we've add for Vue.
   *
   * @return {Object}
   */
  public toObject() {
    return omitDeep({ ...this }, ["_uuid"]);
  }
}
