<template>
  <div
    :id="id"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`booking-detail-modal-label`"
    aria-hidden="true"
    style=""
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      :class="{ 'modal-lg': booking.googleid }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sms-modal-label">
            <span>PV Bookings</span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" :key="bookingid" >
          <div id="booking-detail-modal-body">
            <h3 class="mb-1">Address</h3>
            <div
              v-for="(singleBooking, index) in booking.bookings"
              :key="index"
            >
              <div @click="onAddressclick(singleBooking.address)">
                <span>
                  {{ actProperty.formatAddress(singleBooking.address, ", ") }}
                </span>
              </div>
            </div>

            <div>
              <h3 class="mb-0">Client</h3>
            </div>
            <div
              v-for="(singleBooking, index) in booking.bookings"
              :key="index"
            >
              <div
                v-if="
                  singleBooking?.customer &&
                  (singleBooking.customer.companyName ||
                    singleBooking.customer.branchName)
                "
              >
                {{ singleBooking.customer.companyName }} -
                {{ singleBooking.customer.branchName }}
              </div>
            </div>

            <div class="mt-4">
              <h3 class="mb-0">Inspector</h3>
            </div>
            <div v-if="booking?.inspector">
              {{ booking.inspector.name }}
            </div>
            <div>
              <div class="mt-4">
                <h3 class="mb-0">Time</h3>
              </div>
              <div
                v-for="(singleBooking, index) in booking.bookings"
                :key="index"
              >
                <div v-if="singleBooking?.startdate || singleBooking?.enddate">
                  {{ singleBooking.starttime }}
                  to
                  {{ singleBooking.endtime }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import $ from "jquery";
import _get from "lodash/get";
import {
  ref,
  computed,
  defineEmits,
  inject,
  defineProps,
  defineExpose,
} from "vue";
import { useStore } from "vuex";
// Import your models or other external methods
import { Address, Booking, BookingBlock, CognitoUser } from "@/models";

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  showAuditLogs: {
    type: Boolean,
    default: true,
  },
  id: String,
});

const emit = defineEmits(["showAuditLogsModal"]);

const store = useStore();

const cognitoUser = computed(
  (): CognitoUser => store.getters["auth/cognitoUser"]
);
const saveBooking = (booking: BookingBlock) => {
  return store.dispatch("diary/saveBookingFromScheduler", booking);
};

const realtime: any = inject("realtime");
const actProperty: any = inject("actProperty");

const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();

const booking = ref(new BookingBlock());
const titlenoteschanged = ref(false);
const bookingid = computed(() => booking.value.id);

// Methods
const init = (bookingData: BookingBlock) => {
  booking.value = bookingData;
};
const onAddressclick = (addressdata: Address) => {
  let address = actProperty.formatAddress(addressdata, ", ");
  window.open(
    `http://maps.google.com/?q=${encodeURI(address)}`,
    "_blank",
    "noreferrer"
  );
};

const show = () => {
  if ($(`#${props.id}` + "Backdrop").length == 0) {
    const backdropDiv = $(
      '<div class="modal-backdrop fade show" id="' +
        props.id +
        'Backdrop"></div>'
    );
    $("body").append(backdropDiv);
    let el = $(`#${props.id}`);
    el.show();
  }
};

const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + "Backdrop").remove();
  }
  if (titlenoteschanged.value) {
    saveBooking(booking.value).then((b: Booking) => {
      channel.publish("appointmentChanged", {
        deviceid: deviceid,
        bookingid: b.id,
        source: "Scheduler",
      });
      $(`#${props.id}`).hide();
    });
  } else {
    $(`#${props.id}`).hide();
  }
};

defineExpose({ init, show, hide });
</script>

<style scoped lang="scss">
.modal-body {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>
