<template>
  <div>
    <h2>Summary</h2>
    <div class="row">
      <div class="col-md-9 dx-item-content dx-toolbar-item-content" style="min-width: 350px">
        <div role="group" class="dx-buttongroup dx-widget" tabindex="0">
          <div class="dx-buttongroup-wrapper dx-widget dx-collection">
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-first-item dx-shape-standard dx-scheduler-navigator-previous"
              role="button" aria-label="chevronprev" @click="prevdate">
              <div class="dx-button-content">
                <i class="dx-icon dx-icon-chevronprev"></i>
              </div>
            </div>
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-caption"
              role="button" aria-label="">
              <DxDateBox v-model="currentdate" display-format="EEEE dd MMMM yyyy" type="date" picker-type="calendar"
                apply-value-mode="instantly" class="datebox" :calendarOptions="{ firstDayOfWeek: 1 }" />
            </div>
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-next"
              role="button" aria-label="chevronnext" @click="nextdate">
              <div class="dx-button-content">
                <i class="dx-icon dx-icon-chevronnext"></i>
              </div>
            </div>
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-last-item dx-shape-standard today-button"
              role="button" aria-label="today" @click="today">
              <div class="dx-button-content">
                <i class="far fa-calendar-check"></i> Today
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-hover mt-5">
      <thead style="color: red;">
        <th>Week days</th>
        <th>Date</th>
        <th>Age</th>
        <th>Inventory</th>
        <th>Check-out/SOC</th>
        <th>Check-In</th>
        <th>Other</th>
        <th>Total to hit KPI</th>
        <th>PVs</th>
        <th>Feedback</th>
      </thead>
      <tbody>
        <template v-for="(data, prepdata) in summaryArray" :key="prepdata">
          <tr>
            <td>{{ data.weekdays }}</td>
            <td>{{ data.date }}</td>
            <td style="color: red;">{{ findAge(data.date) }}</td>
            <td>{{ data.Inventory }}</td>
            <td>{{ data.CoSOC }}</td>
            <td>{{ data.ci }}</td>
            <td>{{ data.other }}</td>
            <td>{{ }}</td>
            <td>{{ data.pv }}</td>
            <td>{{ data.feedback }}</td>
          </tr>
          <tr v-if="prepdata === 7" style="color: red;">
            <td colspan="3"><strong>Total</strong></td>
            <td><strong>{{ totalInventory }}</strong></td>
            <td><strong>{{ totalCoSOC }}</strong></td>
            <td><strong>{{ totalCheckIn }}</strong></td>
            <td><strong>{{ totalOther }}</strong></td>
            <td><strong>{{ totalToHitKPI }}</strong></td>
            <td><strong>{{ totalPV }}</strong></td>
            <td><strong>{{ totalfeedback }}</strong></td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>


</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from "moment-timezone";

const route = useRoute();
const router = useRouter();

const store = useStore();
const _currentdate = computed(() => store.getters["diary/currentdate"]);
const todaymoment = moment().utc().startOf('day');

import { DxDateBox } from "devextreme-vue/date-box";
import { Booking, Report } from '@/models';
const summaryArray = ref<SummaryData[]>([]);

const setCurrentdate = (currentdate: Date) => {
  store.commit("diary/setCurrentdate", currentdate);
};

const getBookingsforQCSummary = (payload?: { date?: string; }, p0?: () => void) => {
  return store.dispatch("diary/getBookingsforQCSummary", payload);
};

const currentdate = computed({
  get: () => _currentdate.value,
  set: (val:Date) => {
    setCurrentdate(val)
    bookingforQCsummary();
  },
});


const formatWeekDay = (date: Date) => moment(date).format("dddd");
const formatdate = (date: Date) => moment(date).format("DD-MM-YYYY");

const prevdate = () => {
  isCalendarSelected.value = true;
  currentdate.value = moment(currentdate.value)
    .utc()
    .subtract(1, "days")
    .toDate();
  bookingforQCsummary();
};

const nextdate = () => {
  isCalendarSelected.value = true;
  currentdate.value = moment(currentdate.value).utc().add(1, "days").toDate();
  bookingforQCsummary();
};

const today = () => {
  isCalendarSelected.value = false;
  currentdate.value = moment().utc().toDate();
  bookingforQCsummary();
};

const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};

const dformatDate = (date: Date) => {
  return moment(date).format("DD/MM/YYYY");
};

class SummaryData {
  weekdays?: string;
  date?: string;
  age?: string;
  Inventory?: number;
  CoSOC: number;
  ci: number;
  other: number;
  pv: number;
  feedback: number;
  kpi: number;
}

const calculateSummaryData = (bookingList: Booking[], date: Date) => {
  const jobtypeArray = ["inventory", "checkin", "soc", "property visit", "checkout"];
  let cosocdata = bookingList.filter((b: Booking) => {
    return (b.jobtype === 'checkout' || b.jobtype === 'soc') && b.bookingdate === dformatDate(date);
  });
  let checkindata = bookingList.filter((b: Booking) => {
    return b.jobtype === 'checkin' && b.bookingdate === dformatDate(date);
  });
  let inventorydata = bookingList.filter((b: Booking) => {
    return b.jobtype === 'inventory' && b.bookingdate === dformatDate(date);
  });
  let pvdata = bookingList.filter((b: Booking) => {
    return b.jobtype === 'property visit' && b.bookingdate === dformatDate(date);
  });
  let otherdata = bookingList.filter((b: Booking) => {
    return !jobtypeArray.includes(b.jobtype) && b.bookingdate === dformatDate(date);
  });
  let feedbackstatus = bookingList.filter((b: Booking) => {
    return b.report.feedbackstatus === 'Received' && b.bookingdate === dformatDate(date);
  });

  let data = new SummaryData();
  data.CoSOC = cosocdata.length;
  data.ci = checkindata.length;
  data.Inventory = inventorydata.length;
  data.pv = pvdata.length;
  data.other = otherdata.length;
  data.feedback = feedbackstatus.length;
  return data;
}

onMounted(() => {
  bookingforQCsummary();
});

// watch(currentdate, () => {
//   bookingforQCsummary();
// });


const bookingforQCsummary = () => {
  getBookingsforQCSummary({ date: formatDate(currentdate.value) }, () => { }).then((bookingList: Booking[]) => {
    summaryArray.value = [];

    const start = moment(currentdate.value).subtract(9, "days");
    const end = moment(currentdate.value).add(4, "days");
    const dateRange = [];

    for (let m = start; m.isBefore(end); m.add(1, 'days')) {
      dateRange.push(m.toDate());
    }
    for (let i = 0; i < dateRange.length; i++) {
      let obj = calculateSummaryData(bookingList, dateRange[i]);
      let data = new SummaryData();
      let date = dateRange[i];
      data.weekdays = formatWeekDay(date);
      data.date = formatdate(date);
      data.CoSOC = obj.CoSOC;
      data.ci = obj.ci;
      data.Inventory = obj.Inventory;
      data.pv = obj.pv;
      data.other = obj.other;
      data.feedback = obj.feedback;
      summaryArray.value.push(data);
    }
  });
}

const isCalendarSelected = ref(false);

const findAge = (date: string) => {
      const dateMoment = moment.tz(date, "DD-MM-YYYY", "UTC");
      const todayMoment = moment().utc().startOf('day');
      const currentMoment = moment(currentdate.value).utc().startOf('day');

      if (dateMoment.isSame(todayMoment, 'day') && !isCalendarSelected.value) {
        return "Today";
      } else if (dateMoment.isSame(currentMoment, 'day') && isCalendarSelected.value) {
        return "Selected Day";
      } else {
        let diffInDays = 0;
        let mom = moment(currentdate.value).utc().clone();

        if (dateMoment.isSameOrBefore(mom)) {
          while (mom.isAfter(dateMoment, 'day')) {
            mom.add(-1, 'days');
            const dayOfWeek = mom.day();
            if (dayOfWeek !== 6 && dayOfWeek !== 0) { // Skip Saturday (6) and Sunday (0)
              diffInDays++;
            }
          }
        } else {
          while (mom.isBefore(dateMoment, 'day')) {
            mom.add(1, 'days');
            const dayOfWeek = mom.day();
            if (dayOfWeek !== 6 && dayOfWeek !== 0) { // Skip Saturday (6) and Sunday (0)
              diffInDays++;
            }
          }
          return `+${diffInDays}`;
        }

        return  diffInDays.toString();
      }
    };

const totalInventory = computed(() => {
  return summaryArray.value.slice(0, 8).reduce((total, item) => total + (item.Inventory || 0), 0);
});

const totalCoSOC = computed(() => {
  return summaryArray.value.slice(0, 8).reduce((total, item) => total + (item.CoSOC || 0), 0);
});

const totalCheckIn = computed(() => {
  return summaryArray.value.slice(0, 8).reduce((total, item) => total + (item.ci || 0), 0);
});

const totalOther = computed(() => {
  return summaryArray.value.slice(0, 8).reduce((total, item) => total + (item.other || 0), 0);
});

const totalPV = computed(() => {
  return summaryArray.value.slice(0, 8).reduce((total, item) => total + (item.pv || 0), 0);
});

const totalfeedback = computed(() => {
  return summaryArray.value.slice(0, 8).reduce((total, item) => total + (item.feedback || 0), 0);
});

const totalToHitKPI = computed(() => {
  return totalInventory.value + totalCoSOC.value + totalCheckIn.value + totalOther.value;
});
</script>


<style scoped lang="scss"></style>