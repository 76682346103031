<template>
  <div class="user-header">
    <form>
      <fieldset>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Name</label>
          <div class="col-sm-4">
            <input
              v-model="name"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">DD Number</label>
          <div class="col-sm-4">
            <input
              v-model="phone"
              type="text"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-4">
            <input
              type="text"
              class="form-control"
              :value="email"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Signature</label>
          <div class="col-sm-9 body-editor" :height="messageheight">
            <ckeditor :editor="signatureeditor" v-model="signatureeditordata" :config="signatureEditorConfig"></ckeditor>
          </div>
        </div>

      </fieldset>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import { User} from '@/models';
import Editor from "ckeditor5/ckeditor";

const height = ref(window.innerHeight);

const store = useStore();
const user = computed((): User => store.getters['usermanagement/selected']);
const setUserDeep = (payload: {
  path: string;
  data: any;
}) => {
  store.commit("usermanagement/setUserDeep", payload);
  store.commit('usermanagement/addUnsavedChange', payload.path)
};

const messageheight = computed(() => {
  return height.value - height.value * 0.30;
});

const name = computed({
  get: () => user.value.name,
  set: (data) => {
    setUserDeep({ path: "name", data });
  },
});

const phone = computed({
  get: () => user.value.phone,
  set: (data) => {
    setUserDeep({ path: "phone", data });
  },
});

const email = computed({
  get: () => user.value.email,
  set: (data) => {
    setUserDeep({ path: "email", data });
  },
});

const signatureeditor = computed(() => {
  return Editor;
});

const signatureEditorConfig = computed(() => {
  return {
    toolbar: {
      items: ['heading', '|', 'bold', 'italic', 'link', '|', 'alignment:left', 'alignment:right', 'alignment:center',
        'alignment:justify', '|', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'outdent', 'indent',
        '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo',],
    },
    licenseKey:
      "UUI3NHlwcFFOOXZpdFlqSFlhSUFYbWpCRHY4MnpuQWR1Mk95K2ZZWkxKSlVGclUrSFAvWEpmYndNRzNKLU1qQXlOREF4TURZPQ==",
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
  };
});

const signatureeditordata = computed({
  get: () => user.value.signature,
  set: (data) => {
    setUserDeep({ path: "signature", data });
  },
});

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

        
        