<template>
  <div class="modal" data-keyboard="true" tabindex="-1" role="dialog" :id="id"
    aria-labelledby="`personal-job-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :class="{ 'modal-lg': repeat === 'Custom' }" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="personal-job-modal-label">
            <span v-if="operation === 'add'">Add personal event</span>
            <span v-if="operation === 'edit'">Edit personal event</span>
          </h5>
          <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-6" :class="{ 'col-md-6': repeat === 'Custom', 'col-md-12': repeat != 'Custom' }">
              <form>
                <fieldset>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Title</label>
                    <div class="col-md-8 pr-0">
                      <input type="text" class="form-control" v-model="title" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Date</label>
                    <div class="col-md-6">
                      <DxDateBox v-model="subjobdate" display-format="dd/MM/yyyy" type="date" picker-type="calendar"
                        apply-value-mode="instantly" :calendarOptions="{ firstDayOfWeek: 1 }" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Inspector</label>
                    <div class="col-sm-8">
                      <singleselect-text v-model="inspector" :options="inspectorlistinstring" :allow-empty="true"
                        :taggable="false" @search-change="inspectorinstringsearchfilter = $event"
                        :ref="el => { dynamicRefs['inspectorDropDown'] = el }"
                        @open="dropDownOpened('inspectorDropDown')">
                        <template #clear v-if="selectedinspector && selectedinspector.id">
                          <i @mousedown.prevent.stop="resetInspector" class="multiselect__clear fa fa-times"
                            aria-label="Clear Who"></i>
                        </template>
                      </singleselect-text>
                    </div>
                  </div>


                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Copy to</label>
                    <div :class="{ 'col-md-6': operation === 'add', 'col-md-8': operation === 'edit' }">
                      <multiselect-text class="inspectorfilter" v-model="selectedinspectors" :options="inspectoroptions"
                        :allow-empty="true" :taggable="false" :disabled="sharewithall"
                        @search-change="copytoinspectorinstringsearchfilter = $event" placeholder="Select" trackBy="id"
                        label="name" multiple>
                      </multiselect-text>
                    </div>
                    <label v-if="operation === 'add'" class="col-md-1 col-form-label">All</label>
                    <div v-if="operation === 'add'" class="col-sm-2" style="margin-top: 5px;">
                      <DxSwitch :value="sharewithall" @value-changed="sharewithall = $event.value" />
                    </div>
                  </div>
                  <div v-if="!allday" class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Timing</label>
                    <div class="col-md-4 pr-0">
                      <singleselect-text v-model="_startdate" :options="starttimeoptions" :customLabel="customLabel" :allow-empty="true"
                        :taggable="false" :ref="el => { dynamicRefs['startDateDropDown'] = el }"
                        @open="dropDownOpened('startDateDropDown')">
                        <template #clear v-if="_startdate">
                          <i @mousedown.prevent.stop="_startdate = ''" class="multiselect__clear fa fa-times"
                            aria-label="Clear Start Time"></i>
                        </template>
                      </singleselect-text>
                    </div>
                    <div class="col-md-4 pl-0">
                      <singleselect-text v-model="_enddate" :options="endtimeoptions" :customLabel="customLabel" :allow-empty="true"
                        :taggable="false" :ref="el => { dynamicRefs['endDateDropDown'] = el }"
                        @open="dropDownOpened('endDateDropDown')">
                        <template #clear v-if="_enddate">
                          <i @mousedown.prevent.stop="_enddate = ''" class="multiselect__clear fa fa-times"
                            aria-label="Clear End Time"></i>
                        </template>
                      </singleselect-text>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">All day</label>
                    <div class="col-md-6" style="margin-top: 5px;">
                      <DxSwitch :value="allday" @value-changed="allday = $event.value" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Location</label>
                    <div class="col-md-8 pr-0">
                      <input type="text" class="form-control" rows="3" v-model.lazy="location" />
                    </div>
                  </div>

                  <div v-if="booking.googleid">
                    <tiptap-editor :key="personalbooking.id" :content="description" @blur="setDescription" />
                  </div>
                  <div v-else class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Description</label>
                    <div class="col-md-8 pr-0">
                      <textarea class="form-control description-textarea" rows="6" v-model.lazy="description"></textarea>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Repeat</label>
                    <div class="col-md-8 pr-0">
                      <singleselect-text v-model="repeat" :options="repeatoptions"></singleselect-text>
                    </div>
                  </div>

                </fieldset>
              </form>
            </div>
            <div v-if="repeat === 'Custom'" class="col-md-6">
              <form>
                <fieldset>
                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Repeat every</label>
                    <div class="col-md-9 pr-0">
                      <div class="row">
                        <div class="col-md-4">
                          <input type="number" class="form-control" style="height: 40px;" v-model="repeatevery" min="1"
                            @keypress="isNumber($event)" />
                        </div>
                        <div class="col-md-6">
                          <singleselect-text v-model="selectedfrequency"
                            :options="repeatfrequencyoptions"></singleselect-text>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Repeat on</label>
                    <div class="col-md-9 pr-0">
                      <button v-for="(letter, $index) in repeatonoptions" :key="$index" type="button"
                        class="btn alphabet-button" :class="{ 'btn-primary': repeaton[$index] }"
                        @click="repeaton[$index] = !repeaton[$index]">
                        {{ letter }}
                      </button>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-3 col-form-label">Ends</label>
                    <div class="col-md-9">
                      <singleselect-text v-model="ends" :options="endsonoptions"></singleselect-text>
                    </div>
                  </div>

                  <div class="form-group row" v-if="ends === 'On'">
                    <label class="col-md-3 col-form-label">On</label>
                    <div class="col-md-6">
                      <DxDateBox v-model="endson" display-format="dd/MM/yyyy" type="date" picker-type="calendar"
                        apply-value-mode="instantly" :calendarOptions="{ firstDayOfWeek: 1 }" />
                    </div>
                  </div>

                  <div class="form-group row" v-if="ends === 'After'">
                    <label class="col-md-3 col-form-label">After</label>
                    <div class="col-md-6">
                      <input type="number" class="form-control" style="height: 40px;"
                        v-model.lazy="endsafteroccurancenumber" /> Occurance
                    </div>
                  </div>

                </fieldset>

              </form>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-light mr-auto" title="Audit logs" @click="showAuditLogsModal">
            <i class="fas fa-clipboard-list"></i> Audit logs
          </button>
          <button v-if="operation === 'edit' && booking.cancelled" type="button" class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal" @click.prevent="restore()">
            Restore
          </button>
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide()">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="save()"
            :disabled="isSaving || !savingAllowed">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <RecursionConfirmModal id="edit-note-model" ref="recursionConfirmModal" :booking="booking"
    @select="selectEditNoteModal" />
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { ref, computed, onMounted, inject, watch, nextTick, defineEmits, defineProps, PropType, defineExpose } from 'vue';
import { useStore } from 'vuex';
import moment from "moment-timezone";
import { DxDateBox } from "devextreme-vue/date-box";
import TiptapEditor from "@/components/editor/TiptapEditor.vue";
import { Booking, Inspector } from "@/models";
import { bookingdateformat } from "@/utilities";
import { useToast } from "vue-toastification";
import { emitEvent } from '@/eventBus';
import { DxSwitch } from 'devextreme-vue/switch';
import RecursionConfirmModal from "@/components/modals/RecursionConfirmModal";
// Props
const props = defineProps({
  booking: { type: Object as PropType<Booking> },
  id: String
});
// Vuex store
const store = useStore();

const inspectorlist = computed(() => store.getters['diary/inspectorlist']);
const _currentdate = computed(() => store.getters['diary/currentdate']);

// Reactive data
const recursionConfirmModal = ref(null);
const operation = ref("");
const personalbooking = ref(new Booking());
const bookingcopy = ref(new Booking());
const selectedinspector = ref(new Inspector());
const subjobdate = ref(new Date());
const inspectorinstringsearchfilter = ref('');
const copytoinspectorinstringsearchfilter = ref('');
const isSaving = ref(false);
const selectedinspectors = ref<Inspector[]>([]);

const repeatevery = ref(1);
const repeatfrequency = ref('week');
//https://trello.com/c/Ft26Xyjv/566-new-note-repeat-event-wording
const selectedfrequency = computed({
  set: (val: string) => {
    repeatfrequency.value = val.replaceAll('s', '');
  },
  get: () => {
    if (repeatevery.value > 1) {
      return `${repeatfrequency.value}s`;
    }
    else {
      return repeatfrequency.value;
    }
  }
})
//https://trello.com/c/Ft26Xyjv/566-new-note-repeat-event-wording
const repeatfrequencyoptions = computed(() => {
  var opts = ['day', 'week', 'month', 'year'];
  if (repeatevery.value > 1)
    opts = ['days', 'weeks', 'months', 'years'];
  return opts;
});
const repeaton = ref([false, false, false, false, false, false, false]);
const repeatonoptions = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
const ends = ref('Never');
const endsonoptions = ['Never', 'On', 'After'];
const threemonthsinfuture = moment().add(3, 'month').endOf('day').toDate();
const endson = ref(threemonthsinfuture);
const endsafteroccurancenumber = ref(13);
const allday = ref(false);
const sharewithall = ref(false);


const dynamicRefs = ref({});

// Injected property
const realtime: any = inject('realtime');
const actProperty: any = inject('actProperty');
const toasted = useToast();

const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

const emit = defineEmits(['hide', 'showAuditLogsModal'])

// Computed properties and methods
const inspectors = computed((): Inspector[] => store.getters['diary/inspectorlist']);

const addBooking = (booking: Booking): Promise<Booking> => {
  return store.dispatch('diary/addBooking', booking);
};

const shareBooking = (payload: { booking: Booking, idlist: string[] }): Promise<Booking> => {
  return store.dispatch('diary/shareBooking', payload);
};

const saveBooking = (booking: Booking): Promise<Booking> => {
  return store.dispatch('diary/saveBooking', booking);
};

const insertBooking = (booking: Booking) => {
  store.commit('diary/insertBooking', booking);
};

const removeBooking = (id: string) => {
  store.commit('diary/removeBooking', id);
};


const inspectorlistinstring = computed(() => {
  return inspectors.value
    .sort((i1, i2) => i1.name.toLowerCase().localeCompare(i2.name.toLowerCase()))
    .filter(i => !i.inactive && i.name.toLowerCase() != "default" && (!inspectorinstringsearchfilter.value || i.name.toLowerCase().startsWith(inspectorinstringsearchfilter.value.toLowerCase())))
    .map(i => i.name);
});

// Converted methods (demonstrating a few)
const init = () => {
  personalbooking.value = new Booking();
  title.value = '';
  location.value = '';
  description.value = '';
  personalbooking.value.subbookings = [];
  selectedinspector.value = new Inspector();
  selectedinspectors.value = [];
  isSaving.value = false;
};

const add = () => {
  operation.value = "add";
  sharewithall.value = false;
  allday.value = false;
  personalbooking.value.allDay = false;
  personalbooking.value.startdate = moment().utc().format(bookingdateformat);
  personalbooking.value.enddate = moment().utc().format(bookingdateformat);
  personalbooking.value.auditlogs = [];
  personalbooking.value.subtype = Booking.PERSONAL;
  subjobdate.value = _currentdate.value;
  repeat.value = personalbooking.value.repeat;
  parserrule(personalbooking.value.recurrenceRule);
};

const addwith = (bookingData: Booking) => {
  operation.value = "add";
  allday.value = false;
  sharewithall.value = false;
  personalbooking.value = new Booking();
  personalbooking.value = new Booking(bookingData);
  personalbooking.value.subbookings = [];
  selectedinspector.value = new Inspector();
  personalbooking.value.subtype = Booking.PERSONAL;
  subjobdate.value = _currentdate.value;
  selectedinspector.value = bookingData.inspector;
  repeat.value = personalbooking.value.repeat;
  parserrule(personalbooking.value.recurrenceRule);
  isSaving.value = false;
};

const edit = () => {
  operation.value = "edit";
  sharewithall.value = false;
  personalbooking.value = new Booking(props.booking);
  bookingcopy.value = new Booking(props.booking);
  allday.value = personalbooking.value.allDay;
  subjobdate.value = personalbooking.value.startdateAsDate;
  selectedinspector.value = personalbooking.value.inspector;
  repeat.value = personalbooking.value.repeat;
  parserrule(personalbooking.value.recurrenceRule);
}


const restore = async () => {
  try {
    await store.dispatch('diary/restoreBooking', props.booking);
    toasted.success("Booking restored");
    emitEvent("appointmentRestoredInBookingTemplate", props.booking);
  } catch (err: any) {
    actProperty.displayError(err);
  }
};

// Other methods would be converted similarly...

onMounted(async () => {
  Promise.all([setStartTimeOptions(), setEndTimeOptions()])
    .then((values) => { })
    .catch((err) => {
      actProperty.displayError(err);
    });
});

const starttimeoptions = ref<string[]>([]);
const endtimeoptions = ref<string[]>([]);
const appointmenttimeoptions = ref<string[]>([]);
const setStartTimeOptions = () => {
  starttimeoptions.value = [];
  let startmoment = moment().set("hour", 6).set("minute", 0);
  for (let i = 0; i < 62; i++) {
    const hourMinutes = startmoment.format("hh:mm A");
    starttimeoptions.value.push(hourMinutes);
    appointmenttimeoptions.value.push(hourMinutes);
    startmoment.add(15, "minutes");
  }
}

const setEndTimeOptions = () => {
  endtimeoptions.value = [];
  let startmoment = moment().set("hour", 6).set("minute", 0);
  if (_startdate.value) {
    startmoment = moment(_startdate.value, "hh:mm A");
    startmoment.add(15, "minutes");
  }
  for (let i = 0; i < 62; i++) {
    const hourMinutes = startmoment.format("hh:mm A");
    endtimeoptions.value.push(hourMinutes);
    startmoment.add(15, "minutes");
    if (hourMinutes === "09:00 PM ") break;
  }
}

const _startdate = computed({
  get: () => {
    let value = personalbooking.value.startdate
      ? moment(personalbooking.value.startdate).utc().format("hh:mm A")
      : "";
    const index = starttimeoptions.value.findIndex(
      (option: string) => option === value
    );
    if (index < 0) value = "";
    return value;
  },
  set: (val: string) => {
    personalbooking.value.startdate = subjobendDate(
      val,
      personalbooking.value.startdateAsDate
    );
    determinEnddate();
    determineAppointmenttime();
  }
});

const _enddate = computed({
  get: () => {
    let value = personalbooking.value.enddate
      ? moment(personalbooking.value.enddate).utc().format("hh:mm A")
      : "";
    const index = endtimeoptions.value.findIndex(
      (option: string) => option === value
    );
    if (index < 0) value = "";
    return value;
  },
  set: (val: string) => {
    personalbooking.value.enddate = subjobendDate(
      val,
      personalbooking.value.startdateAsDate
    );
    let duration = personalbooking.value.duration;
    if (duration === personalbooking.value.recommendedtime) duration = 0;
    personalbooking.value.preferredduration = duration;
    determineAppointmenttime();
  }
});

watch(() => _startdate.value, (newValue) => {
  setEndTimeOptions();
});

const inspector = computed({
  get: () => {
    const insp = inspectors.value.find(i => i.id === selectedinspector.value.id);
    return insp?.name ?? '';
  },
  set: (val: string) => {
    if (val) {
      const insp = inspectors.value.find(i => i.name === val);
      if (insp) {
        selectedinspector.value = insp;
      }
    }
  }
});

const dropDownOpened = (ref: string) => {
  nextTick(() => {
    // Get the reference to the dropdown list container
    const multiselect = dynamicRefs.value[ref] as any;
    const dropdownList = multiselect.$el.querySelector('.multiselect__content-wrapper');

    // Get the reference to the selected option element
    const selectedOption = multiselect.$el.querySelector('.multiselect__option--selected');

    // Scroll to the position of the selected element
    if (selectedOption?.offsetTop)
      dropdownList.scrollTop = selectedOption.offsetTop;
  });
}

const resetInspector = () => {
  selectedinspector.value = new Inspector();
};

const subjobendDate = (time: string, date: Date) => {
  let value = "";
  if (time) {
    let dt: Date = date ? date : _currentdate.value;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
}

const determinEnddate = () => {
  if (personalbooking.value.startdate && personalbooking.value.recommendedtime) {
    let time =
      props.booking.preferredduration > 0
        ? props.booking.preferredduration
        : personalbooking.value.recommendedtime;
    personalbooking.value.enddate = subjobendDate(
      moment(personalbooking.value.startdate)
        .utc()
        .add(time, "minutes")
        .format("hh:mm A"),
      personalbooking.value.startdateAsDate
    );
  }
}

const determineAppointmenttime = () => {
  let appointmenttime = "";
  let timeinminutes = 0;
  if (props.booking.preferredappointmenttime > 0)
    timeinminutes = props.booking.preferredappointmenttime;
  else if (personalbooking.value.recommendedappointmenttime > 0)
    timeinminutes = personalbooking.value.recommendedappointmenttime;

  // Round it to the nearest 15 minutes interval
  timeinminutes = Math.round(timeinminutes / 15) * 15;
  if (personalbooking.value.enddate && timeinminutes > 0) {
    appointmenttime = moment(personalbooking.value.enddate)
      .utc()
      .subtract(timeinminutes, "minutes")
      .format("hh:mm A");
  } else if (personalbooking.value.startdate) {
    appointmenttime = moment(personalbooking.value.startdate)
      .utc()
      .format("hh:mm A");
  }
  personalbooking.value.appointmenttime = subjobendDate(
    appointmenttime,
    personalbooking.value.startdateAsDate
  );
}



const setDescription = (val: string) => {
  personalbooking.value.description = val;
}

const description = computed({
  get: () => {
    return personalbooking.value.description;
    //if (personalbooking.value.pinotes)
    //  val = val ? `${val}\n${personalbooking.value.pinotes}` : personalbooking.value.pinotes;
    //return val;
  },
  set: (val: string) => {
    personalbooking.value.description = val;
  }
});

const title = computed({
  get: () => personalbooking.value.summary,
  set: (val: string) => {
    personalbooking.value.summary = val;
  }
});

const location = computed({
  get: () => personalbooking.value.location,
  set: (val: string) => {
    personalbooking.value.location = val;
  }
});

const repeat = computed({
  get: () => personalbooking.value.repeat,
  set: (val: string) => {
    personalbooking.value.repeat = val;
    if(val === 'Custom') {
      ends.value = '';
      repeatevery.value = 1;
      if (repeatevery.value > 1) {
        selectedfrequency.value =  `${repeatfrequency.value}s`;
      }
      else {
        selectedfrequency.value = repeatfrequency.value;
      }
      endson.value = threemonthsinfuture;
      endsafteroccurancenumber.value = 13;
      repeaton.value = [false,false,false,false,false,false,false]
    }
  }
});

const repeatoptions = computed((): string[] => {
  const options = [];
  options.push(`Doesn't repeat`);
  const sdmoment = moment(subjobdate.value)
  const weekday = sdmoment.format('dddd');
  const monthday = sdmoment.format('MMMM D');
  options.push(`Weekly on ${weekday}`);
  options.push(`Annually on ${monthday}`);
  options.push(`Custom`);
  return options;
})

const savingAllowed = computed(() => {
  let result = true;
  if (!selectedinspector.value || !selectedinspector.value.id || !subjobdate.value || !title.value)
    result = false;
  if (!allday.value && (!_startdate.value || !_enddate.value))
    result = false;
  return result;
});

const save = async () => {
  hide();
  if (operation.value === "edit") {
    if (personalbooking.value.repeat !== "Doesn't repeat" && personalbooking.value.recurrenceRule) {
      const modal = recursionConfirmModal.value as any;
      modal.init(personalbooking.value);
      modal.edit();
      modal.show();
    } else {
      selectEditNoteModal('blank', personalbooking.value);
    }
  } else {
    selectEditNoteModal('blank', personalbooking.value);
  }
};

const selectEditNoteModal = async (val: any, book: Booking) => {
  if (selectedinspector.value.id) {
    isSaving.value = true;
    personalbooking.value.recurrenceRule = formatrrule();
    personalbooking.value.jobtype = Booking.PERSONAL;
    personalbooking.value.subtype = Booking.PERSONAL;
    personalbooking.value.allDay = allday.value;
    if (operation.value === "add") {
      personalbooking.value.id = "";
      personalbooking.value.inspector = selectedinspector.value;
      if (personalbooking.value.allDay) {
        const startTime = "T09:00:00.000Z";
        const endTime = "T18:00:00.000Z";
        personalbooking.value.startdate = subjobendDate(
          startTime,
          subjobdate.value
        );
        personalbooking.value.appointmenttime = subjobendDate(
          startTime,
          subjobdate.value
        );
        personalbooking.value.enddate = subjobendDate(
          endTime,
          subjobdate.value
        );
      } else {
        personalbooking.value.startdate = subjobendDate(
          personalbooking.value.starttime,
          subjobdate.value
        );
        personalbooking.value.enddate = subjobendDate(
          personalbooking.value.endtime,
          subjobdate.value
        );
      }

      addBooking(personalbooking.value)
        .then(async (b: Booking) => {
          channel.publish('newAppointmentAdded', { deviceid: deviceid, bookingid: b.id });
          return b;
        })
        .then((newbooking: Booking) => {
          var sharelist: Inspector[] = [];
          if (sharewithall.value) {
            sharelist = [...inspectors.value];
          }
          else if (selectedinspectors.value?.length) {
            sharelist = [...selectedinspectors.value];
          }
          const idlist: string[] = sharelist.filter((i) => i.id != personalbooking.value.inspector.id).map((i) => i.id);
          if (idlist.length) {
            shareBooking({ booking: personalbooking.value, idlist })
              .then((bookingidlist) => {
                channel.publish('newAppointmentsAdded', { deviceid: deviceid, bookingid: newbooking.id, bookingidlist: bookingidlist });
              });
          }

        })
        .catch((err: any) => {
          isSaving.value = false;
          actProperty.displayError(err);
        });
    } else {
      hiderecusionmodel();
      personalbooking.value.inspector = selectedinspector.value;
      if (personalbooking.value.allDay) {
        const startTime = "T09:00:00.000Z";
        const endTime = "T18:00:00.000Z";
        personalbooking.value.startdate = subjobendDate(
          startTime,
          subjobdate.value
        );
        personalbooking.value.appointmenttime = subjobendDate(
          startTime,
          subjobdate.value
        );
        personalbooking.value.enddate = subjobendDate(
          endTime,
          subjobdate.value
        );
      } else {
        personalbooking.value.startdate = subjobendDate(
          personalbooking.value.starttime,
          subjobdate.value
        );
        personalbooking.value.enddate = subjobendDate(
          personalbooking.value.endtime,
          subjobdate.value
        );
      }
      if (props.booking.googleid) {
        personalbooking.value.description = description.value;
      }
      if (val === 'thisevent') {
        bookingcopy.value.addExclDate(_currentdate.value);
        saveBooking(bookingcopy.value)
          .then((savednpb: Booking) => {
            // Remove current booking from the list
            removeBooking(savednpb.id);
            // Now save the new changes as a single entry personal booking
            personalbooking.value.id = '';
            personalbooking.value.startdate = actProperty.prependDate(
              moment(personalbooking.value.startdate).utc().format("hh:mm A"),
              _currentdate.value,
              _currentdate.value
            );
            personalbooking.value.enddate = actProperty.prependDate(
              moment(personalbooking.value.enddate).utc().format("hh:mm A"),
              _currentdate.value,
              _currentdate.value
            );
            personalbooking.value.repeat = `Doesn't repeat`;
            personalbooking.value.recurrenceRule = '';
            personalbooking.value.recurrenceExclDates = '';
            addBooking(personalbooking.value)
              .then((newbooking) => {
                insertBooking(newbooking);
                toasted.success("Booking saved");
              });
          }).catch((err: any) => {
            actProperty.displayError(err);
          });
      } else if (val === 'thisandfollowingevent') {
        const datebeforecurrentdate = moment(_currentdate.value).utc().subtract(1, 'day').endOf('day').toDate();
        bookingcopy.value.setRecurrenceEnddate(datebeforecurrentdate);
        saveBooking(bookingcopy.value)
          .then((savednpb: Booking) => {
            // Reaload current booking
            removeBooking(savednpb.id);
            // Now save the new changes as a single entry personal booking
            personalbooking.value.id = '';
            personalbooking.value.startdate = actProperty.prependDate(
              moment(personalbooking.value.startdate).utc().format("hh:mm A"),
              _currentdate.value,
              _currentdate.value
            );
            personalbooking.value.enddate = actProperty.prependDate(
              moment(personalbooking.value.enddate).utc().format("hh:mm A"),
              _currentdate.value,
              _currentdate.value
            );
            addBooking(personalbooking.value)
              .then((newbooking) => {
                insertBooking(newbooking);
                toasted.success("Booking saved");
              });
          });
      } else {
        await saveBooking(personalbooking.value)
          .then(() => {
            isSaving.value = false;
            //if (val != 'allevents') {
            channel.publish('appointmentChanged', { deviceid: deviceid, bookingid: personalbooking.value.id, source: 'Scheduler' });
            //}
          })
          .then(() => {
            //https://trello.com/c/jNVjqmVi/564-new-note-booking
            if (selectedinspectors.value?.length) {
              selectedinspectors.value.forEach((i: Inspector) => {
                const copybooking = new Booking(personalbooking.value);
                copybooking.inspector = i;
                addBooking(copybooking).
                  then((cb: Booking) => {
                    hiderecusionmodel();
                    channel.publish('newAppointmentAdded', { deviceid: deviceid, bookingid: cb.id });
                  })
              })
            }
          })
          .catch((err: any) => {
            hiderecusionmodel();
            isSaving.value = false;
            actProperty.displayError(err);
          });
      }
    }
  }
}

const hiderecusionmodel = () => {
  const modal = recursionConfirmModal.value as any;
  modal.hide();
}

const formatrrule = () => {
  const rruleSet = new RRuleSet()
  const sdmoment = moment(subjobdate.value)
  const weekday = sdmoment.format('dddd');
  var weekd = RRule.MO;
  switch (weekday) {
    case 'Monday': weekd = RRule.MO; break;
    case 'Tuesday': weekd = RRule.TU; break;
    case 'Wednesday': weekd = RRule.WE; break;
    case 'Thursday': weekd = RRule.TH; break;
    case 'Friday': weekd = RRule.FR; break;
    case 'Saturday': weekd = RRule.SA; break;
    case 'Sunday': weekd = RRule.SU; break;
  }
  if (repeat?.value === '' || repeat.value === `Doesn't repeat`) {
    return '';
  }
  else if (repeat.value.startsWith(`Weekly`)) {
    const rrule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: weekd,
      dtstart: subjobdate.value,
    });
    rruleSet.rrule(rrule);
    return rruleSet.toString();
  }
  else if (repeat.value.startsWith(`Annually`)) {
    const rrule = new RRule({
      freq: RRule.YEARLY,
      interval: 1,
      dtstart: subjobdate.value,
    });
    rruleSet.rrule(rrule);
    return rruleSet.toString();
  }

  var frequency = RRule.DAILY;
  if (repeatfrequency.value === 'week') frequency = RRule.WEEKLY;
  else if (repeatfrequency.value === 'month') frequency = RRule.MONTHLY;
  else if (repeatfrequency.value === 'year') frequency = RRule.YEARLY;
  var byweekday = [];
  for (var i = 0; i < repeaton.value.length; i++) {
    if (repeaton.value[i]) {
      switch (i) {
        case 0: byweekday.push(RRule.MO); break;
        case 1: byweekday.push(RRule.TU); break;
        case 2: byweekday.push(RRule.WE); break;
        case 3: byweekday.push(RRule.TH); break;
        case 4: byweekday.push(RRule.FR); break;
        case 5: byweekday.push(RRule.SA); break;
        case 6: byweekday.push(RRule.SU); break;
      }
    }
  }

  const options = {
    freq: frequency,
    interval: repeatevery.value,
    byweekday: byweekday,
    dtstart: subjobdate.value,
  }
  if (ends.value === 'After') {
    options['count'] = endsafteroccurancenumber.value;
  }
  else if (ends.value === 'On') {
    options['until'] = endson.value;
  }

  rruleSet.rrule(new RRule(options));
  return extractRRule(rruleSet.toString());
}

const extractRRule = (val: string) => {
  let rruleOnly = val;
  rruleOnly = val
    .split("\n")
    .find((line) => line.startsWith("RRULE"));
  return rruleOnly;
}

const parserrule = (val: string) => {
  if (!val) return;
  const options = RRuleSet.parseString(val);

  repeatevery.value = options.interval;
  switch (options.freq) {
    case RRule.DAILY: repeatfrequency.value = 'day'; break;
    case RRule.WEEKLY: repeatfrequency.value = 'week'; break;
    case RRule.MONTHLY: repeatfrequency.value = 'month'; break;
  }

  repeaton.value = [false, false, false, false, false, false, false];
  if (options.byweekday && Array.isArray(options.byweekday)) {
    for (var i = 0; i < options.byweekday.length; i++) {
      const bwd = options.byweekday[i];
      switch (bwd) {
        case RRule.MO: repeaton.value[0] = true; break;
        case RRule.TU: repeaton.value[1] = true; break;
        case RRule.WE: repeaton.value[2] = true; break;
        case RRule.TH: repeaton.value[3] = true; break;
        case RRule.FR: repeaton.value[4] = true; break;
        case RRule.SA: repeaton.value[5] = true; break;
        case RRule.SU: repeaton.value[6] = true; break;
      }
    }
  }

  if (options.count > 0) {
    ends.value = 'After';
    endsafteroccurancenumber.value = options.count;
  }
  else if (options.until) {
    ends.value = 'On';
    endson.value = options.until;
  }

  if (!repeat.value) {
    repeat.value = 'Custom';
  }
}

const filteredsortedinspectorlist = computed(() => {
  return inspectorlist.value
    .filter((i: Inspector) => i.name.toLowerCase() != "default" && i.name != inspector.value)
    .sort((insp1: Inspector, insp2: Inspector) => {
      let val: number = insp1.region.localeCompare(insp2.region);
      if (val === 0) val = insp1.name.localeCompare(insp2.name);
      return val;
    });
});

const inspectoroptions = computed((): Inspector[] => {
  filteredsortedinspectorlist.value
    .sort((i1, i2) => i1.name.toLowerCase().localeCompare(i2.name.toLowerCase()))
    .filter(i => i.name.toLowerCase() != "default" && (!copytoinspectorinstringsearchfilter.value || i.name.toLowerCase().startsWith(copytoinspectorinstringsearchfilter.value.toLowerCase())))
    .map(i => i.name);
  return [...filteredsortedinspectorlist.value];
});


const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};


const isNumber = (event: any) => {
  const keyCode = event.keyCode || event.which;
  const inputChar = String.fromCharCode(keyCode);
  if (!/^[1-9]\d*$/.test(event.target.value + inputChar)) {
    event.preventDefault();
  }
}

const customLabel = (option: string) => {
  return option.replace(/^0/, '');
}

const showAuditLogsModal = () => {
  emit("showAuditLogsModal", personalbooking.value);
}

defineExpose({ init, add, addwith, edit, show, hide })
</script>


<style scoped lang="scss">
.text-yellow {
  color: #e9db4b;
}

.no-padding ::v-deep .multiselect__option {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.booking-section ::v-deep .multiselect__option--highlight {
  color: #35495e;
  background: #f8f9fa;
}

.booking-section ::v-deep .multiselect__option--selected {
  color: #35495e;
  background: #41b883;
}

.alphabet-button {
  margin: 2px;
  border-radius: 50%;
}

.description-textarea {
  //resize: none;
}
</style>