<template>
  <div>
    <form>
      <fieldset>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Name</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model="inspector.name"
              @change="editCurrentInspector()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Type</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              maxlength="8"
              v-model="inspector.itype"
              @change="editCurrentInspector()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Mobile</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model="inspector.mobile"
              @change="editCurrentInspector()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model="inspector.email"
              @change="editCurrentInspector()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Google calendar email</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model="inspector.googlecalendaremail"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Address</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model.lazy="inspector.address.line1"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label"
            ><span class="sr-only">Address Line 2</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model.lazy="inspector.address.line2"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">City</label>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              v-model.lazy="inspector.address.town"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">County</label>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              v-model.lazy="inspector.address.county"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Postcode</label>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              v-model.lazy="inspector.address.postcode"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Mode of transport</label>
          <div class="col-sm-2">
            <singleselect-text
              v-model="inspector.transport"
              :value="inspector.transport"
              :options="transportmodes"
              @select="editCurrentInspector()"
            >
            </singleselect-text>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Car registration</label>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              v-model.lazy="inspector.carreg"
              @change="editCurrentInspector()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Region</label>
          <div class="col-sm-2">
            <singleselect-text
              v-model="inspector.region"
              :value="inspector.region"
              :options="regionlist"
              @select="editCurrentInspector()"
            >
            </singleselect-text>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Active</label>
          <div class="col-sm-2">
            <DxSwitch v-model="active" @value-changed="active = $event.value" />
          </div>
        </div>

        <!--div class="form-group row">
          <label class="col-sm-3 col-form-label">Appointment colour</label>
          <div class="col-sm-1">
            <input-color-picker
              class="color-picker"
              v-model="inspector.appointmentcolour"
              @change="editCurrentInspector()"
            />
          </div>
        </div-->

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Picture</label>
          <div class="col-sm-9">
            <Logos
              :key="`row${inspector._uuid}${getPicture.length}`"
              :photos="getPicture"
              :path="'picture'"
              :target="'inspector'"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Signature</label>
          <div class="col-sm-9">
            <button class="btn btn-secondary" @click.prevent="pasteSignature()">
              Paste  <i class="fas fa-1x fas fa-paste"></i>
             </button>
             <br/>
            <img
              v-if="inspector.signature"
              :src="`data:image/jpeg;base64,${inspector.signature}`"
              class="image"
            />            
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script lang="ts" setup>
import _ from "lodash";
import _get from "lodash/get";
import { ref, computed } from "vue";
import Logos from "@/components/logo/Logos.vue";
import { Inspector } from "@/models";
import { useStore } from "vuex";
import { DxSwitch } from "devextreme-vue/switch";
import { useToast } from "vue-toastification";
const toasted = useToast();
const store = useStore();
const transportmodes = ref<string[]>(["Bike", "Car", "Train", "Walking"]);
const inspector = computed(() => store.getters["inspectors/current"]);
const editInspector = async (inspector: Inspector) =>
  await store.dispatch("inspectors/editInspector", inspector);
const regionlist = ref<string[]>([
  "Region 1",
  "Region 2",
  "Region 3",
  "Region 4",
  "Region 5",
  "Region 6",
  "Region 7",
  "Region 8",
  "Region 9",
]);
const setInspectorDeep = (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return store.dispatch("inspectors/setInspectorDeep", payload);
};

const editCurrentInspector = () => {
  editInspector(inspector.value);
};

const active = computed({
  get: () => !inspector.value.inactive,
  set: (data) => {
    let val: boolean = data === true ? false : true;
    setInspectorDeep({ path: "inactive", data: val });
  },
});

const getPicture = computed(() => {
  let photos: string[] = [];
  return _get(inspector.value, "picture", photos);
});


const isBase64 = (base64String:string) => {
  // Create an image object
  const img = new Image();
  
  // Set the source to the base64 string
  img.src = `data:image/jpeg;base64,${base64String}`;
  
  // Check if the image loaded successfully
  return img.complete && img.naturalWidth !== 0;
}

const pasteSignature = async () => {
  const text = await navigator.clipboard.readText();    
  if(text && isBase64(text)) {
    setInspectorDeep({ path: "signature", data: text });
    toasted.success("Signature pasted");
  } else {
    toasted.error('Format of the signature is not proper or blank')
  }
}
</script>

<style scoped lang="scss">
.image {
  height: auto;
  max-width: 20%;
  padding: 1em 1em 0;
  width: 20%;
}
</style>
