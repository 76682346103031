<template>
  <div class="row main-container">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12 filter-pane ml-1">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Date</label>
                <div class="col-sm-9">
                  <singleselect-text
                    v-model="datefiltertype"
                    :options="datefiltertypeoptions"
                    :allow-empty="false"
                    :taggable="false"
                  >
                    <template #clear v-if="datefiltertype">
                      <i
                        @mousedown.prevent.stop="clearFilterType"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Date Filter"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="datefiltertype === 'Date range'">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label" for="fromdate"
                  >From</label
                >
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-6">
                      <DxDateBox
                        v-model="fromdate"
                        display-format="dd/MM/yyyy"
                        type="date"
                        picker-type="calendar"
                        apply-value-mode="instantly"
                        :calendarOptions="{ firstDayOfWeek: 1 }"
                        @value-changed="ensureToDateIsBeforeFromDate"
                      />
                    </div>

                    <div class="col-sm-6">
                      <DxDateBox
                        v-model="todate"
                        display-format="dd/MM/yyyy"
                        type="date"
                        picker-type="calendar"
                        apply-value-mode="instantly"
                        :calendarOptions="{ firstDayOfWeek: 1 }"
                        @value-changed="ensureFromDateIsAfterToDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div
                  class="col-sm-12 mb-3"
                  v-for="(data, $index) in presentErrors"
                  :key="$index"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <button
                    class="btn btn-outline-secondary presetbtn"
                    @click="managePresentErrors($index)"
                    :style="{
                      'background-color': '#253746',
                      color: 'white',
                    }"
                  >
                    {{ data.message
                    }}<i
                      v-if="data.flag"
                      class="fas fa-2x fa-check-circle text-success ml-auto float-right"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div
                  class="col-sm-12 mb-3"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <button
                    class="btn btn-outline-secondary presetbtn"
                    @click="keyreturntbcfn()"
                    :style="{
                      'background-color': '#253746',
                      color: 'white',
                    }"
                  >
                    Key return TBC<i
                      v-if="keyreturntbc"
                      class="fas fa-2x fa-check-circle text-success ml-auto float-right"
                    ></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-sm-12 mr-1 mb-3" style="padding-right: 30px">
              <div class="row" style="float: right; justify-content: center">
                <div class="ml-1">
                  <button class="btn btn-outline-secondary" @click="clearAll">
                    Clear All
                  </button>
                </div>
                <div class="ml-1">
                  <button class="btn btn-outline-secondary" @click="selectAll">
                    Select All
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Search text</label>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control height-43"
                    v-model.lazy="anytext"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Job type</label>
                <div class="col-sm-6">
                  <singleselect-text
                    v-model="jobtype"
                    :options="jobtypeoptions"
                    :custom-label="customLabelForReportType"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="jobtype">
                      <i
                        @mousedown.prevent.stop="clearJobtype"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Job type Filter"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="jobtype != null && jobtype.slug">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Internal job type</label>
                <div class="col-sm-6">
                  <singleselect-text
                    v-model="internaljobtype"
                    :options="reorderedinternaljobtypelist"
                    :custom-label="customLabelForInternaljobtype"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="jobtype">
                      <i
                        @mousedown.prevent.stop="internaljobtype = ''"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Internal Job type Filter"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Client</label>
                <div class="col-sm-6">
                  <singleselect-text
                    v-model="companyname"
                    :options="companyNameOptions"
                    :allow-empty="true"
                    :taggable="false"
                    @select="setBranchnameOptions"
                  >
                    <template
                      #clear
                      v-if="companyname != null && companyname.length"
                    >
                      <i
                        @mousedown.prevent.stop="clearCompanyname"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Company Name"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="companyname">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Branch</label>
                <div class="col-sm-6">
                  <singleselect-text
                    v-model="branchname"
                    :options="branchNameOptions"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template
                      #clear
                      v-if="branchname != null && branchname.length"
                    >
                      <i
                        @mousedown.prevent.stop="clearBranchname"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Branch Name"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Who</label>
                <div class="col-sm-6">
                  <singleselect-text
                    v-model="inspector"
                    track-by="slug"
                    label="name"
                    :options="inspectorlist"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="inspector && inspector.slug">
                      <i
                        @mousedown.prevent.stop="clearInspector"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Who"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Access</label>
                <div class="col-sm-6">
                  <singleselect-text
                    v-model="access"
                    :options="accessOptions"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="access">
                      <i
                        @mousedown.prevent.stop="access = ''"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Access"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Access confrimed</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <singleselect-text
                    v-model="accessconfirmed"
                    :options="accessconfirmedOptions"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="accessconfirmed">
                      <i
                        @mousedown.prevent.stop="accessconfirmed = ''"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Access confirmed"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Flexi Appointments</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <singleselect-text
                    v-model="flexiappoitment"
                    :options="flexiappoitmentslist"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="flexiappoitment">
                      <i
                        @mousedown.prevent.stop="flexiappoitment = ''"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Flexi Appointments"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Predicted travel time exceeds/falls short of scheduled
                  allowance</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <singleselect-text
                    v-model="predictedtraveltime"
                    :options="predictedtraveltimeoptions"
                    :allow-empty="true"
                    :taggable="false"
                  >
                    <template #clear v-if="predictedtraveltime">
                      <i
                        @mousedown.prevent.stop="predictedtraveltime = ''"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Predicted travel time exceeds scheduled allowance"
                      ></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Tenant attending</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <tri-state-toggle
                    :value="tenantattending"
                    @select="tenantAttendingSelect"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing tenant name</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingtenantname"
                    @value-changed="missingtenantname = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing tenant mobile</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingtenantmobile"
                    @value-changed="missingtenantmobile = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing tenant email</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingtenantemail"
                    @value-changed="missingtenantemail = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing landlord mobile</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingllmobile"
                    @value-changed="missingllmobile = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="isuploadorlinktypejob">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing Uploads or linked report reference</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missinguploadorreportref"
                    @value-changed="missinguploadorreportref = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Check-In/Check-Out time conflicting with schedule</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="cicotimeconflict"
                    @value-changed="cicotimeconflict = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Provisional</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="provisional"
                    @value-changed="provisional = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">In absence</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="inabsence"
                    @value-changed="inabsence = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Deleted</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="cancelled"
                    @value-changed="cancelled = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing landlord name</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingllname"
                    @value-changed="missingllname = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing landlord email</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingllemail"
                    @value-changed="missingllemail = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Missing works/purchase order number</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="missingworksorder"
                    @value-changed="missingworksorder = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Not paid</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="notpaid"
                    @value-changed="notpaid = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Key release unconfirmed</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="keyreleaseunconfirmed"
                    @value-changed="keyreleaseunconfirmed = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Key return TBC</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch v-model="keyreturntbc" @value-changed="keyreturntbc = $event.value" />
                </div>
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Smoke and CO alarm form at Inv/CI</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="smokecoalarmrequired"
                    @value-changed="smokecoalarmrequired = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label"
                  >Summary email following CO</label
                >
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="summaryemailafterco"
                    @value-changed="summaryemailafterco = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group row">
                <label class="col-sm-6 col-form-label">Not issued</label>
                <div class="col-sm-6 mt-2 mb-2">
                  <DxSwitch
                    v-model="notissued"
                    @value-changed="notissued = $event.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <h3>SMS/Email responses</h3>
      <UnreadSmsEmailLogs
        :results="sortedunreadsmslogsresults"
        :todaysunreadsmslogsresults="todaysunreadsmslogsresults"
        :daterangeunreadsmslogsresults="daterangeunreadsmslogsresults"
        :futureunreadsmslogsresults="futureunreadsmslogsresults"
        @smslogsselected="updateManageFilter"
        @removesmsbookingfromlocal="removesmsbookingfromlocal"
      />
    </div>

    <div class="col-md-4" ref="managementResults">
      <h3>
        Bookings List
        <span v-if="sortedresults.length">- {{ sortedresults.length }}</span>
      </h3>
      <div class="row" v-if="sortedresults.length">
        <div class="col-sm-12">
          <div class="form-group row">
            <div class="col-sm-7"></div>
            <div class="col-sm-1">
              <label class="col-sm-2 col-form-label">Sort</label>
            </div>
            <div class="col-sm-4">
              <singleselect-text
                v-model="filterbookingtype"
                :options="filterbookingtypeoptions"
                :allow-empty="true"
                :taggable="false"
                @select="setBookingType"
              >
                <template #clear v-if="filterbookingtype">
                  <i
                    @mousedown.prevent.stop="clearFilterBookingType()"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Filter Booking"
                  ></i>
                </template>
              </singleselect-text>
            </div>
          </div>
        </div>
      </div>
      <ManagementResults
        :futurebookingid="futureBookingId"
        :results="sortedresults"
        @selectgooglebooking="showGoogleEventModal"
        @selectbooking="updateManageFilter"
        @removebookingfromlocal="removebookingfromlocal"
      />
    </div>

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <div class="col-md-2 pl-0 pr-0">
          <div class="row">
            <div class="col-md-6 pl-0 pr-0"></div>

            <div class="col-md-6 pl-0 pr-0">
              <button
                class="btn btn-light"
                @click="search"
                v-on:click.stop
                style="margin-left: auto"
                :disabled="searchDisabled"
              >
                <i class="fas fa-search"></i> Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <draggable-modal
      :modal-id="'floorplan-viewer'"
      :document="floorplan[0]"
      :title="'Floor plan'"
    />
    <draggable-modal
      :modal-id="'rates-viewer'"
      :document="ratedocument"
      :title="'Rates'"
    />
    <draggable-modal
      :modal-id="'dataentrydocument-viewer'"
      :document="dataentrydocuments[0]"
      :title="'Dataentry PDF'"
    />

    <AuditlogsModal name="manageauditlogs-modal" ref="auditLogsModal" />

    <google-event-modal ref="googleeventmodal" :booking="googlebooking" />

    <BookingCancelModal ref="bookingCancelModal" name="cancel-booking-modal" />
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  computed,
  onMounted,
  watch,
  inject,
  defineEmits,
  nextTick,
  onBeforeUnmount, 
  onUnmounted
} from "vue";
import { useStore } from "vuex";
import Ably from "ably";
import { ModalsContainer, useVfm } from "vue-final-modal";
import moment from "moment-timezone";
import { checkBooking } from "@/bookingfilterpresets";

import { DxDateBox } from "devextreme-vue/date-box";
import DraggableModal from "@/components/modals/DraggableModal.vue";
import TriStateToggle from "@/components/TriStateToggle.vue";
import ManagementResults from "./ManagementResults.vue";
import UnreadSmsEmailLogs from "./managementfilters/UnreadSmsEmailLogs.vue";
import GoogleEventModal from "@/components/modals/GoogleEventModal.vue";
import AuditlogsModal from "@/components/modals/AuditlogsModal.vue";
import BookingCancelModal from "@/components/modals/BookingCancelModal.vue";
import { DxSwitch } from "devextreme-vue/switch";
import { useToast } from "vue-toastification";
const realtime: Ably.Realtime = inject("realtime");
const actProperty: any = inject("actProperty");
const channel = realtime.channels.get("diary");
const deviceid = actProperty.getDeviceId();
const toasted = useToast();
import UndoSaveToaster from "@/components/modals/UndoSaveToaster.vue";
import {
  Inspector,
  PropertySpec,
  Booking,
  DictionaryReportType,
  SelectOption,
  Document,
  Customer,
  PresetError,
  User,
} from "@/models";
const store = useStore();
const emit = defineEmits([
  "showpricelistdocument",
  "showworksorderdocument",
  "showpricelistdocument",
]);

const auditLogsModal = ref(null);
const googleeventmodal = ref(null);
const bookingCancelModal = ref(null);
const sortedunreadsmslogsresults = ref<Booking[]>([]);
const todaysunreadsmslogsresults = ref<Booking[]>([]);
const daterangeunreadsmslogsresults = ref<Booking[]>([]);
const futureunreadsmslogsresults = ref<Booking[]>([]);
const managementResults = ref(null);
// Filters
const datefiltertype = ref("");
const datefiltertypeoptions = ref([
  "Today",
  "Tomorrow",
  "Day after tomorrow",
  "Next 2 days",
  "Next 3 days",
  "Next 7 days",
  "Date range",
]);
const filterbookingtypeoptions = ref([
  "Client",
  "Time & Date",
  "Job type",
  "PI",
]);
const filterbookingtype = ref("Client");
const predictedtraveltime = ref("");
const predictedtraveltimeoptions = ref([
  "-40",
  "-30",
  "-20",
  "-10",
  "10",
  "20",
  "30",
  "40+",
]);
const flexiappoitment = ref("");
const flexiappoitmentslist = ref([
  "Flexi all day",
  "Flexi AM",
  "Flexi PM",
  "All",
]);
const paramsset = ref();
const fromdate = ref(new Date());
const todate = ref(new Date());
const anytext = ref("");
const jobtype = ref<DictionaryReportType>(new DictionaryReportType());
const internaljobtype = ref("");
const reorderedinternaljobtypelist = ref([]);
const companyname = ref("");
const branchname = ref("");
const companyNameOptions = ref([]);
const branchNameOptions = ref([]);
const presentErrors = ref([]);
const inspector = ref<SelectOption>(new SelectOption());
const access = ref("");
const accessOptions = ref([
  "From Agency",
  "Meet Tenant",
  "Via Concierge",
  "Via Landlord",
  "TBC",
  "Misc",
]);
const accessconfirmed = ref("");
const accessconfirmedOptions = ref(["Confirmed", "Not confirmed"]);
const tenantattending = ref("");
const missingtenantname = ref(false);
const missingtenantmobile = ref(false);
const missingtenantemail = ref(false);
const missinguploadorreportref = ref(false);
const uploadorlinkjobtypelist = ref([
  "Inv CI - off upload",
  "Inv CI - old report on file, charge CI rate",
  "Check-Out - off ACT report",
  "Check-Out - off upload",
  "Check-In - off ACT report",
  "Check-In - off upload",
]);
const cicotimeconflict = ref(false);
const inabsence = ref(false);
const provisional = ref(false);
const safetycleats = ref(false);
const summaryemailafterco = ref(false);
const tbc = ref(false);
const cancelled = ref(false);
const missingllname = ref(false);
const missingllmobile = ref(false);
const missingllemail = ref(false);
const missingworksorder = ref(false);
const notpaid = ref(false);
const keyreleaseunconfirmed = ref(false);
const keyreturntbc = ref(false);
const smokecoalarmrequired = ref(false);
const notissued = ref(false);
const originalbooking = ref();

const results = ref<Booking[]>([]);
const showresultlist = ref(false);
const futureBookingId = ref("");
const googlebooking = ref<Booking>(new Booking());

const selectedbooking = computed(
  () => store.getters["diary/booking"] as Booking
);
const propertyspec = computed(
  () => store.getters["diary/propertyspec"] as PropertySpec
);
const customer = computed(() => store.getters["diary/customer"] as Customer);
const reportTypes = computed(
  () =>
    store.getters["dictionary/reportTypes"] as
      | DictionaryReportType[]
      | undefined
);
const jobtypelist = computed(() => store.getters["diary/jobtypelist"]);
const jobtypemap = computed(() => store.getters["diary/jobtypemap"]);
const inspectors = computed(
  (): Inspector[] => store.getters["diary/inspectorlist"]
);
const internaljobtypedisplayname = computed(
  (): Map<string, string> => store.getters["diary/internaljobtypedisplayname"]
);
const customers = computed(() => store.getters["diary/customerlist"]);
const setBookingDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch("diary/setBookingDeep", payload);
};

const users = computed((): User[] => store.getters['usermanagement/users']);

const getCompanyNameOptions = async (managedtype?: string): Promise<any> => {
  return await store.dispatch("diary/getCompanyNameOptions", managedtype);
};
const setBooking = (booking: any) => {
  store.commit("diary/setBooking", booking);
};

const markSMSEmailAsRead = async (booking: Booking) => {
  return await store.dispatch("diary/markSMSEmailAsRead", booking);
};

const getBranchNameOptionsWithManagedtype = async (payload?: {
  company_name?: string;
  managedtype?: string;
}): Promise<any> => {
  return await store.dispatch(
    "diary/getBranchNameOptionsWithManagedtype",
    payload
  );
};

const managementBookingSearch = async (params: any): Promise<Booking[]> => {
  return await store.dispatch("diary/managementBookingSearch", params);
};
// Methods
const getUnreadSmsLogs = (params: any): Promise<Booking[]> => {
  return store.dispatch("diary/getUnreadSMSLogs", params);
};
const getUnreadEmailLogs = (params: any): Promise<Booking[]> => {
  return store.dispatch("diary/getUnreadEmailLogs", params);
};
const getBookingWithoutStoringInState = (payload: {
  id: string;
  cancelled: string;
}) => {
  return store.dispatch("diary/getBookingWithoutStoringInState", payload);
};
const floorplan = computed(() => propertyspec.value.floorplan);
const dataentrydocuments = computed(
  () => selectedbooking.value.dataentrydocuments
);
const ratedocument = computed(() => {
  if (
    customer.value &&
    customer.value.pricelist &&
    customer.value.pricelist.length
  )
    return customer.value.pricelist[0];
  else return new Document();
});
const ensureFromDateIsAfterToDate = () => {
  if (todate.value) {
    if (
      fromdate.value &&
      moment(fromdate.value).utc().isAfter(moment(todate.value).utc())
    ) {
      fromdate.value = todate.value;
    }
  }
};

const ensureToDateIsBeforeFromDate = () => {
  if (fromdate.value) {
    if (
      todate.value &&
      moment(todate.value).utc().isBefore(moment(fromdate.value).utc())
    ) {
      todate.value = fromdate.value;
    }
  }
};

const jobtypeoptions = computed(() => {
  return reportTypes.value.filter(
    (t: DictionaryReportType) => jobtypelist.value.indexOf(t.slug) >= 0
  );
});
const customLabelForReportType = (type: DictionaryReportType) => {
  return type == null ? DictionaryReportType : type.displayname;
};

watch(
  () => jobtype.value,
  (newValue: any, oldValue: any) => {
    if (
      !oldValue ||
      (newValue != null && newValue && newValue.slug != oldValue.slug)
    ) {
      if (jobtype.value && jobtype.value.slug) {
        let templist = jobtypemap.value.get(jobtype.value?.slug);
        if (templist) reorderedinternaljobtypelist.value = templist;
        if (
          reorderedinternaljobtypelist.value &&
          reorderedinternaljobtypelist.value.length == 1
        ) {
          internaljobtype.value = reorderedinternaljobtypelist.value[0];
        }
        let index = reorderedinternaljobtypelist.value.indexOf(
          internaljobtype.value
        );
        if (index < 0) internaljobtype.value = "";
      }
    }
  }
);
const companyNameList = async () => {
  await getCompanyNameOptions().then((options: any) => {
    companyNameOptions.value = options;
  })
  .then(() => { companyNameOptions.value = companyNameOptions.value.filter((o: string) => o.trim().length)})
  .then(() => {
    // Add profiles on the top
    if(users.value?.length) {
      const portfoliooptions = users.value.filter((u: User) => u.customers?.length)
        .map((u: User) => `CRM Portfolio ${u.name}`).sort();
        companyNameOptions.value = [...portfoliooptions, ...companyNameOptions.value]
    }
  });
};

const setBranchnameOptions = (val: string) => {
  getBranchNameOptionsWithManagedtype({
    company_name: companyname.value,
  }).then((options: any) => (branchNameOptions.value = options));
};

const inspectorlist = computed((): SelectOption[] => {
  return inspectors.value.map(
    (i) => new SelectOption({ name: i.name, slug: i.id })
  );
});
const getAllCustomers = (): Promise<any> => {
  return store.dispatch("diary/getAllCustomers");
};

const logsReletedFunctions = async (params: any) => {
  sortedunreadsmslogsresults.value = [];
  await getUnreadSmsLogs({}).then((bookings: Booking[]) => {
    const smsBookingArray: Booking[] = bookings;
    // bookings.forEach((f: Booking) => {
    //   const smsfilterLogs = f.smslogs.logs.filter((sms: SMSLog) => sms.unread === true);
    //   smsfilterLogs.forEach((sms: SMSLog) => {
    //     const b: Booking = new Booking(f);
    //     b.smslogs.logs = [sms];
    //     smsBookingArray.push(b);
    //   });
    // });
    smsBookingArray.sort((a, b) => {
      const dateA: Date = new Date(a.smslogs.logs[0]?.logdate);
      const dateB: Date = new Date(b.smslogs.logs[0]?.logdate);
      return dateA.getTime() - dateB.getTime();
    });
    sortedunreadsmslogsresults.value = smsBookingArray;
  });
  await getUnreadEmailLogs({}).then((bookings: Booking[]) => {
    const emailBookingArray: Booking[] = bookings;
    // bookings.forEach((f: Booking) => {
    //   const emailfilterLogs = f.emaillogs.tenantemaillogs.filter((email: EmailLog) => email.unread === true && email.tenantresponse === 'callback');
    //   emailfilterLogs.forEach((email: EmailLog) => {
    //     const b: Booking = new Booking(f);
    //     b.emaillogs.tenantemaillogs = [email];
    //     emailBookingArray.push(b);
    //   });
    // });
    emailBookingArray.sort((a, b) => {
      const dateA: Date = new Date(a.emaillogs.tenantemaillogs[0]?.logdate);
      const dateB: Date = new Date(b.emaillogs.tenantemaillogs[0]?.logdate);
      return dateA.getTime() - dateB.getTime();
    });
    sortedunreadsmslogsresults.value = [
      ...sortedunreadsmslogsresults.value,
      ...emailBookingArray,
    ];
  });
  commanFunction();
};

const allPresets = async () => {
  const errors = [
    {
      message: "Upload missing",
      presetnumber: 1,
      flag: false,
    },
    {
      message: "Upload not typed up",
      presetnumber: 2,
      flag: false,
    },
    {
      message: "Check-In time outside of booking slot",
      presetnumber: 3,
      flag: false,
    },
    {
      message: "Time conflict when access via Tenant",
      presetnumber: 4,
      flag: false,
    },
    {
      message: "Access via agency but agency is shut",
      presetnumber: 7,
      flag: false,
    },
    {
      message: "Tenant access for Check-Out unconfirmed",
      presetnumber: 5,
      flag: false,
    },
    {
      message: "Key access unconfirmed (excluding via TT for Check-Out)",
      presetnumber: 6,
      flag: false,
    },
    {
      message: "Provisional booking",
      presetnumber: 8,
      flag: false,
    },
    {
      message: "Access via concierge and no email attached",
      presetnumber: 9,
      flag: false,
    },
  ];

  presentErrors.value = errors;
};

const updateManageFilter = async (selectbooking: Booking, fromflag: string) => {
  // let index = results.value.findIndex(
  //   (f: Booking) => f.id === selectbooking.id
  // );
  // if (index === -1) {
  //   channel.publish("appointmentSelected", {
  //     deviceid: deviceid,
  //     bookingid: selectbooking.id,
  //     inspectorid: selectbooking.inspector?.id,
  //     googleid: selectbooking.googleid,
  //     subtype: selectbooking.subtype,
  //     jobtype: selectbooking.jobtype,
  //     bookingdate: selectbooking.bookingdate,
  //   });
  //   if (fromflag === "fromunreadmessages") results.value = [];
  //   results.value.unshift(selectbooking);
  // }
};

onMounted(async () => {
  channel.subscribe("appointmentChanged", async (message: any) => {
    if (message?.data && message?.data.bookingid) {
      if (
        message.data.source === "DiaryForm" ||
        message.data.source === "Scheduler"
      ) {
        const index = results.value.findIndex(
          (b: Booking) => b.id === message.data.bookingid
        );
        if (index >= 0) {
          getBookingWithoutStoringInState({
            id: message.data.bookingid,
            cancelled: "false",
          }).then(async (b: Booking) => {
            if (b.preseterrors.length == 0) {
              results.value = results.value.filter(
                (f: Booking) => f.id != message?.data.bookingid
              );
            } else {
              results.value[index] = b;
            }
          });
        }
      } else if (
        message.data.source === "smslambda" ||
        message.data.source === "emaillambda"
      ) {
        getBookingWithoutStoringInState({
          id: message.data.bookingid,
          cancelled: "false",
        }).then(async (b: Booking) => {
          if (message.data.source === "smslambda") {
            const index = sortedunreadsmslogsresults.value.findIndex(
              (f: Booking) => f.id === b.id && f.smslogs.logs.length > 0
            );
            if (index >= 0) {
              sortedunreadsmslogsresults.value.splice(index, 1);
              commanFunction();
            }
          } else if (message.data.source === "emaillambda") {
            const index = sortedunreadsmslogsresults.value.findIndex(
              (f: Booking) =>
                f.id === b.id && f.emaillogs.tenantemaillogs.length > 0
            );
            if (index >= 0) {
              sortedunreadsmslogsresults.value.splice(index, 1);
              commanFunction();
            }
          }
        });
      }
    }
  });

  channel.subscribe("bookingConfirmResponse", async (message: any) => {
    if (message?.data && message?.data.bookingid && message?.data.unread) {
      const index = sortedunreadsmslogsresults.value.findIndex(
        (b: Booking) => b.id === message.data.bookingid
      );
      getBookingWithoutStoringInState({
        id: message.data.bookingid,
        cancelled: "false",
      }).then(async (b: Booking) => {
        if (index >= 0) {
          sortedunreadsmslogsresults.value[index] = b;
        } else {
          sortedunreadsmslogsresults.value.push(b);
        }
        sortedunreadsmslogsresults.value.sort((a, b) => {
          const dateA: Date = new Date(a.smslogs.logs[0]?.logdate);
          const dateB: Date = new Date(b.smslogs.logs[0]?.logdate);
          return dateA.getTime() - dateB.getTime();
        });
        sortedunreadsmslogsresults.value.sort((a, b) => {
          const dateA: Date = new Date(a.emaillogs.tenantemaillogs[0]?.logdate);
          const dateB: Date = new Date(b.emaillogs.tenantemaillogs[0]?.logdate);
          return dateA.getTime() - dateB.getTime();
        });
        commanFunction();
      });
    }
  });

  channel.subscribe("bookinglocked", (message: any) => {
    if (message?.data?.bookingid) {
      results.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          results.value[i].locked = true;
          results.value[i].lockedby = message.data.lockedby;
        } else if (b.lockedby === message.data.lockedby) {
          results.value[i].locked = false;
          results.value[i].lockedby = "";
        }
      });
    }
  });
  channel.subscribe("bookingunlocked", (message: any) => {
    if (message?.data?.bookingid) {
      results.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          results.value[i].locked = false;
          results.value[i].lockedby = "";
        } else if (b.lockedby === message.data.lockedby) {
          results.value[i].locked = false;
          results.value[i].lockedby = "";
        }
      });
    }
  });
  channel.subscribe("bookinginprogress", (message: any) => {
    if (message?.data?.bookingid) {
      results.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          results.value[i].inprogress = true;
        }
      });
    }
  });
  channel.subscribe("bookingnotinprogress", (message: any) => {
    if (message?.data?.bookingid) {
      results.value.forEach((b: Booking, i: number) => {
        if (b.id === message.data.bookingid) {
          results.value[i].inprogress = false;
        }
      });
    }
  });
  allPresets();
  await getAllCustomers();
  await logsReletedFunctions({});
  await companyNameList();
  channel.subscribe("appointmentCancelled", (message: any) => {
    if (message?.data) {
      setBookingDeep({ path: "cancelled", data: true });
      results.value
        .filter((b: Booking) => b.id === message.data)
        .forEach((b: Booking) => (b.cancelled = true));
    }
  });
});

onBeforeUnmount(() => {
  channel.unsubscribe("appointmentChanged");
  channel.unsubscribe("bookingConfirmResponse");
  channel.unsubscribe("bookinglocked");
  channel.unsubscribe("bookingunlocked");
  channel.unsubscribe("bookinginprogress");
  channel.unsubscribe("bookingnotinprogress");
  channel.unsubscribe("appointmentCancelled");
});

const tenantAttendingSelect = (val: string) => {
  if (val === "neutral") val = "unsure";
  if (tenantattending.value != val) {
    tenantattending.value = val;
  } else {
    tenantattending.value = "";
  }
};

const isuploadorlinktypejob = computed(() => {
  let result = -1;
  if (internaljobtype.value)
    result = uploadorlinkjobtypelist.value.findIndex(
      (t: string) => internaljobtype.value === t
    );
  return result >= 0;
});

const dateRangeFn = () => {
  let params: any = {};
  if (datefiltertype.value) {
    let todaymoment = moment(new Date()).utc();
    switch (datefiltertype.value) {
      case "Today":
        let today: string = todaymoment.format("DD-MM-YYYY");
        params.fromdate = today;
        params.todate = today;
        break;
      case "Tomorrow":
        let tomorrowmoment = todaymoment.add(1, "days");
        params.fromdate = tomorrowmoment.format("DD-MM-YYYY");
        params.todate = tomorrowmoment.format("DD-MM-YYYY");
        break;
      case "Day after tomorrow":
        let datmoment = todaymoment.add(2, "days");
        params.fromdate = datmoment.format("DD-MM-YYYY");
        params.todate = datmoment.format("DD-MM-YYYY");
        break;
      case "Next 2 days":
        params.fromdate = todaymoment.format("DD-MM-YYYY");
        params.todate = todaymoment.add(2, "days").format("DD-MM-YYYY");
        break;
      case "Next 3 days":
        params.fromdate = todaymoment.format("DD-MM-YYYY");
        params.todate = todaymoment.add(3, "days").format("DD-MM-YYYY");
        break;
      case "Next 7 days":
        params.fromdate = todaymoment.format("DD-MM-YYYY");
        params.todate = todaymoment.add(7, "days").format("DD-MM-YYYY");
        break;
      case "Date range":
        if (fromdate.value && todate.value) {
          params.fromdate = moment(fromdate.value).utc().format("DD-MM-YYYY");
          params.todate = moment(todate.value).utc().format("DD-MM-YYYY");
        } else {
          actProperty.displayError("From/to date cannot be empty");
        }
        break;
      case "All dates":
        params.fromdate = "";
        params.todate = "";
        break;
    }
  }
  return params;
};

const search = async () => {
  let params: any = {};
  results.value = [];
  let dateRange = await dateRangeFn();
  params.fromdate = dateRange.fromdate;
  params.todate = dateRange.todate;
  if (anytext.value) params.anytext = anytext.value;
  if (jobtype.value && jobtype.value.name) params.jobtype = jobtype.value.slug;
  if (internaljobtype.value) params.internaljobtype = internaljobtype.value;
  if (companyname.value) params.companyname = companyname.value;
  if (branchname.value) params.branchname = branchname.value;
  if (companyname.value && branchname.value) {
    const selectedcustomer = customers.value.find(
      (c: Customer) =>
        c.companyName === companyname.value && c.branchName === branchname.value
    );
    if (selectedcustomer?.id) {
      params.customerid = selectedcustomer.id;
    }
  }
  else if(companyname.value && !branchname.value) {
    if(companyname.value?.startsWith('CRM Portfolio')) {
      params.companyname = '';
      params.branchname = '';
      const index = users.value.findIndex((u: User) => u.name.startsWith(companyname.value.replace('CRM Portfolio ','')));
      if(index >= 0 && users.value[index].customers?.length) {
        params.customerids = users.value[index].customers.map((c: Customer) => c.id).join(',');
      }
    }
    else {
      const selectedcustomers = customers.value.filter(
        (c: Customer) =>
          c.companyName === companyname.value
      );
      if (selectedcustomers?.length) {
        params.customerids = selectedcustomers.map((c: Customer) => c.id).join(',');
      }
    }
  }
  if (inspector.value && inspector.value.slug)
    params.inspectorid = inspector.value.slug;
  if (access.value) params.access = access.value;
  if (accessconfirmed.value === "Confirmed") params.accessconfirmed = "true";
  else if (accessconfirmed.value === "Not confirmed")
    params.accessconfirmed = "false";
  if (tenantattending.value) params.tenantattending = tenantattending.value;
  if (missingtenantname.value)
    params.missingtenantname = missingtenantname.value;
  if (missingtenantmobile.value)
    params.missingtenantmobile = missingtenantmobile.value;
  if (missingtenantemail.value)
    params.missingtenantemail = missingtenantemail.value;
  if (missinguploadorreportref.value)
    params.missinguploadorreportref = missinguploadorreportref.value;
  if (cicotimeconflict.value) params.cicotimeconflict = cicotimeconflict.value;
  if (inabsence.value) params.inabsence = inabsence.value;
  if (provisional.value) params.provisional = provisional.value;
  if (smokecoalarmrequired.value)
    params.smokecoalarmrequired = smokecoalarmrequired.value;
  if (notissued.value) params.notissued = notissued.value;
  if (safetycleats.value) params.safetycleats = safetycleats.value;
  if (summaryemailafterco.value)
    params.summaryemailafterco = summaryemailafterco.value;
  if (tbc.value) params.tbc = tbc.value;
  if (cancelled.value) params.cancelled = cancelled.value;
  if (missingllname.value) params.missingllname = missingllname.value;
  if (missingllmobile.value) params.missingllmobile = missingllmobile.value;
  if (missingllemail.value) params.missingllemail = missingllemail.value;
  if (missingworksorder.value)
    params.missingworksorder = missingworksorder.value;
  if (notpaid.value) params.notpaid = notpaid.value;
  if (keyreleaseunconfirmed.value)
    params.keyreleaseunconfirmed = keyreleaseunconfirmed.value;
  if (keyreturntbc.value) params.keyreturntbc = keyreturntbc.value;
  if (flexiappoitment.value) params.flexiappoitment = flexiappoitment.value;
  if (predictedtraveltime.value)
    params.predictedtraveltime = predictedtraveltime.value;
  let presentErrorArray = presentErrors.value
    .filter((f) => f.flag)
    .map((m) => m.presetnumber);
  if (presentErrorArray.length) {
    params.presetnumbers = presentErrorArray.join(",");
  }

  const queryparams = Object.keys(params).filter(
    (p) => p != "fromdate" && p != "todate"
  );
  if (queryparams.length) {
    paramsset.value = params;
    managementBookingSearch(params).then((bookings: Booking[]) => {
      if (keyreturntbc.value || presentErrorArray.length) {
        let noPersonalBookings = bookings.filter(
          (f: Booking) =>
            f.jobtype != Booking.PERSONAL && f.subtype != Booking.PERSONAL
        );
        let onlyPrepAndKeyBookings = noPersonalBookings.filter(
          (f: any) => f.subtype !== Booking.PREP && f.subtype !== Booking.KEY
        );
        let onlyMasterIdsList = onlyPrepAndKeyBookings.map(
          (f: Booking) => f.id
        );
        let masterbookingidlistunderprepandkey = noPersonalBookings
          .filter(
            (f: any) =>
              (f.subtype === Booking.PREP || f.subtype === Booking.KEY) &&
              !onlyMasterIdsList.includes(f.leadbooking.id)
          )
          .map((f: Booking) => f.leadbooking.id);
        results.value.push(...onlyPrepAndKeyBookings);
        setBookingType(filterbookingtype.value);
        if (masterbookingidlistunderprepandkey.length > 0) {
          const uniqueArray = [...new Set(masterbookingidlistunderprepandkey)];
          (params.idlist = encodeURI(uniqueArray.join(","))),
            managementBookingSearch(params).then((bookings: Booking[]) => {
              results.value = [...results.value, ...bookings];
              setBookingType(filterbookingtype.value);
            });
        }
        results.value = results.value.filter((f: Booking) => {
          // Filter existing preset errors
          let existingPresetErrors = f.preseterrors.filter(
            (error: PresetError) =>
              error.category === "secondary" ||
              (error.category === "primary" &&
                ![2, 4, 5, 6, 7].includes(error.presetnumber))
          );
          // Generate new preset errors
          let primaryPresets: PresetError[] = checkBooking(f);
          let newPresetErrors = primaryPresets.filter(
            (error: PresetError) =>
              error.category === "primary" &&
              [2, 4, 5, 6, 7].includes(error.presetnumber)
          );
          // Combine existing and new preset errors
          f.preseterrors = [...existingPresetErrors, ...newPresetErrors];
          // Filter out bookings with no preset errors
          if (f.preseterrors.length === 0) {
            return false;
          }

          // Only show presets which are selected by user
          const presentInSelectedIndex = f.preseterrors.findIndex((e: PresetError) => {
            const i = presentErrorArray.findIndex((pe: any) => pe === e.presetnumber);
            if(i >= 0) return true;
            return false;
          })
          if(presentInSelectedIndex < 0) return false;

          return true;
        });
      } else {
        results.value.push(...bookings);
      }

      // Filter all shared bookings
      const noSharedBookings = results.value.filter((b: Booking) => b.subtype !== Booking.SHARED);
      results.value = [...noSharedBookings];

      showresultlist.value = true;
      setScrollposition();
    });
    setBooking(new Booking());
  }
  commanFunction();
};

const commanFunction = async () => {
  let params: any = {};
  let dateRange = await dateRangeFn();
  params.fromdate = dateRange.fromdate;
  params.todate = dateRange.todate;
  todaysunreadsmslogsresults.value = [];
  daterangeunreadsmslogsresults.value = [];
  futureunreadsmslogsresults.value = [];
  sortedunreadsmslogsresults.value.filter((f) => {
    let checkFlagCheck = false;
    if (params.fromdate && params.todate) {
      let startDate = moment(params.fromdate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      let endDate = moment(params.todate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let checkDate = moment(f.startDate).format("YYYY-MM-DD");
      checkFlagCheck = moment(checkDate).isBetween(
        startDate,
        endDate,
        null,
        "[]"
      );
    }
    if (
      moment(f.bookingdate, "DD/MM/YYYY").isSame(
        moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY"),
        "day"
      )
    ) {
      todaysunreadsmslogsresults.value.push(f);
    } else if (checkFlagCheck) {
      daterangeunreadsmslogsresults.value.push(f);
    } else if (
      !moment(f.bookingdate, "DD/MM/YYYY").isSame(
        moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY"),
        "day"
      )
    ) {
      futureunreadsmslogsresults.value.push(f);
    }
  });
};

const sortedresults = computed(() => {
  const seenIds = new Set();
  const uniqueBookings = results.value.filter((booking) => {
    if (seenIds.has(booking.id)) {
      return false;
    } else {
      seenIds.add(booking.id);
      return true;
    }
  });
  return uniqueBookings;
  // return uniqueBookings.sort((b1: Booking, b2: Booking) => {
  //   // First order by customer
  //   let order = b1?.customer?.companyName.localeCompare(b2?.customer?.companyName);
  //   if (order === 0) order = b1?.customer?.branchName.localeCompare(b2?.customer?.branchName);
  //   if (order === 0) {
  //     if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
  //       order = 1;
  //     } else if (moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))) {
  //       order = -1;
  //     } else {
  //       order = 0;
  //     }
  //   }
  //   return order;
  // });
});

const setScrollposition = () => {
  let resultpane: any = managementResults.value;
  let todaymoment = moment.utc(new Date()).startOf("day");
  var futurebooking: Booking | undefined = sortedresults.value.find(
    (b: Booking) => moment.utc(b.startdate).isAfter(todaymoment)
  );

  if (!futurebooking || !futurebooking?.id) {
    futurebooking =
      sortedresults.value.length > 0
        ? sortedresults.value[sortedresults.value.length - 1]
        : undefined;
  }

  nextTick(() => {
    if (futurebooking?.id) {
      futureBookingId.value = futurebooking?.id;
      let focusedBookingIndex = sortedresults.value.findIndex(
        (b: Booking) => b.id === futureBookingId.value
      );
      setTimeout(() => {
        if (resultpane) {
          resultpane.scrollTop = focusedBookingIndex * 186;
          resultpane.firstChild.firstChild.scrollTop =
            focusedBookingIndex * 186;
        }
      }, 1000);
    }
  });
};

const searchDisabled = computed(() => {
  let disabled = true;
  if (datefiltertype.value) {
    disabled =
      datefiltertype.value === "Date range"
        ? !(fromdate.value && todate.value)
        : false;
  }
  return disabled;
});

const showGoogleEventModal = (booking: Booking) => {
  googlebooking.value = booking;
  const modal = googleeventmodal.value as any;
  modal.show();
};

const clearCompanyname = () => {
  companyname.value = "";
  branchname.value = "";
};

const clearBranchname = () => {
  branchname.value = "";
};

const clearInspector = () => {
  inspector.value = new SelectOption();
};

const clearJobtype = () => {
  jobtype.value = new DictionaryReportType();
  internaljobtype.value = "";
};

const clearFilterType = () => {
  datefiltertype.value = "";
};
const customLabelForInternaljobtype = (internaljobtype: string): string => {
  var displayname = internaljobtype;
  return actProperty.customLabelForInternaljobtype(
    internaljobtypedisplayname.value,
    displayname
  );
};
const managePresentErrors = ($index: number) => {
  if (presentErrors.value[$index].flag === true) {
    presentErrors.value[$index].flag = false;
  } else {
    presentErrors.value[$index].flag = true;
  }
  if (!datefiltertype.value) {
    datefiltertype.value = "Date range";
    const dayOfWeekNumber = moment().format("d");
    const newDate = moment(fromdate.value)
      .add([5, 6, 0].includes(parseInt(dayOfWeekNumber)) ? 3 : 2, "days")
      .toDate();
    todate.value = newDate;
  }
  search();
};

const keyreturntbcfn = () => {
  if (keyreturntbc.value === true) {
    keyreturntbc.value = false;
  } else {
    keyreturntbc.value = true;
  }
  if (!datefiltertype.value) {
    datefiltertype.value = "Date range";
    const dayOfWeekNumber = moment().format("d");
    const newDate = moment(fromdate.value)
      .add([5, 6, 0].includes(parseInt(dayOfWeekNumber)) ? 3 : 2, "days")
      .toDate();
    todate.value = newDate;
  }
  search();
};
const removebookingfromlocal = (booking: Booking) => {
  let index = results.value.findIndex((f: Booking) => f.id === booking.id);
  results.value.splice(index, 1);
};

const removesmsbookingfromlocal = async (booking: Booking, flag: string) => {
  originalbooking.value = new Booking(booking);
  let bfindex = -1;
  let index = -1;
  if (flag === "sms") {
    index = sortedunreadsmslogsresults.value.findIndex(
      (f: Booking) => f.id === booking.id && f.smslogs.logs.length > 0
    );
    sortedunreadsmslogsresults.value.splice(index, 1);
    booking.smslogs.logs.forEach((l) => (l.unread = false));
  } else {
    index = sortedunreadsmslogsresults.value.findIndex(
      (f: Booking) =>
        f.id === booking.id && f.emaillogs.tenantemaillogs.length > 0
    );
    sortedunreadsmslogsresults.value.splice(index, 1);
    booking.emaillogs.tenantemaillogs.forEach((l) => (l.unread = false));
  }
  commanFunction();
  setBooking(new Booking());
  // remove selected
  bfindex = results.value.findIndex((f: Booking) => f.id === booking.id);

  results.value.splice(bfindex, 1);
  await markSMSEmailAsRead(booking);
  toasted.success(UndoSaveToaster, {
    onClick: async () => {
      await markSMSEmailAsRead(originalbooking.value);
      if (index !== -1) {
        sortedunreadsmslogsresults.value.splice(
          index,
          0,
          originalbooking.value
        );
        commanFunction();
      }
      if (bfindex !== -1) {
        results.value.splice(bfindex, 0, booking);
      }
    },
    timeout: 10000,
    closeOnClick: true,
  });
};

const clearAll = () => {
  presentErrors.value = presentErrors.value.map((obj) => ({
    ...obj,
    flag: false,
  }));
  //datefiltertype.value = '';

  // datefiltertype.value = "";
  // fromdate.value = new Date();
  // todate.value = new Date();
  anytext.value = "";
  jobtype.value = new DictionaryReportType();
  internaljobtype.value = "";
  companyname.value = "";
  branchname.value = "";
  inspector.value = new SelectOption();
  access.value = "";
  accessconfirmed.value = "";
  flexiappoitment.value = "";
  predictedtraveltime.value = "";
  tenantattending.value = "";
  missingtenantname.value = false;
  missingtenantmobile.value = false;
  missingtenantemail.value = false;
  missinguploadorreportref.value = false;
  cicotimeconflict.value = false;
  provisional.value = false;
  inabsence.value = false;
  cancelled.value = false;
  missingllname.value = false;
  missingllmobile.value = false;
  missingllemail.value = false;
  missingworksorder.value = false;
  notpaid.value = false;
  summaryemailafterco.value = false;
  smokecoalarmrequired.value = false;
  keyreleaseunconfirmed.value = false;
  keyreturntbc.value = false;
  notissued.value = false;
  store.state.selectedbooking = new Booking();
  search();
};
const selectAll = () => {
  if (!datefiltertype.value) {
    datefiltertype.value = "Date range";
    const dayOfWeekNumber = moment().format("d");
    const newDate = moment(fromdate.value)
      .add([5, 6, 0].includes(parseInt(dayOfWeekNumber)) ? 3 : 2, "days")
      .toDate();
    todate.value = newDate;
  }
  keyreturntbc.value = true;
  presentErrors.value = presentErrors.value.map((obj) => ({
    ...obj,
    flag: true,
  }));
  search();
};
const clearFilterBookingType = () => {
  filterbookingtype.value = "Client";
  setBookingType("Client");
};

const setBookingType = (val: string) => {
  let filterBookingList: Booking[] = [];
  if (val === "Time & Date") {
    filterBookingList = results.value.sort((b1: Booking, b2: Booking) => {
      if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
        return 1;
      } else if (moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))) {
        return -1;
      } else {
        return 0;
      }
    });
  } else if (val === "Job type") {
    filterBookingList = results.value.sort((l1: Booking, l2: Booking) =>
      l1.jobtype.localeCompare(l2.jobtype)
    );
  } else if (val === "PI") {
    filterBookingList = results.value.sort((l1: Booking, l2: Booking) =>
      l1.inspector.name.localeCompare(l2.inspector.name)
    );
  } else {
    filterBookingList = results.value.sort((b1: Booking, b2: Booking) => {
      const companyName1 = b1?.customer?.companyName || '';
      const companyName2 = b2?.customer?.companyName || '';
      let branchName1 = b1?.keypickupbranchname ? b1?.keypickupbranchname : b1?.customer?.branchName;
      if(!branchName1) branchName1 = '';
      let branchName2 = b2?.keypickupbranchname ? b2?.keypickupbranchname : b2?.customer?.branchName;
      if(!branchName2) branchName2 = '';
      
      let order = companyName1.localeCompare(companyName2);
      
      if (order === 0) {
        order = branchName1.localeCompare(branchName2);
      }

      return order;
     });
  }
  results.value = [];
  results.value = filterBookingList;
};
</script>

<style scoped lang="scss">
.filter-pane {
  flex-grow: 1;
  height: 90vh;
  overflow-x: auto !important;
  overflow-y: auto !important;
  border-left: 1px solid #d9dadb;
  padding-bottom: 50px;
}

.filter-pane::-webkit-scrollbar {
  width: 20px;
}

.card {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.cancelled {
  border: 1px solid rgb(136, 8, 8);
}

.presetbtn {
  background-color: #cccccc;
  color: white;
  border-color: #cccccc;
  border-radius: 2rem !important;
}

.main-container {
  max-height: 90vh;
  overflow: hidden;
}
</style>
