/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { PvBookingsState } from './types';
import { Booking, Customer, Inspector, PmTenancy } from '@/models';

export const getters: GetterTree<PvBookingsState, RootState> = {

  /**
   * Are the tenancies Empty?
   * 
   * @param {PvBookingsState} state 
   * @returns Boolean if tenancy is empty
   */
  isEmpty: (state: PvBookingsState): boolean => {
    return state.tenancies.length === 0;
  },

  /**
   * Get the list of tenancies
   * 
   * @param state PvBookingsState
   * @returns tenancies list
   */
  getTenancies: (state: PvBookingsState): PmTenancy[] => {
    return state.tenancies;
  },
  /**
  * Get the list of tenancies
  * 
  * @param state PvBookingsState
  * @returns tenancies list
  */
  getBookingsData: (state: PvBookingsState): Booking[] => {
    return state.list;
  },

  /**
   * Get the current tenancy
   * 
   * @param {PvBookingsState} state 
   * @returns Current tenancies
   */
  currentTenancy: (state: PvBookingsState): PmTenancy | undefined => {
    return state.tenancies.find(x => x.id === state.currentTenancyId);
  },
  list: (state: PvBookingsState): Booking[] => state.list,
  hasUnsavedChanges: (state: PvBookingsState) => state.hasUnsavedChanges,
  customerlist: (state: PvBookingsState): Customer[] => state.customerlist,
  currentPage: (state: PvBookingsState): number => state.currentPage,
  inspectorlist: (state: PvBookingsState): Inspector[] => state.inspectorlist,
  originallist: (state: PvBookingsState): Booking[] => state.originallist,
};
