<template>
  <div @focusout="closeDropdown">
    <DxDateBox
      v-show="changeddatepvmid === pvm.id"
      class="form-control height-43"
      :value="dueddate"
      display-format="dd/MM/yyyy"
      type="date"
      picker-type="calendar"
      apply-value-mode="instantly"
      :calendarOptions="{ firstDayOfWeek: 1 }"
      @value-changed="setDueDate(pvm.id, $event.value)"
      :ref="'dueDateSelect-' + pvm.id"
    />
    <div
      v-show="changeddatepvmid !== pvm.id"
      :class="{ 'transparent-text': !pvm.duedate }"
      @click="changeDDateReport()"
    >
      {{
        pvm.duedate
          ? actProperty.formatDateForDisplay(pvm.duedate)
          : "Select"
      }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps, inject } from "vue";
import { useStore } from "vuex";
import { Booking } from "@/models";
import moment from "moment-timezone";
import { DxDateBox } from "devextreme-vue/date-box";
const store = useStore();
const actProperty: any = inject("actProperty");
const pvbookings = computed(
  () => store.getters["pvbookings/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changeddatepvmid: string;
}>();
const emit = defineEmits(["duedatepvmselected"]);
const dueDateSelect = ref<{ [key: string]: HTMLElement | undefined }>({});
const setBookingsData = (data: Booking[]) =>
  store.commit("pvbookings/setBookings", data);
const sethasUnsavedChanges = (data: boolean) =>
  store.commit("pvbookings/sethasUnsavedChanges", data);
const { pvm, changeddatepvmid } = toRefs(props);
const changeDDateReport = () => {
  emit("duedatepvmselected", pvm.value.id);

  const dropdown = dueDateSelect.value[
    `dedueddate-${pvm.value.id}`
  ] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const setDueDate = async (id: string, $event: any) => {
  if ($event === "Invalid date" || $event === null) return;
  const pmIndex = pvbookings.value.findIndex(
    (r: Booking) => r.id === id
  );
  if(!$event) return;
  pvbookings.value[pmIndex].duedate =
    moment($event).format("YYYY-MM-DD[T]HH:mm");    
  // setBookingsData(pvbookings.value);
  sethasUnsavedChanges(true);
  pvm.value.id = id;
  emit("duedatepvmselected", "");
};
const closeDropdown = () => {
  emit("duedatepvmselected", "");
};
const dueddate = ref("");
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
